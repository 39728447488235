import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { apiCalll } from "../../_metronic/_helpers/apiHelpers";
import moment from "moment";
import Skeleton from "react-loading-skeleton";
import { getFromatdate } from "../../_metronic/_helpers/GlobalHelpers";

const ReleaseNote = ({ show, setShow }) => {
  const [notes, setNotes] = useState([]);
  const [openAccordion, setOpenAccordion] = useState(null);
  const [isLoading, setIsLoading] = useState(false);


  const handleAccordionToggle = (accordionId) => {
    setOpenAccordion(openAccordion === accordionId ? null : accordionId);
  };

  function GetReleaseNotes() {
    setIsLoading(true);
    apiCalll({
      method: "GET",
      link: `/ReleaseNotes/releaseNote`,
    })
      .then(async (result) => {
        if (result.length > 0 && result[0] === 200) {
          setNotes(result[1].releaseNotes);
        }
      })
      .catch((error) => {
        console.log("error", error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  useEffect(() => {
    if (show) {
      GetReleaseNotes();
    }
  }, [show]);

  useEffect(() => {
    if (!show) {
      setOpenAccordion(null);
      setNotes([]);
    }
  }, [show]);

  return (
    <div>
      <Modal show={show} onHide={() => setShow(false)} size="lg" centered>
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            DeliveryEase changelog
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div
            className="accordion accordion-light accordion-toggle-arrow"
            id="accordionExample5"
          >
            {!isLoading &&
              notes.length > 0 &&
              notes?.map((note, index) => (
                <div className="card" key={note.id}>
                  <div className="card-header" id={`heading${index}`}>
                    <div
                      className="card-title text-dark"
                      onClick={() => handleAccordionToggle(index)}
                      aria-expanded={openAccordion === index}
                      style={{ cursor: "pointer" }}
                    >
                      {note.title} : {note.release} -{" "}
                      {moment(note.date).format(getFromatdate())}
                    </div>
                  </div>
                  <div
                    id={`collapse${index}`}
                    className={`collapse ${
                      openAccordion === index ? "show" : ""
                    } transition`}
                    data-parent="#accordionExample5"
                  >
                    <div className="card-body ml-5">
                      <div className="mb-5">
                        <div dangerouslySetInnerHTML={{ __html: note.notes }} />
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </div>
          {isLoading &&
            notes.length === 0 &&
            [0, 1, 2, 3, 4].map((item, index) => (
              <Skeleton key={index} height={60} count={1} />
            ))}
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default ReleaseNote;
