import React from "react";

function SelectionCheckbox({ isSelected, onChange }) {
  return (
    <>
      <input type="checkbox" style={{ display: "none" }} />
      <label className="checkbox checkbox-single">
        <input type="checkbox" checked={isSelected} onChange={onChange} />
        <span />
      </label>
    </>
  );
}

function groupingItemOnSelect(props) {
  const { ids, setIds, customerId, isRow, setNames, Names, customer, ordersCodes, setOrdersCodes, backgroundCheckRequired,setBackgroundCheckRequired  } = props;
  const currentIndex = ids.findIndex(id => id === customerId);
  if (ids.some((id) => id === customerId)) {
    setIds(ids.filter((id) => id !== customerId));
    if(Names !== undefined){
      setNames(Names.filter((name) => name?.id !== customerId));
    }
    if (ordersCodes !== undefined) {
      setOrdersCodes(ordersCodes.filter((code) => code !== customer.orderCode));
    }
    if (backgroundCheckRequired !== undefined && currentIndex !== -1) {
      setBackgroundCheckRequired(backgroundCheckRequired.filter((_, index) => index !== currentIndex));
    }
  } else {
    const newIds = [...ids,customerId];
    setIds(newIds);

   
    if(Names !== undefined){
      const newNames = [...Names,customer];
      setNames(newNames);
    }
    if (ordersCodes !== undefined) {
      const newOrdersCodes = [...ordersCodes, customer.orderCode];
      setOrdersCodes(newOrdersCodes);
    }
    if (backgroundCheckRequired !== undefined && currentIndex === -1) {
      const newBackgroundChecks = [...backgroundCheckRequired, customer.backgroundCheckRequired];
      setBackgroundCheckRequired(newBackgroundChecks);
    }
  }
}

function groupingAllOnSelect(props) {
  const { isSelected, setIds, entities, setNames, Names, setOrdersCodes,setBackgroundCheckRequired  } = props;  
  if (!isSelected) {
    const allIds = entities.map((el) => el.id);
    const allNames = entities.map((el) => ({ id: el.id, name: el?.firstName + " " + el?.lastName }));
    const allOrdersCodes = entities.map((el) => el.code);
    const allBackgroundChecks = entities.map((el) => el.backgroundCheckRequired);
    setIds(allIds);
    if (Names !== undefined) {
      setNames(allNames);
    }
    if (setOrdersCodes !== undefined) {
      setOrdersCodes(allOrdersCodes);
    }
    if (setBackgroundCheckRequired !== undefined) {
      setBackgroundCheckRequired(allBackgroundChecks);
    }
  } else {
    setIds([]);
    if(Names !== undefined) {
      setNames([])
    }
    if (setOrdersCodes !== undefined) {
      setOrdersCodes([]);
    }
    if (setBackgroundCheckRequired !== undefined) {
      setBackgroundCheckRequired([]);
    }
  }

  return isSelected;
}

// check official documentations: https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html?selectedKind=Row%20Selection&selectedStory=Custom%20Selection%20Column%20Header%20Style&full=0&addons=1&stories=1&panelRight=0&addonPanel=storybook%2Factions%2Factions-panel
export function getSelectRow(props) {
  const { entities, ids, setIds, isRow, setNames, Names, ordersCodes, setOrdersCodes,backgroundCheckRequired,setBackgroundCheckRequired  } = props;
  return {
    mode: "checkbox",
    clickToSelect: true,
    hideSelectAll: false,
    selectionHeaderRenderer: () => {
      const isSelected = entities && entities.length > 0 && entities.length === ids.length;
      const props = { isSelected, entities, setIds, isRow, setNames, Names, ordersCodes, setOrdersCodes, setBackgroundCheckRequired };

      return (
        <SelectionCheckbox
          isSelected={isSelected}
          onChange={() => groupingAllOnSelect(props)}
        />
      );
    },
    selectionRenderer: ({ rowIndex }) => {
      const isSelected = ids.some((el) =>
        !isRow ? el === entities[rowIndex].id : (el?.id || el) === entities[rowIndex].id
      );
      const customer = {
        id: entities[rowIndex].id,
        name: entities[rowIndex].firstName + " " + entities[rowIndex].lastName,
        orderCode: entities[rowIndex].code,
        backgroundCheckRequired: entities[rowIndex].backgroundCheckRequired
      };
      const props = { ids, setIds, customerId: isRow ? entities[rowIndex] : entities[rowIndex].id, setNames, Names, customer, ordersCodes, setOrdersCodes ,  backgroundCheckRequired,setBackgroundCheckRequired };

      return (
        <SelectionCheckbox
          isSelected={isSelected}
          onChange={() => groupingItemOnSelect(props)}
        />
      );
    },
  };
}
