import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useSubheader } from "../../../../../../_metronic/layout";
import PharmacyOrdersTable from "./PharmacyOrdersTable";
import PharmacyOrdersInvoices from "./PharmacyOrdersInvoices";
import PharmacyInvoicesHistoy from "./PharmacyInvoicesHistoy";
import { Col, Row } from "react-bootstrap";
import ReactSelect from "react-select";
import { ModelStateMessage, apiCalll } from "../../../../../../_metronic/_helpers/apiHelpers";
import Swal from "sweetalert2";
import { TagsInput } from "react-tag-input-component";
import { over } from "lodash";

const PharmacyDetails = () => {
  const role = JSON.parse(localStorage.getItem("user")).roles[0];
  const methods = [
    { value: "card", label: "Card" },
    { value: "cash", label: "Cash" },
    { value: "bank transfer", label: "Bank transfer" },
    { value: "check", label: "check" },
    { value: "acss_debit", label: "Pre-Authorized Debit" },
  ];
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const token = JSON.parse(localStorage.getItem("user")).token;
  const suhbeader = useSubheader();
  suhbeader.setTitle("Pharmacy details");
  const [pharmacy, setPharmacy] = useState({});
  const [search, setSearch] = useState("");
  const [paymentMethod, setPaymentMethod] = useState("");
  const [data, setData] = useState({
    invoicingEmail: [],
    invoiceEmailEnabled: true,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [enabled, setEnabled] = useState(true);
  const [loadingPayment, setLoadingPayment] = useState(false);
  const [pagination, setPagination] = useState([]);
  const [key, setKey] = useState(1);

  const params = useParams();
  const id = params.id;
  const history = useHistory();

  const handleGetPharmcayDetails = (userId) => {
    fetch(`${BASE_URL}/Pharmacies/Details/${userId}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      Allow: "GET",
    })
      .then(async (response) => {
        const statusCode = await response.status;
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
          return response.json().then((data) => [statusCode, data]);
        }
      })
      .then((data) => {
        if (data[0] === 200) {
          setPaymentMethod(data[1]?.paymentMethod);
          setPharmacy(data[1]);
        } else if (data[0] === 401) {
          history.push("/logout");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleGetEanbledEmailInfo = (id) => {
    fetch(`${BASE_URL}/Pharmacies/getInvoiceEmail/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      Allow: "GET",
    })
      .then(async (response) => {
        const statusCode = await response.status;
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
          return response.json().then((data) => [statusCode, data]);
        }
      })
      .then((data) => {
        if (data[0] === 200) {
          setData({
            invoicingEmail: data[1]?.emails,
            invoiceEmailEnabled: data[1]?.invoiceEmailEnabled,
          });

        } else if (data[0] === 401) {
          history.push("/logout");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (id) {
      handleGetPharmcayDetails(id);
      handleGetEanbledEmailInfo(id);
    }
  }, [id]);

  useEffect(() => {
    if (history.location.pathname.includes("invoice")) {
      setKey(2);
    }
  }, []);

  function validateEmail(email) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }
  const handleEnableEmail = (invoicingEmail,invoiceEmailEnabled) => {

    if(invoicingEmail.length > 0){
      for (let i = 0; i < invoicingEmail.length; i++) {
        if (!validateEmail(invoicingEmail[i])) {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Invalid email address',
          })
          return;
        }
      }
    }
    setIsLoading(true)
    fetch(`${BASE_URL}/Pharmacies/InvoiceEmail/${id}?InvoiceEmailEnabled=${invoiceEmailEnabled}
      `, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      },
      Allow: "PUT",
      body: JSON.stringify(invoicingEmail)

    })
      .then(async (response) => {
        const statusCode = await response.status;
        const contentType = response.headers.get("content-type");
        if (
          contentType &&  contentType.indexOf("application/json") !== -1
        ) {
          return response.json().then((data) => [statusCode,data]);
        } else {
          return response.text().then((data) => [statusCode,data]);
        }
      })
      .then(data => {
        if(data[0] === 200){
          Swal.fire({
            title: "Success!",
            text: invoiceEmailEnabled ? "Invoicing email has been enabled successfully" : "Invoicing email has been disabled successfully",
            icon: "success",
            confirmButtonText: "Ok",
          });
        }
        else if(data[0] === 400){
          let tt = JSON.parse(data[1]);
          for (const [key, value] of Object.entries(tt.errors)) {
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: value,
            })
          }
        }
        else if (data[0] === 401) {
          history.push("/logout");
        }
        else if(data[0] === 402){
          Swal.fire({
            title: "Info!",
            text: "You have to subscribe first",
            icon: "info",
            confirmButtonText: "Ok",
          }).then((result) => {
            if (result.isConfirmed) {
              history.push("/user-profile");
            }
          });
        }
        else if(data[0] === 500){
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Something went wrong!. Please try again later.',
          })
        }
      })
      .catch((error) => {
        console.log(error);
      }).finally(() => {
        setIsLoading(false)
      })
  }

  useEffect(() => {
    if(enabled == false){
      handleEnableEmail(data?.invoicingEmail,data?.invoiceEmailEnabled)
    }
  },[enabled])


  return (
    <div className="row">
      <div className="col-xl-3 col-lg-4 col-md-4">
        <div className="card card-custom mb-5">
          <div className="card-header">
            <div className="card-title">
              <h3 className="card-label">Pharmacy details</h3>
            </div>
          </div>
          <div className="card-body">
            <div className="pb-5 fs-6">
              <div className="font-weight-bolder mt-5">Pharmacy name</div>
              <div className="text-gray-600">{pharmacy?.name}</div>
              <div className="font-weight-bolder mt-5">Address</div>
              <div className="text-gray-600">
                {pharmacy?.address1} , {pharmacy?.address2}
                <br />
                {pharmacy?.city} , {pharmacy?.state} , {pharmacy?.zipCode}
                <br />
                {pharmacy?.country}
              </div>
              <div className="font-weight-bolder mt-5">Contact info</div>
              <div className="text-gray-600">
                {pharmacy?.contactFirstName} {pharmacy?.contactLastName}
              </div>
              <div className="text-gray-600 text-break">{pharmacy?.contactEmail}</div>
              <div className="text-gray-600">
                {pharmacy?.contactPhoneNumber}
              </div>
              {(role === "Carrier Admin") && <>
                <Row className="mt-10 form-group">
                  <Col>
                    <label>Payment method</label>
                    <ReactSelect
                      name="paymentMethod"
                      options={methods}
                      value={methods.find((method) => method.label === paymentMethod)}
                      onChange={(e) => {
                        setPaymentMethod(e.label);
                      }}
                      className="basic-multi-select"
                      classNamePrefix="select"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <button className="btn btn-primary w-100" disabled={loadingPayment || !!paymentMethod === false} onClick={async (e) => {
                      e.preventDefault();
                      setLoadingPayment(true);
                      await apiCalll({
                        method: "PUT",
                        link: `/Invoicing/Pharmacy/SetPaymentMethod?id=${id}&paymentMethod=${paymentMethod}`,
                      })
                        .then((res) => {
                          if (res.length > 0 && res[0] !== 200) {
                            ModelStateMessage(res);
                          } else {
                            Swal.fire({
                              title: "Success!",
                              text: "Payment method has been submitted successfully",
                              icon: "success",
                              confirmButtonText: "Ok",
                            });
                          }
                          setLoadingPayment(false);
                        })
                        .catch((error) => {
                          console.error(error);
                          setLoadingPayment(false);
                        });
                    }}>Save changes</button>
                  </Col>
                </Row>
              </>}
              <div
                className=""
              />
            </div>
          </div>
        </div>
        {(role === "Carrier Admin" || role === "Carrier User") && (
        <div className="card card-custom gutter-b pb-20">
          <div className="card-header">
            <div className="card-title">
              <h3 className="card-label">Invoicing email</h3>
            </div>
          </div>
          <div className="card-body">
            <Row>
              <div className="d-flex flex-wrap align-items-center font-weight-bolder">
                <span class="switch switch-outline switch-icon switch-success">
                  <label>
                    <input
                      type="checkbox"
                      checked={data?.invoiceEmailEnabled}
                      onChange={(e) => {
                        setData({ ...data, invoiceEmailEnabled: e.target.checked });
                        setEnabled(e.target.checked)
                      }}
                      name="select"/>
                    <span></span>
                  </label>
                </span>
                <p className="m-5">Enable invoicing email</p>
                      <span className={`ml-20 badge badge-${
                        data?.invoiceEmailEnabled ? "success" : "danger"
                  } `}>
                  {data?.invoiceEmailEnabled ? "Enabled" : "Disabled"}
                </span>
              </div>
              {data?.invoiceEmailEnabled && <div className="row mt-5 w-100">
                <div className="col-12">
                  <TagsInput
                    value={data?.invoicingEmail || []}
                    onChange={(value) => {
                      setData({ ...data, invoicingEmail: value });
                    }}
                    name="invoicingEmail"
                    placeHolder="Enter the email"
                    classNames="w-100 overflow-auto"
                  />
                </div>
                <div className="col-12">
                  <button
                    disabled={isLoading || data?.invoicingEmail.length === 0}
                    onClick={() => {
                      handleEnableEmail(data?.invoicingEmail,data?.invoiceEmailEnabled)
                    }}
                    className="btn btn-primary w-100 mt-5">
                    Save changes
                  </button>
                </div>
              </div>}
            </Row>
          </div>
        </div>
         )}
      </div>

      <div className="col-xl-9 col-lg-8 col-md-8">
        <div className="card card-custom">
          <div className="px-5 pt-2 border-0">
            <ul
              className="nav nav-dark nav-bold nav-tabs nav-tabs-line"
              data-remember-tab="tab_id"
              role="tablist"
            >
              <li className="nav-item">
                <a
                  style={{ cursor: "pointer" }}
                  className={`nav-link font-size-h5 ${
                    key === 1 ? "active font-weight-bolder" : ""
                    }`}
                  data-toggle="tab"
                  onClick={() => {
                    setKey(1);
                  }}
                >
                  Orders
                </a>
              </li>
              {(role === "Carrier Admin" || role === "Carrier User") && (
                <li className="nav-item">
                  <a
                    style={{ cursor: "pointer" }}
                  className={`nav-link font-size-h5 ${
                    key === 2 ? "active font-weight-bolder" : ""
                      }`}
                    data-toggle="tab"
                    onClick={() => {
                      setKey(2);
                    }}
                  >
                    To Invoice
                  </a>
                </li>
              )}
              {(role === "Carrier Admin" || role === "Carrier User") && (
                <li className="nav-item">
                  <a
                    style={{ cursor: "pointer" }}
                  className={`nav-link font-size-h5 ${
                    key === 3 ? "active font-weight-bolder" : ""
                      }`}
                    data-toggle="tab"
                    onClick={() => {
                      setKey(3);
                    }}
                  >
                    Invoices
                  </a>
                </li>
              )}
            </ul>
          </div>
          {key === 1 && (
            <>
              <div className="card-header border-0">
                <div className="card-title">
                  <h3 className="card-label">All orders</h3>{" "}
                  <span className="label label-rounded label-success mr-2">
                    {pagination.totalItemCount}
                  </span>
                </div>
              </div>
              <div className="card-body">
                <div className="col-xl-6 col-lg-6 col-md-4 mb-xl-0 mb-lg-0 mb-md-5 mb-5">
                  <input
                    type="text"
                    className="form-control"
                    name="search"
                    placeholder="Search"
                    onChange={(e) => {
                      setSearch(e.target.value);
                    }}
                  />
                  <small className="form-text text-muted">
                    <b>Search</b> in all fields
                  </small>
                </div>
                <PharmacyOrdersTable
                  search={search}
                  pagination={pagination}
                  setPagination={setPagination}
                />
              </div>
            </>
          )}
          {key === 2 && (
            <div className="card-body">
              <div className="col-xl-12 mb-md-5 mb-5">
                <PharmacyOrdersInvoices name={pharmacy?.name} />
              </div>
            </div>
          )}
          {key === 3 && (
            <div className="card-body">
              <div className="col-xl-12 mb-md-5 mb-5">
                <PharmacyInvoicesHistoy  />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PharmacyDetails;
