import React, { useEffect, useState, useRef } from "react";
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";
import SVG from "react-inlinesvg";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Modal } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import useAuthStore from "./authStore";
import { Container, Row, Col } from 'react-bootstrap';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { useReactToPrint } from "react-to-print";


export default function BackupVerificationCode({ history, currentPath ,token }) {
    const BASE_URL = process.env.REACT_APP_BASE_URL;
    const { t } = useTranslation();
    const [data, setcode] = useState(null)
    const [numRows, setnumRows] = useState(null)

    const { loginData } = useAuthStore();
    console.log(loginData?.data?.data?.token, loginData)
    const handlebackupVerification = () => {
        fetch(`${BASE_URL}/Account/TwoFactorAuthentication/backupCodes`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
            },
            Allow: "GET",
        })
            .then(async (response) => {
                const statusCode = await response.status;
                const contentType = response.headers.get("content-type");
                if (contentType && contentType.indexOf("application/json") !== -1) {
                    return response.json().then((data) => [statusCode, data]);
                }
            })
            .then((data) => {
                if (data[0] === 200) {
                    console.log(data[1]?.result)
                    setcode(data[1]?.result)
                } else if (data[0] === 401) {
                    history.push("/logout");
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };
    const [Generate, setGenerate] = useState(false)

    const handlebackupCodeGenerated = () => {
        setGenerate(true)
        fetch(`${BASE_URL}/Account/TwoFactorAuthentication/backupCodes/generate`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
            },
            Allow: "GET",
        })
            .then(async (response) => {
                const statusCode = await response.status;
                const contentType = response.headers.get("content-type");
                if (contentType && contentType.indexOf("application/json") !== -1) {
                    return response.json().then((data) => [statusCode, data]);
                }
            })
            .then((data) => {
                if (data[0] === 200) {
                    setGenerate(false)
                    setcode(data[1]?.result)
                } else if (data[0] === 401) {
                    setGenerate(false)
                    history.push("/logout");
                }
            })
            .catch((error) => {
                setGenerate(false)
                console.log(error);
            });
    };
    const CurrentDate = () => {
        const currentDate = new Date();
        const options = { month: 'long', day: 'numeric', year: 'numeric' };
        const formattedDate = currentDate.toLocaleDateString('en-US', options);
      
        return formattedDate;
      };
    const contentRef = useRef(null);
    const handlePrint = useReactToPrint({
        content: () => {
            const contentNode = contentRef.current;

            // Create a temporary wrapper for printing
            const printWrapper = document.createElement('div');
            printWrapper.appendChild(contentNode.cloneNode(true));

            // Apply styles to center the content both horizontally and vertically
            printWrapper.firstChild.style.display = 'flex';
            printWrapper.firstChild.style.alignItems = 'center';
            printWrapper.firstChild.style.justifyContent = 'center';

            // Return the temporary wrapper for printing
            return printWrapper;
        },
    });
    const handleDownload = () => {
        const content = contentRef.current;
        if (content) {
            html2canvas(content).then((canvas) => {
                const imgData = canvas.toDataURL('image/png');
                const pdf = new jsPDF('p', 'mm', 'a4');
                console.log(imgData)
                pdf.addImage(imgData, 'PNG', 0, 0, 210, 297); // A4 size: 210mm x 297mm
                pdf.save('downloaded.pdf');
            });
        }
    };
    useEffect(() => {
        if (data)
            console.log(data)
        console.log(Math.ceil(data?.length / 2))
        setnumRows(Math.ceil(data?.length / 2));
    }, [data])

    useEffect(() => {
        handlebackupVerification()
    }, [])
    return (
    <div className="login login-3 login-signin-on d-flex flex-row-fluid" >
        <div
            className="d-flex flex-center bgi-size-cover bgi-no-repeat flex-row-fluid"
        >
            <div className="col-xl-8 col-lg-8 col-md-10 col-12  p-7 position-relative overflow-hidden">
                <div ref={contentRef} className="text-center">
                    <div>
                        <div>
                            <div ref={contentRef} className="text-center" >
                                <div>
                                    <div>
                                        <h1>Backup verification Codes</h1>
                                        <div className="flex-wrap font-weight-semibold text-dark-50 mt-3 mb-10 fs-3">
                                        With 2FA enabled for your account, you'll need these backup codes if you ever 
                                        lose access to your device. Without a backup code, you'll have to contact DeliveryEase 
                                        support team to recover your account.
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-center ">
                                        <div>
                                            <div className="flex-wrap font-weight-semibold text-dark-50 fs-3">
                                                We recommend printing them and keeping them in a safe place.
                                            </div>
                                            {!!numRows === true ? <div className="d-flex justify-content-between mt-5 ">
                                                <Container>
                                                    {Array.from({ length: numRows }, (_, rowIndex) => {
                                                        console.log(numRows, rowIndex)
                                                        return <Row key={rowIndex}>
                                                            {data.slice(rowIndex * 2, rowIndex * 2 + 2).map((item) => {
                                                                console.log(item)
                                                                return <Col key={item.id}>
                                                                    <div className="label label-lg label-light-muted label-inline mt-2 w-100">
                                                                        <div className="p-5">{item.code}</div>
                                                                    </div>
                                                                </Col>
                                                            })}
                                                        </Row>
                                                    })}
                                                </Container>

                                            </div>
                                                : ""}

                                        </div>
                                    </div>
                                    <div className="flex-wrap font-weight-semibold text-dark-50  mt-5 fs-3 px-30">
                                        <div >
                                            These codes were genarated on {CurrentDate()+" "}
                                            0 have been used, 10 are still available
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                   
                </div>
                <div className="text-center">
                <span className="text-info font-weight-bold cursor-pointer" onClick={handlebackupCodeGenerated}>
                        {Generate === true?"Please wait...":"Generate new codes"}
                        </span>
                </div>
                <div className="text-center">
                        <button
                            type="button"
                            className={`btn btn-outline-primary font-weight-bold px-9 py-4 my-3 `}
                            onClick={() => handlePrint()}
                        >
                            <span>Print codes</span>
                        </button>
                    </div>
            </div>
        </div>
        {/*end::Login*/}
    </div>
    );
}
