import React, { useEffect } from "react";
import moment from "moment";
import FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { AppString } from "../../app/modules/firebase/Const";
import { myFirestore } from "../../app/modules/firebase/firebase";
import { fetchDataToggle } from "../../app/modules/firebase/firebaseHelper";
import { v1 as uuidv1 } from "uuid";
import i18next from "i18next";
import { Dropdown, Modal } from "react-bootstrap";
import { DropdownFilter } from "./DropdownFilter";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "./AssetsHelpers";

const BASE_URL = process.env.REACT_APP_BASE_URL;

export function timeSinceCountDown(eta) {
  try {
    let date = moment(eta).format("YYYY/MM/DD HH:mm:ss [GMT]");
    let etaDate = new Date(date).toLocaleString("en-US", {
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    });
    const now = new Date().toLocaleString("en-US", {
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    });

    // Calculate the difference in milliseconds
    let diff = new Date(etaDate) - new Date(now);

    // If the ETA has passed, return "00h:00"
    if (diff < 0) return "";

    // Convert milliseconds to hours and minutes
    const hours = Math.floor(diff / (1000 * 60 * 60));
    const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));

    // Format the output as "HHh:MM"
    return `${!!hours ? `${hours.toString().padStart(2, '0')}h` : ""} ${!!minutes ? `${minutes.toString().padStart(2, '0')}min` : ""}`;
  } catch (err) {
    console.log("err", err)
    return "";
  }
}

export function formatValueFromApi(value, is12HourFormat) {
  if (typeof value === 'string' && isIsoDate(value)) {
    return is12HourFormat ? ConvertToDateCurrentTimeZoneDateTime(value) : ConvertToDateCurrentTimeZoneDateTime24Hour(value)
  } else if (typeof value === 'number') {
    return currencyFormat(value);
  } else if (typeof value === 'string') {
    return value;
  } else {
    return value;
  }
}
export function formatNumberWithCommas(number) {
  const num = parseFloat(number);

  if (!isNaN(num)) {
    const roundedNumber = Math.round(num * 10000) / 10000; // Round to four decimal places
    const [integerPart, decimalPart] = roundedNumber.toFixed(2).split('.');
    const formattedInteger = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

    if (decimalPart && decimalPart !== '00') {
      return `${formattedInteger}.${decimalPart.replace(/0+$/, '')}`;
    } else {
      return formattedInteger;
    }
  } else {
    return 0;
  }
}
function isIsoDate(value) {
  const isoDatePattern = /\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}/;
  return isoDatePattern.test(value);
}
export function getColorStatus(row){
  var colotCode = "";
  switch (row.status) {
    case "Draft":
      colotCode = "#C0C0C0";
      break;
    case "Open":
      colotCode = "#4caf50";
      break;
    case "Booked":
      colotCode = "#8950FC";
      break;
    case "Out for pickup":
      colotCode = "#32C5D2";
      break;
    case "Ready for pickup":
      colotCode = "#ff7c43";
      break;
    case "In transit":
      colotCode = "#6993ff";
      break;
    case "Assigned":
      colotCode = "#FFA800";
      break;
    case "Delivered":
      colotCode = "#009688";
      break;
    case "Closed":
      colotCode = "#991f17";
      break;
    case "RxDrop":
      colotCode = "#F3A694";
      break;
    case "RTS":
      colotCode = "#6D73D9";
      break;
    case "Pending Driver":
      colotCode = "#F64E7B";
      break;

    default:
      colotCode = "";
      break;
  }
  return colotCode;
}
export function truncateString(str, maxLength) {
  try {
    if (str.length > maxLength) {
      return str.substring(0, maxLength) + "...";
    }
    return str;
  } catch (err) {
    console.log("err :", err);
  }
  return "";
}
export function RemoveSpace(str) {
  str = str.replace(/\s/g, "");
  str = str.replace(/\*/g, "");
  str = str.toLowerCase();
  return str;
}

export function CheckisRequredApi(
  a,
  cell,
  indexRow,
  errorList,
  setInfoJsonResult
) {
  var isTrue = false;
  try {
    errorList.forEach((x) => {
      var y = (x?.key ?? "").split("[")[1];
      var b = y.split("].");
      if (
        parseInt(b[0]) === indexRow &&
        RemoveSpace(a) === RemoveSpace(b[1]) &&
        !cell
      ) {
        if (!isTrue) isTrue = true;
      }
    });

    /* if (a.includes("*") && !cell) {
      setInfoJsonResult((x) => {
        x.forEach((y) => {
          if (!y[a]) y[a] = "";
        });
        return x;
      });
    } */
    return isTrue || (a.includes("*") && !cell);
  } catch (error) {
    return isTrue;
  }
}

export function DynamicModal(props) {
  return (
    <Modal
      size={props?.size}
      show={props?.show}
      enforceFocus={false}
      className={props?.className ?? ""}
      onHide={() => {
        props.setShow(false);
      }}
      {...props}
    >
      {props?.child}
    </Modal>
  );
}
export function getItemArrayColors(statusName) {
  switch (statusName) {
    case "Draft":
      return "#C0C0C0";
    case "Open":
      return "#4caf50";
    case "Booked":
      return "#8950FC";
    case "Out for pickup":
      return "#32C5D2";
    case "Ready for pickup":
      return "#ff7c43";
    case "In transit":
      return "#6993ff";
    case "Assigned":
      return "#FFA800";
    case "Delivered":
      return "#009688";
    case "Closed":
      return "#991f17";
    case "RxDrop":
      return "#F3A694";
    case "RTS":
      return "#6D73D9";
    case "Delayed":
      return "#F64E60";
    case "Pending Driver":
      return "#F64E7B";
    default:
      return "";
  }
}

export function getFromatdate() {
  if (
    localStorage.getItem("dateFormat") === null ||
    localStorage.getItem("dateFormat") === undefined ||
    localStorage.getItem("dateFormat") === ""
  ) {
    return "DD/MM/YYYY";
  } else {
    return localStorage.getItem("dateFormat").toUpperCase();
  }
}
export function getFromatdateInput() {
  if (
    localStorage.getItem("dateFormat") === null ||
    localStorage.getItem("dateFormat") === undefined ||
    localStorage.getItem("dateFormat") === ""
  ) {
    return "YYYY-DD-MM";
  } else {
    return localStorage.getItem("dateFormat").toUpperCase() === "MM/DD/YYYY" ? "MM-DD-YYYY" : "DD-MM-YYYY";
  }
}
export async function fetchNotify(email, title, code, body) {
  if (!!email === false) {
    return false;
  }
  let currentPeerUser = await myFirestore
    .collection(AppString.NODE_USERS)
    .where(AppString.NICKNAME, "==", email.toLowerCase())
    .get();
  if (currentPeerUser.docs.length === 0) return false;
  var resultUser = currentPeerUser.docs[0];
  currentPeerUser = {
    id: resultUser.data().id,
    pushToken: resultUser.data().pushToken,
    nickname: resultUser.data().nickname,
  };
  // console.log("currentPeerUser", currentPeerUser);

  //send notification
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Authorization", `key=${process.env.REACT_APP_SERVER_KEY}`);
  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: JSON.stringify({
      to: currentPeerUser?.pushToken,
      notification: {
        body: !!body ? body : title,
        title: !!body ? title : "New Order",
        sound: "default",
      },
      apns: {
        headers: {
          "apns-priority": "10",
        },
        payload: {
          aps: {
            sound: "default",
          },
        },
      },
      android: {
        priority: "high",
        notification: {
          sound: "default",
        },
      },
      data: {
        id: code,
        type: !!body ? "message-driver" : "assign_order",
      },
    }),
    redirect: "follow",
  };

  fetch("https://fcm.googleapis.com/fcm/send", requestOptions)
    .then((response) => response.text())
    .then(async (response) => {
      const res = response;
      if (res.status === 200) {
        console.log("res.body");
      }
      return false;
    })
    .then((res) => {
      if (res?.Code === "400") {
        return false;
      }
      //Save Notification list
      var uiid = uuidv1().toString();
      fetchDataToggle(`${currentPeerUser?.id}-${uiid}`, {
        type: !!body ? "message-driver" : "assign_order",
        header: title,
        body: body,
        order: code?.toString(),
        driverId: currentPeerUser.id,
        date: moment(new Date()).format("MM-DD-YYYY hh:mm a"),
        timestamp: moment()
          .valueOf()
          .toString(),
        uid: uiid,
      });
    });
}

function numberFormat(no) {
  var str = no + "";
  var ar = [];
  var i = str.length - 1;
  while (i >= 0) {
    ar.push((str[i - 2] || "") + (str[i - 1] || "") + (str[i] || ""));
    i = i - 3;
  }
  return ar.reverse().join(",");
}

export function currencyFormat(no) {
  if (no === null) {
    no = 0;
  }
  var ar = (+no).toFixed(2).split(".");
  return [numberFormat(ar[0] | 0), ".", ar[1]].join("");
}

export function translateAuto(key) {
  return i18next.t(key);
}


export const Currency = (value) => {
  return  new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD'
  }).format(value);
}

export const Number = (value) => {
  return  new Intl.NumberFormat('en-US').format(value || 0);
}


export function ExportAs(List, Type, Entity) {
  try {
    var dataExportList = JSON.parse(JSON.stringify(List));
    dataExportList.forEach((o) => {
      Object.keys(o).forEach((k) => {
        if (containsHTML(o[k])) {
          o[k] = stripHtml(o[k]);
        }
        if (
          moment(
            o[k] !== null ? String(o[k]).slice(0, 10) : "",
            "YYYY-MM-DD",
            true
          ).isValid()
        ) {
          o[k] = new Date(o[k]);
        }
      });
    });
    var date = new Date(Date.now());
    var dateformat =
      ("0" + (date.getMonth() + 1)).slice(-2) +
      "" +
      date.getDate() +
      "" +
      date.getFullYear() +
      "" +
      date.getHours() +
      "" +
      date.getMinutes() +
      "" +
      date.getSeconds();
    if (Type === "excel") {
      const fileType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      const fileExtension = ".xlsx";
      const ws = XLSX.utils.json_to_sheet(dataExportList, {
        cellDates: true,
        dateNF: "mm/dd/yyyy",
      });
      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const data = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(data, Entity + "_" + dateformat + fileExtension);
    } else if (Type === "csv") {
      const data2 = returncsv(dataExportList);
      const fileType = "text/csv;charset=utf-8";
      const fileExtension = ".csv";
      const data = new Blob([data2], { type: fileType });
      FileSaver.saveAs(data, Entity + "_" + dateformat + fileExtension);
    } else if (Type === "json") {
      const fileType = "application/json";
      const fileExtension = ".json";
      const data = new Blob([JSON.stringify(dataExportList)], {
        type: fileType,
      });
      FileSaver.saveAs(data, Entity + "_" + dateformat + fileExtension);
    }
  } catch (err) {
    console.log(err);
  }
}
export function ExportAsDataDashboard(List, Type, Entity) {
  try {
    var dataExportList = JSON.parse(JSON.stringify(List));
    dataExportList.forEach((o) => {
      Object.keys(o).forEach((k) => {
        if (containsHTML(o[k])) {
          o[k] = stripHtml(o[k]);
        }
        if (
          moment(
            o[k] !== null ? String(o[k]).slice(0, 10) : "",
            "YYYY-MM-DD",
            true
          ).isValid()
        ) {
          o[k] = new Date(o[k]);
        }
      });
    });
    var date = new Date(Date.now());
    var dateformat =
      ("0" + (date.getMonth() + 1)).slice(-2) +
      "" +
      date.getDate() +
      "" +
      date.getFullYear() +
      "" +
      date.getHours() +
      "" +
      date.getMinutes() +
      "" +
      date.getSeconds();
    if (Type === "excel") {
      const fileType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      const fileExtension = ".xlsx";
      const ws = XLSX.utils.json_to_sheet(dataExportList, {
        cellDates: true,
        dateNF: "mm/dd/yyyy",
      });
      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const data = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(data, Entity + fileExtension);
    } else if (Type === "csv") {
      const data2 = returncsv(dataExportList);
      const fileType = "text/csv;charset=utf-8";
      const fileExtension = ".csv";
      const data = new Blob([data2], { type: fileType });
      FileSaver.saveAs(data, Entity + fileExtension);
    } else if (Type === "json") {
      const fileType = "application/json";
      const fileExtension = ".json";
      const data = new Blob([JSON.stringify(dataExportList)], {
        type: fileType,
      });
      FileSaver.saveAs(data, Entity + fileExtension);
    }
  } catch (err) {
    console.log(err);
  }
}
function returncsv(values) {
  var json = values;
  var fields = Object.keys(json[0]);
  var replacer = function(key, value) {
    return value === null ? "" : value;
  };
  var csv = json.map(function(row) {
    return fields
      .map(function(fieldName) {
        return JSON.stringify(row[fieldName], replacer);
      })
      .join(",");
  });
  csv.unshift(fields.join(",")); // add header column
  csv = csv.join("\r\n");
  return csv;
}

export const containsHTML = (str) => /<[a-z][\s\S]*>/i.test(str);
export function stripHtml(html) {
  let tmp = document.createElement("DIV");
  tmp.innerHTML = html;
  return tmp.textContent || tmp.innerText || "";
}

export function getTimeZoneOffset() {
  const offsetInMinutes = new Date().getTimezoneOffset();

  const hours = Math.floor(Math.abs(offsetInMinutes) / 60);
  const sign = offsetInMinutes < 0 ? "+" : "-";

  return `${sign}${hours.toString().padStart(2, "")}`;
}

export const firstLetterUppercase = (word) => {
  return word.charAt(0).toUpperCase() + word.slice(1);
};

export function ConvertToDateCurrentTimeZone(params) {
  if (params === null || params === "" || params === undefined) {
    return "";
  } else {
    //params = params.replace("GMT", "");

    let newDate = moment(params).format("YYYY/MM/DD HH:mm:ss [GMT]");
    let dateConverted = new Date(newDate).toLocaleString("en-US", {
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    });
    return dateConverted === "Invalid Date" ? "-" : dateConverted;
  }
}
export function ConvertToDateCurrentTimeZoneForNotInput(params) {
  if (params === null || params === "" || params === undefined) {
    return "";
  } else {
    //params = params.replace("GMT", "");

    let newDate = moment(params).format("YYYY/MM/DD HH:mm:ss [GMT]");
    let dateConverted = new Date(newDate).toLocaleString("en-US", {
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    });
    return moment(dateConverted).format(`${getFromatdate()}, h:mm A`);
  }
}
export function ConvertToDateCurrentTimeZoneForNotInput24Hour(params) {
  if (params === null || params === "" || params === undefined) {
    return "";
  } else {
    //params = params.replace("GMT", "");

    let newDate = moment(params).format("YYYY/MM/DD HH:mm:ss [GMT]");
    let dateConverted = new Date(newDate).toLocaleString("en-US", {
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    });
    return moment(dateConverted).format(`${getFromatdate()}, HH:mm`);
  }
}
export function ConvertToDateCurrentTimeZoneDateTime(params) {
  if (params === null || params === "" || params === undefined) {
    return "";
  } else {
    //params = params.replace("GMT", "");

    let newDate = moment(params).format("YYYY/MM/DD HH:mm:ss [GMT]");
    let dateConverted = new Date(newDate).toLocaleString("en-US", {
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    });
    return moment(dateConverted).format(`${getFromatdate()}, h:mm A`);
  }
}
export function ConvertToDateCurrentGMT(params) {
  if (params === null || params === "" || params === undefined) {
    return "";
  } else {
    //params = params.replace("GMT", "");

    let newDate = moment(params).format("YYYY/MM/DD HH:mm:ss [GMT]");
    let dateConverted = new Date(newDate).toLocaleString("en-US", {
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    });
    return moment(dateConverted).format("YYYY/MM/DD HH:mm:ss");
  }
}
export function ConvertToDateCurrentTimeZoneDateTime24Hour(params) {
  if (params === null || params === "" || params === undefined) {
    return "";
  } else {
    //params = params.replace("GMT", "");

    let newDate = moment(params).format("YYYY/MM/DD HH:mm:ss [GMT]");
    let dateConverted = new Date(newDate).toLocaleString("en-US", {
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    });
    return moment(dateConverted).format(`${getFromatdate()}, HH:mm`);
  }
}
export function ConvertToDateCurrentTimeZoneOnlyTime(params) {
  if (params === null || params === "" || params === undefined) {
    return "";
  } else {
    //params = params.replace("GMT", "");

    let newDate = moment(params).format("YYYY/MM/DD HH:mm:ss [GMT]");
    let dateConverted = new Date(newDate).toLocaleString("en-US", {
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    });
    return moment(dateConverted).format(`h:mm A`);
  }
}
export function ConvertToDateCurrentTimeZoneOnlyTime24Hour(params) {
  if (params === null || params === "" || params === undefined) {
    return "";
  } else {
    //params = params.replace("GMT", "");

    let newDate = moment(params).format("YYYY/MM/DD HH:mm:ss [GMT]");
    let dateConverted = new Date(newDate).toLocaleString("en-US", {
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    });
    return moment(dateConverted).format(`HH:mm`);
  }
}
export function ConvertToDateCurrentTimeZoneOnlyDate(params) {
  if (params === null || params === "" || params === undefined) {
    return "";
  } else {
    //params = params.replace("GMT", "");

    let newDate = moment(params).format("YYYY/MM/DD HH:mm:ss [GMT]");
    let dateConverted = new Date(newDate).toLocaleString("en-US", {
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    });
    return moment(dateConverted).format(getFromatdate());
  }
}
export function MomentOnlyTime(params) {
  if (params === null || params === "" || params === undefined) {
    return "";
  } else return moment(ConvertToDateCurrentTimeZone(params)).format("HH:mm");
}

export function MomentOnlyDate(params) {
  if (params === null || params === "" || params === undefined) {
    return "";
  } else return moment(params).format(getFromatdate());
}

export function convertGmtToLocal(params) {
  if (!params) {
    return "";
  }
  const newDate = moment(params).format("YYYY/MM/DD HH:mm:ss [GMT]");
  const dateConverted = new Date(newDate).toLocaleString("en-US", {
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  });
  return moment(dateConverted).format(`HH:mm`);
}

export function ConvertToDateGMT(params) {
  if (params === null || params === "" || params === undefined) {
    return "";
  } else {
    let UTCDate = new Date(params).toUTCString();
    let DateFormater = moment.utc(UTCDate);
    return DateFormater;
  }
}

export const isDateInPast = (date) => {
  return date ? moment(date).isBefore(moment()) : false;
};
export function DropdownDate({ Filter, setFilter }) {
  return (
    <>
      <Dropdown className="dropdown-inline" alignRight>
        <Dropdown.Toggle id="dropdown-toggle-top" as={DropdownFilter}>
          <a
            href={() => false()}
            className="btn btn-light font-weight-bold"
          >
            <span>
              {" "}
              <i className="fas fa-sort-amount-down-alt mr-2" />
            </span>

            <span
              className=""
              id="kt_dashboard_daterangepicker_date"
            >
              {!!Filter?.Type ? Filter?.Type : "Custom Date"}
            </span>
          </a>
        </Dropdown.Toggle>
        <Dropdown.Menu className="dropdown-menu dropdown-menu-sm dropdown-menu-right">
          <div className="row w-100 mt-2 mb-2 pb-5 ml-0 border-bottom">
            <div className="col-12 mb-2">
              From
              <input
                type={`Date`}
                className="form-control form-control-sm mr-2 w-100"
                name="From"
                value={Filter?.StartDate ?? ""}
                onChange={(e) => {
                  var aa = e.target?.value;
                  setFilter((x) => {
                    x.StartDate = aa;
                    x.Type = null;
                    return { ...x };
                  });
                }}
              />
            </div>
            <div className="col-12">
              To
              <input
                type={`Date`}
                className="form-control form-control-sm mr-2 w-100"
                name="To"
                value={Filter?.EndDate ?? ""}
                onChange={(e) => {
                  var aa = e.target?.value;
                  setFilter((x) => {
                    x.EndDate = aa;
                    x.Type = null;
                    return { ...x };
                  });
                }}
              />
            </div>
          </div>

          {/* <b>Or</b> */}
          <Dropdown.Item
            onClick={() => {
              const startOfDay = moment()
                .startOf("day")
                .format("YYYY-MM-DD");
              const endOfDay = moment()
                .endOf("day")
                .format("YYYY-MM-DD");
              setFilter({
                StartDate: startOfDay,
                EndDate: endOfDay,
                Type: "day",
              });
            }}
          >
            Today
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() => {
              const startOfMonth = moment()
                .startOf("week")
                .format("YYYY-MM-DD");
              const endOfMonth = moment()
                .endOf("week")
                .format("YYYY-MM-DD");
              setFilter({
                StartDate: startOfMonth,
                EndDate: endOfMonth,
                Type: "week",
              });
            }}
          >
            This Week
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() => {
              const startOfMonth = moment()
                .startOf("month")
                .format("YYYY-MM-DD");
              const endOfMonth = moment()
                .endOf("month")
                .format("YYYY-MM-DD");
              setFilter({
                StartDate: startOfMonth,
                EndDate: endOfMonth,
                Type: "month",
              });
            }}
          >
            This Month
          </Dropdown.Item>
          {/* <Dropdown.Item
            onClick={() =>
              setFilter({ StartDate: null, EndDate: null, Type: "week" })
            }
          >
            This Quarter
          </Dropdown.Item> */}
          <Dropdown.Item
            onClick={() => {
              const startOfMonth = moment()
                .startOf("year")
                .format("YYYY-MM-DD");
              const endOfMonth = moment()
                .endOf("year")
                .format("YYYY-MM-DD");
              setFilter({
                StartDate: startOfMonth,
                EndDate: endOfMonth,
                Type: "year",
              });
            }}
          >
            This Year
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() => {
              setFilter({
                StartDate: null,
                EndDate: null,
                Type: "All",
              });
            }}
          >
            All
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
      {/*   </div>
      </div> */}
    </>
  );
}
export function DropdownDateTwo({ Filter, setFilter }) {
  return (
    <>
      <Dropdown className="dropdown-inline" alignRight>
        <Dropdown.Toggle id="dropdown-toggle-top" as={DropdownFilter}>
          <a
            href={() => false()}
            className="btn btn-light font-weight-bold"
          >
            <span>
              {" "}
              <i className="fas fa-sort-amount-down-alt mr-2" />
            </span>

            <span
              className=""
              id="kt_dashboard_daterangepicker_date"
            >
              {!!Filter?.Type ? Filter?.Type : "Custom Date"}
            </span>
          </a>
        </Dropdown.Toggle>
        <Dropdown.Menu className="dropdown-menu dropdown-menu-lg dropdown-menu-right">
          <div className="row w-100 mt-2 mb-2 pb-2 ml-0 border-bottom">
            <div className="col-6 mb-2">
              From
              <input
                type={`Date`}
                className="form-control form-control-sm mr-2 w-100"
                name="From"
                value={Filter?.StartDate ?? ""}
                onChange={(e) => {
                  var aa = e.target?.value;
                  setFilter((x) => {
                    x.StartDate = aa;
                    x.Type = null;
                    return { ...x };
                  });
                }}
              />
            </div>
            <div className="col-6">
              To
              <input
                type={`Date`}
                className="form-control form-control-sm mr-2 w-100"
                name="To"
                value={Filter?.EndDate ?? ""}
                onChange={(e) => {
                  var aa = e.target?.value;
                  setFilter((x) => {
                    x.EndDate = aa;
                    x.Type = null;
                    return { ...x };
                  });
                }}
              />
            </div>
          </div>

          {/* <b>Or</b> */}
          <Dropdown.Item
            onClick={() => {
              const startOfDay = moment()
                .startOf("day")
                .format("YYYY-MM-DD");
              const endOfDay = moment()
                .endOf("day")
                .format("YYYY-MM-DD");
              setFilter({
                StartDate: startOfDay,
                EndDate: endOfDay,
                Type: "day",
              });
            }}
          >
            Today
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() => {
              const startOfMonth = moment()
                .startOf("week")
                .format("YYYY-MM-DD");
              const endOfMonth = moment()
                .endOf("week")
                .format("YYYY-MM-DD");
              setFilter({
                StartDate: startOfMonth,
                EndDate: endOfMonth,
                Type: "week",
              });
            }}
          >
            This Week
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() => {
              const startOfMonth = moment()
                .startOf("month")
                .format("YYYY-MM-DD");
              const endOfMonth = moment()
                .endOf("month")
                .format("YYYY-MM-DD");
              setFilter({
                StartDate: startOfMonth,
                EndDate: endOfMonth,
                Type: "month",
              });
            }}
          >
            This Month
          </Dropdown.Item>
          {/* <Dropdown.Item
            onClick={() =>
              setFilter({ StartDate: null, EndDate: null, Type: "week" })
            }
          >
            This Quarter
          </Dropdown.Item> */}
          <Dropdown.Item
            onClick={() => {
              const startOfMonth = moment()
                .startOf("year")
                .format("YYYY-MM-DD");
              const endOfMonth = moment()
                .endOf("year")
                .format("YYYY-MM-DD");
              setFilter({
                StartDate: startOfMonth,
                EndDate: endOfMonth,
                Type: "year",
              });
            }}
          >
            This Year
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() => {
              setFilter({
                StartDate: null,
                EndDate: null,
                Type: "All",
              });
            }}
          >
            All
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
      {/*   </div>
      </div> */}
    </>
  );
}

export const GetRole = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  const role = user?.roles?.[0] || null;
  return role;
}

export function Impersonate({ id }) {
  const token = JSON.parse(localStorage.getItem("user"))?.token;
  const role = JSON.parse(localStorage.getItem("user"))?.roles[0];
  const { t } = useTranslation();
  const history = useHistory();
  const HandelLogin = async () => {
    await fetch(`${BASE_URL}/Pharmacies/Impersonte/${id}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        Authorization: `bearer ${token}`,
      },
      Allow: "POST",
    })
      .then(async (response) => {
        const statusCode = response.status;
        const contentType = response.headers.get("content-type");

        if (contentType && contentType.indexOf("application/json") !== -1) {
          return response.json().then((data) => [statusCode, data]);
        } else {
          return response.text().then((data) => [statusCode, data]);
        }
      })
      .then((data) => {
        if (data[0] === 200) {
          localStorage.setItem(
            "UserEmail",
            JSON.parse(localStorage.getItem("user"))?.username
          );
          localStorage.removeItem("user");
          localStorage.removeItem("ProfilePicture");
          localStorage.removeItem("dateFormat");
          localStorage.setItem("user", JSON.stringify(data[1]));
          localStorage.setItem("useSso", JSON.stringify(data[1].useSso || false));
          localStorage.setItem(
            "ProfilePicture",
            JSON.stringify(data[1].profilePicture)
          );
          localStorage.setItem(
            "dateFormat",
            data[1]?.dateFormat !== null &&
              data[1]?.dateFormat !== undefined &&
              data[1]?.dateFormat !== ""
              ? data[1]?.dateFormat
              : "DD/MM/YYYY"
          );
          localStorage.setItem("IconShow", true);
          const user = {
            accessToken: "access-token-8f3ae836da744329a6f93bf20594b5cc",
            refreshToken: "access-token-f8c137a2c98743f48b643e71161d90aa",
          };
          if(data[1]?.roles[0] === "Dispatcher"){
            history.push("/command-center");
          }else{
            history.push("/dashboard");
          }
          window.location.reload();
          if (user) {
            return [200, { ...user, password: undefined }];
          }
        } else if (data[0] === 400 && data[1].message) {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: data[1].message,
          });

          return [400];
        } else if (data[0] === 400 && data[1].errors) {
          for (const [key, value] of Object.entries(data[1].errors)) {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: value,
            });
          }

          return [400];
        }
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: error,
        });

        return [400];
      });
  };
  return (
    <>
      {role === "Business Admin" || role === "Carrier Admin" ? (
        <li className="navi-item">
          <div
            onClick={() => {
              Swal.fire({
                title: "Are you sure?",
                text: "Do you want to login it !",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#d33",
                cancelButtonColor: "#0058B1",
                confirmButtonText: "Yes, I do!",
              }).then((result) => {
                if (result.isConfirmed) {
                  HandelLogin();
                }
              });
            }}
            className="navi-link cursor-pointer"
          >
            <h3
              style={{ cursor: "pointer" }}
              className={`svg-icon svg-icon-md svg-icon-info mr-2`}
            >
              <SVG src={toAbsoluteUrl("/media/svg/icons/Home/Key.svg")} />
            </h3>
            <h3 className="navi-text">{t("Access this account")}</h3>
          </div>
        </li>
      ) : (
        ""
      )}
    </>
  );
}

export const CustomCheckbox = ({ type, checked, disabled, onChange }) => {
  return (
    <>
      <div className="checkbox-list">
      <input
      type={"checkbox"}
      checked={checked}
      disabled={disabled}
      onChange={onChange}
      style={{
        width: "18px", // Custom width
        height: "18px", // Custom height
        cursor: "pointer",
        border: "transparent",
        borderRadius: "4px",
        accentColor: "#EC520b", // Custom color for the checkbox (only works in some browsers)
        backgroundColor: checked ? "#EC520b" : "#EBEDF3", // Background color when checked
      }}
    />
    <span></span>
    </div>
    </>
  );
};


export const PickupTimes = [
  { value: "00:00", label: "12:00 AM" },
  { value: "00:30", label: "12:30 AM" },
  { value: "01:00", label: "01:00 AM" },
  { value: "01:30", label: "01:30 AM" },
  { value: "02:00", label: "02:00 AM" },
  { value: "02:30", label: "02:30 AM" },
  { value: "03:00", label: "03:00 AM" },
  { value: "03:30", label: "03:30 AM" },
  { value: "04:00", label: "04:00 AM" },
  { value: "04:30", label: "04:30 AM" },
  { value: "05:00", label: "05:00 AM" },
  { value: "05:30", label: "05:30 AM" },
  { value: "06:00", label: "06:00 AM" },
  { value: "06:30", label: "06:30 AM" },
  { value: "07:00", label: "07:00 AM" },
  { value: "07:30", label: "07:30 AM" },
  { value: "08:00", label: "08:00 AM" },
  { value: "08:30", label: "08:30 AM" },
  { value: "09:00", label: "09:00 AM" },
  { value: "09:30", label: "09:30 AM" },
  { value: "10:00", label: "10:00 AM" },
  { value: "10:30", label: "10:30 AM" },
  { value: "11:00", label: "11:00 AM" },
  { value: "11:30", label: "11:30 AM" },
  { value: "12:00", label: "12:00 PM" },
  { value: "12:30", label: "12:30 PM" },
  { value: "13:00", label: "01:00 PM" },
  { value: "13:30", label: "01:30 PM" },
  { value: "14:00", label: "02:00 PM" },
  { value: "14:30", label: "02:30 PM" },
  { value: "15:00", label: "03:00 PM" },
  { value: "15:30", label: "03:30 PM" },
  { value: "16:00", label: "04:00 PM" },
  { value: "16:30", label: "04:30 PM" },
  { value: "17:00", label: "05:00 PM" },
  { value: "17:30", label: "05:30 PM" },
  { value: "18:00", label: "06:00 PM" },
  { value: "18:30", label: "06:30 PM" },
  { value: "19:00", label: "07:00 PM" },
  { value: "19:30", label: "07:30 PM" },
  { value: "20:00", label: "08:00 PM" },
  { value: "20:30", label: "08:30 PM" },
  { value: "21:00", label: "09:00 PM" },
  { value: "21:30", label: "09:30 PM" },
  { value: "22:00", label: "10:00 PM" },
  { value: "22:30", label: "10:30 PM" },
  { value: "23:00", label: "11:00 PM" },
  { value: "23:30", label: "11:30 PM" },
];

  export const PickupTimes24Hours = [
    { value: "00:00", label: "00:00" },
    { value: "00:30", label: "00:30" },
    { value: "01:00", label: "01:00" },
    { value: "01:30", label: "01:30" },
    { value: "02:00", label: "02:00" },
    { value: "02:30", label: "02:30" },
    { value: "03:00", label: "03:00" },
    { value: "03:30", label: "03:30" },
    { value: "04:00", label: "04:00" },
    { value: "04:30", label: "04:30" },
    { value: "05:00", label: "05:00" },
    { value: "05:30", label: "05:30" },
    { value: "06:00", label: "06:00" },
    { value: "06:30", label: "06:30" },
    { value: "07:00", label: "07:00" },
    { value: "07:30", label: "07:30" },
    { value: "08:00", label: "08:00" },
    { value: "08:30", label: "08:30" },
    { value: "09:00", label: "09:00" },
    { value: "09:30", label: "09:30" },
    { value: "10:00", label: "10:00" },
    { value: "10:30", label: "10:30" },
    { value: "11:00", label: "11:00" },
    { value: "11:30", label: "11:30" },
    { value: "12:00", label: "12:00" },
    { value: "12:30", label: "12:30" },
    { value: "13:00", label: "13:00" },
    { value: "13:30", label: "13:30" },
    { value: "14:00", label: "14:00" },
    { value: "14:30", label: "14:30" },
    { value: "15:00", label: "15:00" },
    { value: "15:30", label: "15:30" },
    { value: "16:00", label: "16:00" },
    { value: "16:30", label: "16:30" },
    { value: "17:00", label: "17:00" },
    { value: "17:30", label: "17:30" },
    { value: "18:00", label: "18:00" },
    { value: "18:30", label: "18:30" },
    { value: "19:00", label: "19:00" },
    { value: "19:30", label: "19:30" },
    { value: "20:00", label: "20:00" },
    { value: "20:30", label: "20:30" },
    { value: "21:00", label: "21:00" },
    { value: "21:30", label: "21:30" },
    { value: "22:00", label: "22:00" },
    { value: "22:30", label: "22:30" },
    { value: "23:00", label: "23:00" },
    { value: "23:30", label: "23:30" },
  ];

  
export const Times = [
  { value: -1, label: "12:00 AM" },
  { value: 1, label: "01:00 AM" },
  { value: 2, label: "02:00 AM" },
  { value: 3, label: "03:00 AM" },
  { value: 4, label: "04:00 AM" },
  { value: 5, label: "05:00 AM" },
  { value: 6, label: "06:00 AM" },
  { value: 7, label: "07:00 AM" },
  { value: 8, label: "08:00 AM" },
  { value: 9, label: "09:00 AM" },
  { value: 10, label: "10:00 AM" },
  { value: 11, label: "11:00 AM" },
  { value: 12, label: "12:00 PM" },
  { value: 13, label: "01:00 PM" },
  { value: 14, label: "02:00 PM" },
  { value: 15, label: "03:00 PM" },
  { value: 16, label: "04:00 PM" },
  { value: 17, label: "05:00 PM" },
  { value: 18, label: "06:00 PM" },
  { value: 19, label: "07:00 PM" },
  { value: 20, label: "08:00 PM" },
  { value: 21, label: "09:00 PM" },
  { value: 22, label: "10:00 PM" },
  { value: 23, label: "11:00 PM" },
];


export function getComputerOffset() {
  // Get the timezone offset in minutes and convert it to hours
  const offsetMinutes = moment().utcOffset();
  const offsetHours = offsetMinutes / 60;
  
  return offsetHours;
}

export function getFormattedOffset(offsetHours) {
  // Create a moment object with the desired offset
  const offset = moment().utcOffset(offsetHours * 60);

  // Format the offset to show as a string, like "UTC-05:00"
  const offsetString = `UTC${offset.format("Z")}`;
  
  return offsetString;
}