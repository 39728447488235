import { Formik, useFormik } from "formik";
import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Modal,
  OverlayTrigger,
  Row,
  Spinner,
  Tooltip,
} from "react-bootstrap";
import { useTranslation } from "react-i18next";
import SVG from "react-inlinesvg";
import ReactSelect from "react-select";
import Swal from "sweetalert2";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import {
  ModelStateMessage,
  apiCalll,
  creditCardTypeFromNumber,
  setValueField
} from "../../../_metronic/_helpers/apiHelpers";
import { DynamicModal } from "../../../_metronic/_helpers/GlobalHelpers";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../_metronic/_partials/controls";
import Payment from "../UserProfile/stripe/Payment";
import { useHistory } from "react-router-dom";

export default function PaymentDelivery(props) {
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const [token, setToken] = useState("");
  const history = useHistory();
  const [currentId, setCurrentId] = useState("");
  const [data, setData] = useState(null);
  const [isError, setIsError] = useState(false);
  const [modalStripe, setModalStripe] = useState(false);
  const [payWithCash, setPayWithCash] = useState(true);
  const [loadingPayout, setLoadingPayout] = useState(false);
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [reasonSkip, setReasonSkip] = useState("");
  const [modalPaymentCard, setModalPaymentCard] = useState(false);
  const [modalReasonSkip, setModalReasonSkip] = useState(false);
  const [isSuccessfulPayment, setIsSuccessfulPayment] = useState(null);
  const [clientSecret, setclientSecret] = useState(
    "seti_1O5Uux44XggjBBCkPaNKmab6_secret_OtHTF2i2LmVci9WyyCivVI84puJKcZ1"
  );
  const [Key, setKey] = useState(null);
  function extractLastTwoDigits(inputString) {
    // Use a regular expression to match the last two digits in the string
    const regex = /(\d{2})\D*$/;
    const match = inputString.match(regex);

    // Check if there was a match
    if (match && match[1]) {
      // Extract and return the last two digits as a number
      return parseInt(match[1], 10).toString();
    } else {
      // Return an error value or handle the case when no digits are found
      return NaN; // NaN represents "Not-a-Number"
    }
  }
  useEffect(() => {
    try {
      var url = window.location.href.split("/deliveryPayment");
      var token = url[1].split("&id=")[0].replace("?token=", "");
      var id = url[1].split("&id=")[1];
      getData(id, token);
      setToken(token);
      setCurrentId(id);

      //hide subheader
      var element = document.getElementById("kt_subheader");
      if (!!element) element.style.display = "none";
    } catch (err) {
      console.log("🚀 ~ file: index.js:28 ~ useEffect ~ err:", err);
    }
  }, []);
  var initValuesCard = {
    orderId: "",
    cardName: "",
    cardNumber: "",
    stripeCardToken: "",
    stripeCardId: "",
    expiryMonth: "",
    expiryYear: "",
    cardCvc: "",
  };
  const [valuesCard, setValuesCard] = useState(initValuesCard);
  useEffect(() => {
    if (!modalStripe || modalPaymentCard) {
      setValuesCard(initValuesCard);
    }
  }, [modalStripe, modalPaymentCard]);
  const formik2 = useFormik({
    initialValues: valuesCard,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      // billing(values)
    },
  });

  const getPayWithCash = (id) => {
    fetch(`${BASE_URL}/Pharmacies/getCash/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json"
      },
      Allow: "GET",
    })
      .then(async (response) => {
        const statusCode = await response.status;
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
          return response.json().then((data) => [statusCode, data]);
        } else {
          return response.text().then((data) => [statusCode, data]);
        }
      })
      .then((data) => {
        if (data[0] === 200) {
          setPayWithCash(data[1].hideCash);
        }
        else if (data[0] === 401) {
          history.push("/logout");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  async function getData(id, token) {
    apiCalll({
      method: "GET",
      link: `/Orders/Details/${id}`,
      token: token,
    }).then(async (result) => {
      try {
        if (result.length > 0 && result[0] === 200) {
          var a = result[1];
          setData(a);
          setIsError(false);
          setValueField(valuesCard, setValuesCard, id, "orderId");
          setValueField(valuesCard, setValuesCard, id, "orderId");
          getPayWithCash(a.order.pharmacyId);
        } else {
          setIsError(true);
          ModelStateMessage(result);
        }
      } catch (err) {}
    });
    setToken(token);
    setCurrentId(id);
  }
  const { t } = useTranslation();

  const listMonth = [];
  const listMonthSelect = [];
  const listYear = [];
  const listYearSelect = [];
  var currentTime = new Date().getFullYear();
  for (let index = 1; index <= 12; index++) {
    listMonth.push(<option>{index}</option>);
    listMonthSelect.push(index);
  }
  for (let index = 0; index <= 20; index++) {
    listYear.push(<option>{currentTime + index}</option>);
    listYearSelect.push(currentTime + index);
  }
  function ImageCardNumber({ value }) {
    return (
      <img
        src={toAbsoluteUrl(
          creditCardTypeFromNumber(value) === "Visa"
            ? "/media/svg/card-logos/visa.svg"
            : creditCardTypeFromNumber(value) === "MasterCard"
            ? "/media/svg/card-logos/mastercard.svg"
            : creditCardTypeFromNumber(value) === "Amex"
            ? "/media/svg/card-logos/american-express.svg"
            : creditCardTypeFromNumber(value) === "Discover"
            ? "/media/svg/card-logos/discover.svg"
            : creditCardTypeFromNumber(value) === "UnionPay"
            ? "/media/svg/card-logos/unionpay.svg"
            : creditCardTypeFromNumber(value) === "JCB"
            ? "/media/svg/card-logos/jcb.svg"
            : creditCardTypeFromNumber(value) === "Diners Club"
            ? "/media/svg/card-logos/diners-club.svg"
            : creditCardTypeFromNumber(value) === "Union Pay"
            ? "/media/svg/card-logos/unionpay.svg"
            : ""
        )}
        alt=""
        className="h-25px"
      />
    );
  }
  return (
    <>
      <div id="kt_header_mobile" className="header-mobile">
        <span>
          <img
            alt="Logo"
            className="logo-default max-h-30px"
            src="/media/logos/logo-letter-2.svg"
          />
        </span>
        <h3>COD Payment</h3>
      </div>
      <div className="d-flex flex-column flex-root">
        {/*begin::Page*/}
        <div className="d-flex flex-row flex-column-fluid page">
          {/*begin::Wrapper*/}
          <div
            className="d-flex flex-column flex-row-fluid wrapper"
            id="kt_wrapper"
          >
            {/*begin::Content*/}
            <div
              id="kt_content"
              className={`content d-flex flex-column flex-column-fluid`}
              style={{
                placeContent:
                  isSuccessfulPayment || !!data === false
                    ? "center"
                    : "inherit",
              }}
            >
              {!!data === false ? (
                <Row>
                  <Col>
                    <Card>
                      <CardBody>
                        <div className="d-flex justify-content-center">
                          {!isError ? (
                            <h2 className="ml-5 mt-2 text-muted">Loading..</h2>
                          ) : (
                            <h2 className="ml-5 mt-2 text-muted">
                              401 Unauthorized...
                            </h2>
                          )}
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              ) : !!data?.order?.gpSuccess === false && data?.order?.paymentMethod !== "not paid"? (
                <Row>
                  <Col>
                    <Card className="mb-10">
                      <CardBody>
                        <div className="d-flex justify-content-between">
                          <h2 className="font-weight-bold">
                            #{data?.order?.code ?? "..."}
                          </h2>

                          <h2 className="font-weight-bold text-success">
                            ${data?.order?.cod ?? "..."}
                          </h2>
                        </div>
                      </CardBody>
                    </Card>
                    <Card>
                      <CardBody>
                        <div className="d-flex flex-column align-items-center align-items-center">
                          {(payWithCash == false || payWithCash == null) ? <button
                            className="btn btn-primary w-100 p-7 font-size-xl mb-10"
                            onClick={(e) => {
                              e.preventDefault();
                              Swal.fire({
                                title: "Are you sure?",
                                text: `You want to pay this order #${data?.order?.code} with cash for amount $${data?.order?.cod}`,
                                icon: "warning",
                                showCancelButton: true,
                                confirmButtonColor: "#013C73",
                                cancelButtonColor: "#d33",
                                cancelButtonText: "Cancel",
                                confirmButtonText: "Confirm now",
                              }).then(async (result) => {
                                if (result.isConfirmed) {
                                  apiCalll({
                                    method: "POST",
                                    link: `/orders/MarkAsPaidByHand`,
                                    token: token,
                                    data: {
                                      id: currentId,
                                      type: "cash",
                                    },
                                  }).then(async (result) => {
                                    try {
                                      if (
                                        result.length > 0 &&
                                        result[0] === 200
                                      ) {
                                        Swal.fire({
                                          icon: "success",
                                          title: "Success",
                                          text: "Successful payment",
                                        });
                                        getData(currentId, token);
                                      } else {
                                        ModelStateMessage(result);
                                      }
                                    } catch (err) {}
                                  });
                                }
                              });
                            }}
                          >
                            <div className="d-flex justify-content-center">
                              <span className="svg-icon svg-icon-2x svg-icon-primary">
                                <SVG
                                  src={toAbsoluteUrl(
                                    "/media/svg/icons/Tools/cash.svg"
                                  )}
                                />
                              </span>
                              <span className="ml-8">Pay with Cash</span>
                            </div>
                          </button>
                          : ""
                          }
                          <button
                            className="btn btn-primary mb-10 w-100 p-7 font-size-xl"
                            onClick={(e) => {
                              e.preventDefault();
                              Swal.fire({
                                title: "Are you sure?",
                                text: `You want to pay this order #${data?.order?.code} with cheque for amount $${data?.order?.cod}`,
                                icon: "warning",
                                showCancelButton: true,
                                confirmButtonColor: "#013C73",
                                cancelButtonColor: "#d33",
                                cancelButtonText: "Cancel",
                                confirmButtonText: "Confirm now",
                              }).then(async (result) => {
                                if (result.isConfirmed) {
                                  apiCalll({
                                    method: "POST",
                                    link: `/orders/MarkAsPaidByHand`,
                                    token: token,
                                    data: {
                                      id: currentId,
                                      type: "cheque",
                                    },
                                  }).then(async (result) => {
                                    try {
                                      if (
                                        result.length > 0 &&
                                        result[0] === 200
                                      ) {
                                        Swal.fire({
                                          icon: "success",
                                          title: "Success",
                                          text: "Successful payment",
                                        });
                                        getData(currentId, token);
                                      } else {
                                        ModelStateMessage(result);
                                      }
                                    } catch (err) {}
                                  });
                                }
                              });
                            }}
                          >
                            <div className="d-flex justify-content-center align-items-center">
                              <span className="svg-icon svg-icon-2x svg-icon-primary">
                                <SVG
                                  src={toAbsoluteUrl(
                                    "/media/svg/icons/Tools/cheque.svg"
                                  )}
                                />
                              </span>
                              <span className="ml-8">Pay with Cheque</span>
                            </div>
                          </button>
                            {!data?.hidePayment &&
                              (<button
                                className="btn btn-primary mb-10 w-100 p-7 font-size-xl"
                                disabled={loadingBtn}
                                onClick={async (e) => {
                                  e.preventDefault();

                                  if (
                                    data?.order?.processor === "moneris" ||
                                    data?.order?.processor === "globalPayment"
                                  ) {
                                    setModalPaymentCard(true);
                                  } else if (
                                    data?.order?.processor ===
                                    "deliveryeaseStripe" ||
                                    data?.order?.processor === "stripe"
                                  ) {
                                    setLoadingBtn(true);
                                    await apiCalll({
                                      method: "GET",
                                      link: `/billing/CreateSetupIntentDriver/${currentId}`,
                                      token: token,
                                    }).then(async (result) => {
                                      try {
                                        if (
                                          result.length > 0 &&
                                          result[0] === 200
                                        ) {
                                          var a = result[1];
                                          setclientSecret(a?.clientSecret);
                                          setKey(a?.stripePublishableKey);
                                          setTimeout(() => {
                                            setModalStripe(true);
                                            setLoadingBtn(false);
                                          }, 1000);
                                        } else {
                                          ModelStateMessage(result);
                                          setLoadingBtn(false);
                                        }
                                      } catch (err) {
                                        setLoadingBtn(false);
                                      }
                                    });
                                  }
                                }}
                              >
                            <div className="d-flex justify-content-center align-items-center">
                                  <span className="svg-icon svg-icon-2x svg-icon-primary">
                                    <SVG
                                      src={toAbsoluteUrl(
                                        "/media/svg/icons/Tools/creditCard.svg"
                                      )}
                                    />
                                    <span className="ml-8">
                                      Pay with Credit Card
                                    </span>
                                  </span>
                                </div>
                                {loadingBtn && (
                                  <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                    className="ml-2"
                                  />
                                )}
                              </button>)}
                          <button
                            className="btn btn-primary w-100 p-7 font-size-xl mb-10"
                            onClick={(e) => {
                              e.preventDefault();
                              setModalReasonSkip(true);
                            }}
                          >
                            <div className="d-flex justify-content-center align-items-center">
                              <span className="svg-icon svg-icon-2x svg-icon-primary">
                                <SVG
                                  src={toAbsoluteUrl(
                                    "/media/svg/icons/Tools/cancel.svg"
                                  )}
                                />
                              </span>
                              <span className="ml-8">Skip Payment</span>
                            </div>
                          </button>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              ) : !!data?.order?.gpSuccess === false &&
                data?.order?.paymentMethod === "not paid" ? (
                <Row>
                  <Col>
                    <Card>
                      <CardBody>
                        <div className="d-flex">
                          <i
                            className="flaticon2-check-mark text-success mr-4"
                            style={{ fontSize: "3rem" }}
                          ></i>
                          <div className="d-inline">
                            <h2 className="ml-5 mt-2">
                              Order #{data?.order?.code} has been{" "}
                              skipped for payment {" "}
                              successfully.{" "}
                            </h2>
                            <span className="text-muted ml-4 font-size-lg">
                                You can check now the app mobile for this order
                                to complete the process.
                              </span>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              ) : (
                <Row>
                  <Col>
                    <Card>
                      <CardBody>
                        <div className="d-flex">
                          <i
                            className="flaticon2-check-mark text-success mr-4"
                            style={{ fontSize: "3rem" }}
                          ></i>
                          <div className="d-inline">
                            <h2 className="ml-5 mt-2">
                              Order #{data?.order?.code} has been{" "}
                              paid {" "}
                              successfully.{" "}
                            </h2>
                            <span className="text-muted ml-4 font-size-lg">
                                You can check the payment now on your app mobile
                                for this order to complete the process.
                              </span>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              )}
              <Modal
                size="lg"
                show={modalPaymentCard}
                onHide={() => {
                  setModalPaymentCard(false);
                }}
              >
                <Formik initialValues={valuesCard} enableReinitialize={true}>
                  {({
                    values,
                    status,
                    errors,
                    touched,
                    setFieldValue,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isValid,
                    isSubmitting,
                  }) => (
                    <>
                      <Modal.Header closeButton>
                        <Modal.Title>
                          {t("Pay with credit card for")}
                          <span className="text-success ml-2">
                            (${data?.order?.cod})
                          </span>
                        </Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <div className="pb-10 col-md-12">
                          <Row>
                            <Col>
                              {/*begin::Input*/}
                              <div className="form-group fv-plugins-icon-container">
                                <label>
                                  {t("Name on card")}{" "}
                                  <span className="text-danger">*</span>
                                  <OverlayTrigger
                                    overlay={
                                      <Tooltip
                                        style={{ pointerEvents: "none" }}
                                        id="products-delete-tooltip"
                                      >
                                        {t("Specify a card holder name")}
                                      </Tooltip>
                                    }
                                  >
                                    <i className="fa fa-exclamation-circle ml-2 fs-7"></i>
                                  </OverlayTrigger>
                                </label>
                                <input
                                  type="text"
                                  className="form-control  form-control-md"
                                  placeholder="Card Name"
                                  onChange={(e) => {
                                    setValueField(
                                      valuesCard,
                                      setValuesCard,
                                      e.target.value,
                                      "cardName"
                                    );
                                  }}
                                  name="cardName"
                                />
                              </div>
                              {/*end::Input*/}
                              {/*begin::Input*/}
                              <div className="form-group">
                                <label>
                                  {t("Card number")}
                                  <span className="text-danger">*</span>
                                </label>
                                <div className="position-relative">
                                  <input
                                    type="number"
                                    className="form-control  form-control-md"
                                    placeholder={t("Card number")}
                                    /* value={values.cardNumber} */
                                    onChange={(e) => {
                                      const length = e?.target?.value?.length;
                                      if (length > 19) {
                                        return false;
                                      } else {
                                        setValueField(
                                          valuesCard,
                                          setValuesCard,
                                          e.target.value,
                                          "cardNumber"
                                        );
                                      }
                                    }}
                                    name="cardNumber"
                                  />
                                  {valuesCard.cardNumber !== "" && (
                                    <div className="position-absolute translate-middle-c top-50 end-0 mr-5">
                                      <ImageCardNumber
                                        value={valuesCard.cardNumber}
                                      />
                                    </div>
                                  )}
                                </div>
                              </div>
                              {/*end::Input*/}
                              <div className="row">
                                <div className="col-xl-4">
                                  {/*begin::Input*/}
                                  <div className="form-group fv-plugins-icon-container">
                                    <label>
                                      {t("Expiration date")}
                                      <span className="text-danger">*</span>
                                    </label>
                                    <ReactSelect
                                      onChange={(e) => {
                                        setValueField(
                                          valuesCard,
                                          setValuesCard,
                                          e?.value <= 9 || e?.value >= 1
                                            ? `0${e?.value}`
                                            : e?.value,
                                          "expiryMonth"
                                        );
                                      }}
                                      name="expiryMonth"
                                      isSearchable={true}
                                      placeholder={t("Month")}
                                      options={listMonthSelect.map((a) => {
                                        return {
                                          label: a,
                                          value: a,
                                        };
                                      })}
                                    />
                                  </div>
                                  {/*end::Input*/}
                                </div>

                                <div className="col-xl-4">
                                  {/*begin::Input*/}
                                  <div className="form-group fv-plugins-icon-container mt-8">
                                    <ReactSelect
                                      onChange={(e) => {
                                        setValueField(
                                          valuesCard,
                                          setValuesCard,
                                          e?.value?.toString(),
                                          "expiryYear"
                                        );
                                      }}
                                      name="expiryYear"
                                      isSearchable={true}
                                      placeholder={t("Year")}
                                      options={listYearSelect.map((a) => {
                                        return {
                                          label: a,
                                          value: a,
                                        };
                                      })}
                                    />

                                    {/* {errors.CardExpiryYear && touched.CardExpiryYear ? (
                                                      <div className="fv-plugins-message-container">
                                                          <div
                                                              data-field="width"
                                                              data-validator="digits"
                                                              className="fv-help-block text-danger"
                                                          >
                                                              {errors.CardExpiryYear}
                                                          </div>
                                                      </div>
                                                  ) : null} */}
                                  </div>
                                  {/*end::Input*/}
                                </div>

                                <div className="col-xl-4">
                                  {/*begin::Input*/}
                                  <div className="form-group fv-plugins-icon-container">
                                    <label>
                                      {t("CVV")}{" "}
                                      <span className="text-danger">*</span>
                                      <OverlayTrigger
                                        overlay={
                                          <Tooltip
                                            style={{ pointerEvents: "none" }}
                                            id="products-delete-tooltip"
                                          >
                                            {t("Enter a cvv code")}
                                          </Tooltip>
                                        }
                                      >
                                        <i className="fa fa-exclamation-circle ml-2 fs-7"></i>
                                      </OverlayTrigger>
                                    </label>
                                    <div className="position-relative">
                                      <input
                                        type="number"
                                        className="form-control form-control-md"
                                        placeholder={t("CVV")}
                                        /* value={valuesCard.cardCvc} */
                                        onChange={(e) => {
                                          const length =
                                            e?.target?.value?.length;
                                          if (length > 4) {
                                            return false;
                                          } else {
                                            setValueField(
                                              valuesCard,
                                              setValuesCard,
                                              e.target.value,
                                              "cardCvc"
                                            );
                                          }
                                        }}
                                        name="cardCvc"
                                      />
                                      <div className="position-absolute translate-middle-c top-50 end-0 mr-3">
                                        <span className="svg-icon svg-icon-2hx">
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width={24}
                                            height={24}
                                            viewBox="0 0 24 24"
                                            fill="none"
                                          >
                                            <path
                                              d="M22 7H2V11H22V7Z"
                                              fill="currentColor"
                                            />
                                            <path
                                              opacity="0.3"
                                              d="M21 19H3C2.4 19 2 18.6 2 18V6C2 5.4 2.4 5 3 5H21C21.6 5 22 5.4 22 6V18C22 18.6 21.6 19 21 19ZM14 14C14 13.4 13.6 13 13 13H5C4.4 13 4 13.4 4 14C4 14.6 4.4 15 5 15H13C13.6 15 14 14.6 14 14ZM16 15.5C16 16.3 16.7 17 17.5 17H18.5C19.3 17 20 16.3 20 15.5C20 14.7 19.3 14 18.5 14H17.5C16.7 14 16 14.7 16 15.5Z"
                                              fill="currentColor"
                                            />
                                          </svg>
                                        </span>
                                      </div>
                                    </div>

                                    {/* {errors.CardCvc && touched.CardCvc ? (
                                                      <div className="fv-plugins-message-container invalid-feedback">
                                                          * {errors.CardCvc}
                                                      </div>
                                                  ) : null} */}
                                    <div className="fv-plugins-message-container" />
                                  </div>
                                  {/*end::Input*/}
                                </div>
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </Modal.Body>
                      <Modal.Footer className="d-flex justify-content-between">
                        <div>
                          <span className="font-weight-boldest">
                            Powered by
                          </span>
                          {data?.order?.processor === "stripe" ||
                          data?.order?.processor === "deliveryeaseStripe" ? (
                            <img
                              src={toAbsoluteUrl(
                                "/media/svg/icons/Tools/stripePowered.png"
                              )}
                              alt="stripePowered"
                              style={{ width: "34%" }}
                            />
                          ) : data?.order?.processor === "moneris" ? (
                            <img
                              src={toAbsoluteUrl(
                                "/media/svg/icons/Tools/moneris.png"
                              )}
                              alt="moneris"
                              style={{ width: "82px" }}
                            />
                          ) : (
                            ""
                          )}
                        </div>
                        <div>
                          <Button
                            variant="secondary"
                            className="mr-4"
                            onClick={() => {
                              setModalPaymentCard(false);
                            }}
                          >
                            {t("Close")}
                          </Button>
                          <Button
                            variant="primary"
                            disabled={
                              loadingPayout ||
                              !!valuesCard.expiryMonth === false ||
                              !!valuesCard.expiryYear === false ||
                              !!valuesCard.cardName === false ||
                              !!valuesCard.cardNumber === false ||
                              !!valuesCard.cardCvc === false ||
                              valuesCard.cardNumber.length < 14 ||
                              valuesCard.cardCvc.length < 3
                            }
                            onClick={async () => {
                              if (!loadingPayout) {
                                setLoadingPayout(true);

                                setTimeout(async () => {
                                  
                                valuesCard.orderId = currentId;
                                valuesCard.expiryYear = extractLastTwoDigits(
                                  valuesCard.expiryYear
                                );
                                await apiCalll({
                                  method: "POST",
                                  link: `/Orders/MarkAsPaidByCard`,
                                  data: valuesCard,
                                  token: token,
                                }).then((result) => {
                                  try {
                                    if (result?.length > 0 && result[0] === 200) {
                                      Swal.fire({
                                        position: "top-center",
                                        icon: "success",
                                        title: t("Successful payment"),
                                        showConfirmButton: false,
                                        timer: 1500,
                                      });
                                      setModalPaymentCard(false);
                                      getData(currentId, token);
                                      setLoadingPayout(false);
                                    } else {
                                      ModelStateMessage(result);
                                      setLoadingPayout(false);
                                    }
                                  } catch (err) {
                                    setLoadingPayout(false);
                                  }
                                });
                              }, 988);

                              }
                            }}
                          >
                            {loadingPayout ? "Please wait..." : t("Pay now")}
                          </Button>
                        </div>
                      </Modal.Footer>
                    </>
                  )}
                </Formik>
              </Modal>

              <Modal
                size="lg"
                show={modalStripe}
                onHide={() => {
                  setModalStripe(false);
                }}
              >
                <>
                  <Modal.Header closeButton>
                    <Modal.Title>
                      {t("Checkout for")}
                      <span className="text-success ml-2">
                        (${data?.order?.cod})
                      </span>
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <div className="pb-10 col-md-12">
                      <Row>
                        <Col>
                          <div className="form-group fv-plugins-icon-container">
                            <label>
                              {t("Name on card")}{" "}
                              <span className="text-danger">*</span>
                            </label>
                            <input
                              type="text"
                              className="form-control  form-control-md"
                              placeholder="Enter card name"
                              name="cardName"
                              value={valuesCard.cardName}
                              onChange={(e) => {
                                setValueField(
                                  valuesCard,
                                  setValuesCard,
                                  e.target.value,
                                  "cardName"
                                );
                              }}
                            />
                          </div>
                          <label className="d-flex align-items-center fs-6 fw-semibold form-label mb-2">
                            <span className="required">
                              Payment Information
                            </span>
                          </label>
                          <Payment
                            publishedKey={Key}
                            clientSecret={clientSecret}
                            handleSubmit={async (data) => {
                              if (!!data?.cardId && !!data?.cardToken && !loadingPayout) {

                                setTimeout(async () => {
                                  valuesCard.stripeCardId = data?.cardId;
                                  valuesCard.stripeCardToken = data?.cardToken;
                                  valuesCard.orderId = currentId;
                                  await apiCalll({
                                    method: "POST",
                                    link: `/Orders/MarkAsPaidByCard`,
                                    data: valuesCard,
                                    token: token,
                                  }).then((result) => {
                                    try {
                                      if (
                                        result?.length > 0 &&
                                        result[0] === 200
                                      ) {
                                        Swal.fire({
                                          position: "top-center",
                                          icon: "success",
                                          title: t("Successful payment"),
                                          showConfirmButton: false,
                                          timer: 1500,
                                        });
                                        setModalStripe(false);
                                        getData(currentId, token);
                                        setLoadingPayout(false);
                                      } else {
                                        ModelStateMessage(result);
                                        setLoadingPayout(false);
                                      }
                                    } catch (err) {
                                      setLoadingPayout(false);
                                    }
                                  });
                                }, 988);
                              } else {
                                setLoadingPayout(false);
                              }
                            }}
                            Billing={valuesCard}
                            setbilling={setValuesCard}
                            // sub={sub}
                            formik={formik2}
                          />
                        </Col>
                      </Row>
                    </div>
                  </Modal.Body>
                  <Modal.Footer className="d-flex justify-content-between">
                    <div>
                      <span className="font-weight-boldest">Powered by</span>
                      {data?.order?.processor === "stripe" ||
                      data?.order?.processor === "deliveryeaseStripe" ? (
                        <img
                          src={toAbsoluteUrl(
                            "/media/svg/icons/Tools/stripePowered.png"
                          )}
                          alt="stripePowered"
                          style={{ width: "34%" }}
                        />
                      ) : data?.order?.processor === "moneris" ? (
                        <img
                          src={toAbsoluteUrl(
                            "/media/svg/icons/Tools/moneris.png"
                          )}
                          alt="moneris"
                          style={{ width: "82px" }}
                        />
                      ) : (
                        ""
                      )}
                    </div>
                    <div>
                      <Button
                        variant="secondary"
                        className="mr-4"
                        onClick={() => {
                          setModalStripe(false);
                        }}
                      >
                        {t("Close")}
                      </Button>
                      <Button
                        variant="primary"
                        disabled={
                          !!valuesCard.cardName === false || loadingPayout
                        }
                        onClick={async () => {
                          setLoadingPayout(true);
                          document.getElementById("Strip_billing_pay").click();
                        }}
                      >
                        {t("Pay now")}
                        {loadingPayout && (
                          <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            className="ml-4"
                            role="status"
                            aria-hidden="true"
                          />
                        )}
                      </Button>
                    </div>
                  </Modal.Footer>
                </>
              </Modal>
            </div>
          </div>
        </div>
      </div>

      <DynamicModal
        size="xl"
        show={modalReasonSkip}
        setShow={setModalReasonSkip}
        child={
          <Card>
            <CardHeader title="Skip Payment" className="font-weight-boldest">
              <CardHeaderToolbar className="d-flex justify-centent-between">
                <div>
                  <span
                    style={{ cursor: "pointer" }}
                    onClick={() => setModalReasonSkip(false)}
                    className="float-right"
                  >
                    <i className="flaticon2-delete"></i>
                  </span>
                </div>
              </CardHeaderToolbar>
            </CardHeader>
            <CardBody>
              <Row>
                <Col>
                  <div className="form-group fv-plugins-icon-container">
                    <label>
                      {t("Reason")} <span className="text-danger">*</span>
                    </label>
                    <textarea
                      className="form-control"
                      rows={8}
                      value={reasonSkip}
                      onChange={(e) => {
                        setReasonSkip(e.target.value);
                      }}
                    ></textarea>
                  </div>
                </Col>
              </Row>
            </CardBody>
            <Modal.Footer>
              <Button
                variant="secondary"
                size="sm"
                onClick={(e) => {
                  e.preventDefault();
                  setModalReasonSkip(false);
                }}
              >
                Close
              </Button>
              <Button
                variant="outline-primary"
                className=""
                disabled={reasonSkip?.trim() === ""}
                size="sm"
                onClick={() => {
                  apiCalll({
                    method: "POST",
                    link: `/orders/MarkAsPaidByHand`,
                    token: token,
                    data: {
                      id: currentId,
                      type: "not paid",
                      reason: reasonSkip,
                    },
                  }).then(async (result) => {
                    try {
                      if (result.length > 0 && result[0] === 200) {
                        Swal.fire({
                          icon: "success",
                          title: "Success",
                          text: "Success",
                        });
                        getData(currentId, token);
                        setModalReasonSkip(false);
                      } else {
                        ModelStateMessage(result);
                      }
                    } catch (err) {}
                  });
                }}
              >
                Submit
              </Button>
            </Modal.Footer>
          </Card>
        }
      />
    </>
  );
}
