import React, { useEffect, useState } from "react";
import {
  GoogleMap,
  Marker,
  useLoadScript,
  DirectionsRenderer,
} from "@react-google-maps/api";
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";
import { HubConnectionBuilder } from "@microsoft/signalr";
import "./style.css";
import { useParams, useHistory } from "react-router-dom";
import moment from "moment";
import { ConvertToDateCurrentGMT } from "../../../../../_metronic/_helpers/GlobalHelpers";
import Countdown from "react-countdown";

const exampleMapStyles = [
  {
    elementType: "geometry",
    stylers: [
      {
        color: "#f5f5f5",
      },
    ],
  },
  {
    elementType: "labels.icon",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#616161",
      },
    ],
  },
  {
    elementType: "labels.text.stroke",
    stylers: [
      {
        color: "#f5f5f5",
      },
    ],
  },
  {
    featureType: "administrative.land_parcel",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#bdbdbd",
      },
    ],
  },
  {
    featureType: "poi",
    elementType: "geometry",
    stylers: [
      {
        color: "#eeeeee",
      },
    ],
  },
  {
    featureType: "poi",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#757575",
      },
    ],
  },
  {
    featureType: "poi.park",
    elementType: "geometry",
    stylers: [
      {
        color: "#e5e5e5",
      },
    ],
  },
  {
    featureType: "poi.park",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#ABE8C7",
      },
    ],
  },
  {
    featureType: "road",
    elementType: "geometry",
    stylers: [
      {
        color: "#ffffff",
      },
    ],
  },
  {
    featureType: "road.arterial",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#757575",
      },
    ],
  },
  {
    featureType: "road.highway",
    elementType: "geometry",
    stylers: [
      {
        color: "#dadada",
      },
    ],
  },
  {
    featureType: "road.highway",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#616161",
      },
    ],
  },
  {
    featureType: "road.local",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#9e9e9e",
      },
    ],
  },
  {
    featureType: "transit.line",
    elementType: "geometry",
    stylers: [
      {
        color: "#e5e5e5",
      },
    ],
  },
  {
    featureType: "transit.station",
    elementType: "geometry",
    stylers: [
      {
        color: "#eeeeee",
      },
    ],
  },
  {
    featureType: "water",
    elementType: "geometry",
    stylers: [
      {
        color: "#CFE2EB",
      },
    ],
  },
  {
    featureType: "water",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#CFE2EB",
      },
    ],
  },
];

const libraries = ["places"];

const LiveTracking = () => {
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const history = useHistory();
  const { id } = useParams();
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
    libraries,
  });
  const [connection, setConnection] = useState(null);
  const [data, setData] = useState([]);
  const [NewData, setNewData] = useState([]);
  const [directions, setDirections] = useState(null);
  const [currentPosition, setCurrentPosition] = useState(null);
  const [completed, setCompleted] = useState(false);

  const mapStyles = {
    height: "100%",
    width: "100%",
  };

  const defaultCenter = {
    lat: parseFloat(data?.fromLat) || parseFloat(data?.destLat) || 43.653225,
    lng: parseFloat(data?.fromLng) || parseFloat(data?.destLng) || -79.383186,
  };

  const mapOptions = {
    styles: exampleMapStyles,
    zoomControl: true,
    fullscreenControl: false,
    mapTypeControl: false,
    streetViewControl: false,
    rotateControl: false,
    scaleControl: false,
  };

  const getIcon = (type) => {
    if (type === "driver") {
      return {
        url: toAbsoluteUrl("/media/icons/car.svg"),
        scaledSize: new window.google.maps.Size(30, 30),
      };
    }
    if (type === "customer") {
      return {
        url: toAbsoluteUrl("/media/icons/finish.svg"),
        scaledSize: new window.google.maps.Size(30, 30),
      };
    }
    return null;
  };

  useEffect(() => {
    const newConnection = new HubConnectionBuilder()
      .withUrl(`${BASE_URL?.replace("/api", "")}/Hubs/livetracking`)
      .withAutomaticReconnect()
      .build();
    setConnection(newConnection);
  }, []);

  const handleGetDetails = (isFinished) => {
    fetch(`${BASE_URL}/LiveTracking/GetDetails/${id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      Allow: "PUT",
    })
      .then(async (response) => {
        const statusCode = await response.status;
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
          return response.json().then((data) => [statusCode, data]);
        } else {
          return response.text().then((data) => [statusCode, data]);
        }
      })
      .then((data) => {
        if (data[0] === 200) {
          window.$trackingData = data[1].data;
          setData(data[1].data);
          setCurrentPosition({
            lat: parseFloat(data[1].data.fromLat),
            lng: parseFloat(data[1].data.fromLng),
          });
          if (isFinished) {
            setDirections(null);
          }
        }
        else if (data[0] === 401) {
          history.push("/logout");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleSetConnectionId = () => {
    fetch(`${BASE_URL}/LiveTracking/SetConnectionId`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      Allow: "PUT",
      body: JSON.stringify({
        id: connection?.connectionId,
        code: id,
      }),
    })
      .then(async (response) => {
        const statusCode = await response.status;
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
          return response.json().then((data) => [statusCode, data]);
        } else {
          return response.text().then((data) => [statusCode, data]);
        }
      })
      .then((data) => {
        if (data[0] === 200) {
          console.log("Connection Id set successfully");
        }
        else if (data[0] === 401) {
          history.push("/logout");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const calculateRoute = () => {
    if (!data?.fromLat || !data?.fromLng || !data?.destLat || !data?.destLng)
      return;

    const directionsService = new window.google.maps.DirectionsService();
    directionsService.route(
      {
        origin: {
          lat: parseFloat(data.fromLat),
          lng: parseFloat(data.fromLng),
        },
        destination: {
          lat: parseFloat(data.destLat),
          lng: parseFloat(data.destLng),
        },
        travelMode: window.google.maps.TravelMode.DRIVING,
      },
      (result, status) => {
        if (status === window.google.maps.DirectionsStatus.OK) {
          setDirections(result);
        } else {
          console.error(`error fetching directions ${result}`);
        }
      }
    );
  };

  useEffect(() => {
    handleGetDetails(false);
  }, []);

  useEffect(() => {
    if (connection) {
      connection.start().then(() => {
        handleSetConnectionId();
      });
      connection.on("ReceiveMessage", (message) => {
        if (message.action === "Tracking") {
          setNewData(message);
        }
        if (message.action === "Finished") {
          handleGetDetails(true);
        }
      });
      connection.onreconnected(() => {
        handleSetConnectionId();
      });
    }
  }, [connection]);

  const calculateNewRoute = () => {
    if (!data?.fromLat || !data?.fromLng || !data?.destLat || !data?.destLng) return;
  
    const directionsService = new window.google.maps.DirectionsService();
    directionsService.route(
      {
        origin: {
          lat: parseFloat(NewData.lat),
          lng: parseFloat(NewData.lng),
        },
        destination: {
          lat: parseFloat(data.destLat),
          lng: parseFloat(data.destLng),
        },
        travelMode: window.google.maps.TravelMode.DRIVING,
        optimizeWaypoints: true, // this option will optimize the route
      },
      (result, status) => {
        if (status === window.google.maps.DirectionsStatus.OK) {
          setDirections(result);
        } else {
          console.error("Error fetching directions", result);
        }
      }
    );
  };

  useEffect(() => {
    if (NewData.lat && NewData.lng) {
      animateMarker(NewData.lat, NewData.lng);
      calculateNewRoute();
    }
  }, [NewData]);

  useEffect(() => {
    if (data?.fromLat && data?.fromLng && data?.destLat && data?.destLng) {
      calculateRoute();
    }
  }, [data]);

  const animateMarker = (newLat, newLng) => {
    const startLat = currentPosition.lat;
    const startLng = currentPosition.lng;
    const endLat = parseFloat(newLat);
    const endLng = parseFloat(newLng);
    const animationDuration = 7000;
    const frameRate = 60;
    const totalFrames = (animationDuration / 1000) * frameRate;

    let frame = 0;

    const animate = () => {
      frame++;
      const progress = frame / totalFrames;

      const lat = startLat + (endLat - startLat) * progress;
      const lng = startLng + (endLng - startLng) * progress;

      setCurrentPosition({ lat, lng });

      if (frame < totalFrames) {
        requestAnimationFrame(animate);
      } else {
        setCurrentPosition({ lat: endLat, lng: endLng });
      }
    };

    requestAnimationFrame(animate);
  };

  const fullRenderer = ({ days, hours, minutes, seconds }) => {
    return (
      <span>
        {hours > 0 && hours + " h"} {minutes} minutes.
      </span>
    );
  };

  if (loadError) {
    return (
      <div>
        Error loading maps. Please check your API key and network connection.
      </div>
    );
  }

  if (!isLoaded) return <div>Loading Maps...</div>;

  return (
    <div className="mapContainer">
      {isLoaded && (
        <GoogleMap
          mapContainerStyle={mapStyles}
          zoom={12}
          center={defaultCenter}
          options={mapOptions}
        >
          {directions && (
            <DirectionsRenderer
              directions={directions}
              options={{
                suppressMarkers: true,
                polylineOptions: {
                  strokeColor: "#1976D2",
                  strokeWeight: 5,
                },
              }}
            />
          )}
          {!data?.isDelivered && (
            <Marker
              key={"driver"}
              position={currentPosition}
              title={"Driver"}
              icon={getIcon("driver")}
            />
          )}
          <Marker
            key={"customer"}
            position={{
              lat: parseFloat(data?.destLat),
              lng: parseFloat(data?.destLng),
            }}
            title={
              data?.destAddress1 +
              ", " +
              data?.destCity +
              ", " +
              data?.destState +
              " " +
              data?.destZipCode
            }
            icon={getIcon("customer")}
          />
        </GoogleMap>
      )}
      {data?.name && (
        <div className="topCard card card-custom mb-5">
          <div className="d-flex align-items-center p-4">
            <div className="symbol symbol-40  mr-5">
              <span className="symbol-label bg-transparent">
                <img
                  src={toAbsoluteUrl("/media/icons/car.svg")}
                  className="h-75 align-self-end"
                />
              </span>
            </div>
            <div className="d-flex flex-column flex-grow-1 font-weight-bold">
              {!data?.isDelivered && (
                <span className="text-dark font-weight-bolder mb-1 font-size-lg">
                  {data?.name}
                </span>
              )}
              {!data?.isDelivered && (
                <span className="text-muted font-weight-bolder">
                  Your delivery is on the way!
                </span>
              )}
              {!data?.isDelivered && data?.eta && !completed && (
                <span className="text-dark  font-weight-bolder">
                  ETA:{" "}
                  {data?.eta && (
                    <Countdown
                      date={ConvertToDateCurrentGMT(data?.eta)}
                      renderer={fullRenderer}
                      onComplete={() => setCompleted(true)}
                    />
                  )}
                </span>
              )}
              {data?.isDelivered && (
                <span className="text-success font-weight-bolder mt-1">
                  Delivered{" "}
                  <i className="ml-2 text-success far fa-check-circle mt-3"></i>
                </span>
              )}
              {data?.isDelivered && (
                <span className="text-muted font-weight-bolder">
                  {moment(ConvertToDateCurrentGMT(data?.actualDeliveryDate)).format("MMMM DD, YYYY hh:mm A")}
                </span>
              )}
            </div>
          </div>
        </div>
      )}
      {data?.driverFirstName && (
        <div className="bottomCard card card-custom">
          <div className="font-weight-bold text-dark-50">
            <div className="d-flex align-items-center">
              <div className="symbol symbol-40 mr-3">
                <span className="symbol-label bg-transparent">
                  <img
                    src={toAbsoluteUrl("/media/icons/car.svg")}
                    className="h-75 align-self-end"
                  />
                </span>
              </div>
              <div className="d-flex flex-column flex-grow-1 font-weight-bold">
                <span className="text-dark font-weight-bolder text-hover-primary font-size-lg mt-3">
                  {data?.driverFirstName} {data?.driverLastName}
                </span>
              </div>
            </div>
          </div>
          <div className="separator separator-dashed mt-5 mb-8"></div>
          <div className="timeline timeline-6 mb-5">
            <div className="timeline-item align-items-start mb-10">
              <div className="timeline-label font-weight-bold text-dark-75 font-size-lg p-0 m-0" />
              <div className="timeline-badge">
                <i className="fas fa-map-marker-alt text-primary icon-xl"></i>
              </div>
              <div className="timeline-content font-weight-bold text-dark-75 pl-3 font-size-lg">
                {data?.fromAddress1},{" "}
                {data?.fromAddress2 && data?.fromAddress2 + ","}{" "}
                {data?.fromCity}, {data.fromState?.slice(0, 2)}{" "}
                {data?.fromZipCode}, {data?.fromCountry}
              </div>
            </div>
            <div className="timeline-item align-items-start">
              <div className="timeline-label font-weight-bold text-dark-75 font-size-lg p-0 m-0" />
              <div className="timeline-badge">
                <i className="fas fa-flag-checkered text-primary icon-xl"></i>
              </div>
              <div className="timeline-content font-weight-bold text-dark-75 pl-3 font-size-lg">
                {data?.destAddress1},{" "}
                {data?.destAddress2 && data?.destAddress2 + ","}{" "}
                {data?.destCity}, {data.destState?.slice(0, 2)}{" "}
                {data?.destZipCode}, {data?.destCountry}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default LiveTracking;
