import moment from "moment";
import React, { useEffect, useRef } from "react";
import { useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import { useReactToPrint } from "react-to-print";
import { useHistory, useParams } from "react-router-dom";
import SVG from "react-inlinesvg";
import * as columnFormatters from "../customers-table/column-formatters/StatusColumnFormatter";
import {
  sortCaret,
  headerSortingClasses,
  PleaseWaitMessage,
  NoRecordsFoundMessage,
  toAbsoluteUrl,
  getSelectRow,
} from "../../../../../../_metronic/_helpers";
import { Pagination } from "../../../../../../_metronic/_partials/controls";
import { Button, Dropdown, Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import {
  ConvertToDateCurrentTimeZoneOnlyDate,
  Currency,
  CustomCheckbox,
  ExportAs,
  getFromatdate,
  Number,
} from "../../../../../../_metronic/_helpers/GlobalHelpers";
import "react-google-flight-datepicker/dist/main.css";
import { RangeDatePicker } from "react-google-flight-datepicker";
import Invoice from "./Invoice";
import PayInvoice from "./PayInvoice";
import { ModelStateMessage, apiCalll } from "../../../../../../_metronic/_helpers/apiHelpers";
import Swal from "sweetalert2";
import html2pdf from 'html2pdf.js';
import CreditMemos from "./CreditMemos";


const PharmacyOrdersInvoices = (props) => {
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const token = JSON.parse(localStorage.getItem("user")).token;
  const role = JSON.parse(localStorage.getItem("user")).roles[0];
  const [search, setSearch] = useState("");
  const [pagination, setPagination] = useState([]);
  const [orders, setOrders] = useState([]);
  const [invoices, setInvoices] = useState([]);
  const params = useParams();
  const id = params.id;
  const history = useHistory();
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [show, setShow] = useState(false);
  const [showBtnPay, setShowBtnPay] = useState(false);
  const [showExtra, setShowExtra] = useState(false);
  const [disablePay, setDisablePay] = useState(false);
  const [extra, setExtra] = useState(0);
  const [adjustmentDescription, setAdjustmentDescription] = useState("");
  const [showPay, setShowPay] = useState(false);
  const [isPaying, setIsPaying] = useState(false);
  const [showCard, setShowAddCard] = useState(false);
  const [hidden, setHidden] = useState(false);
  const [paySelected, setPaySelected] = useState(false);
  const [hiddenCard, setHiddenCard] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [downloadIsLoading, setDownloadIsLoading] = useState(false);
  const [invoiceNumber, setInvoiceNumber] = useState("");
  const [totalSelectedCreditMemo, setTotalSelectedCreditMemo] = useState(0);
  const [selectedCreditMemo, setSelectedCreditMemo] = useState([]);
  const [creditMemoCodeNumbers, setCreditMemoNumber] = useState([]);
  const [showCreditMemo, setShowCreditMemo] = useState(false);
const [showAdjust, setShowAdjust] = useState(true);

  const componentRef = useRef();
  const [ids, setIds] = useState([]);
  const [initFilter, setinitFilter] = useState({
    page: 1,
    sizePerPage: localStorage.getItem("orderPharmacyPageSize") ? parseInt(localStorage.getItem("orderPharmacyPageSize")) : 10,
    sortField: "Id",
    sortOrder: "desc",
    totalSize: 10,
    sizePerPageList: [
      { text: "10", value: 10 },
      { text: "20", value: 20 },
      { text: "50", value: 50 },
      { text: "100", value: 100 },
    ],
  });
  useEffect(() => {
    try{
      var resultFilter = JSON.parse(localStorage.getItem("filterInvoicingBilling"));
       if(!!resultFilter?.startDate && resultFilter?.startDate !== "Invalid date") setStartDate(resultFilter?.startDate);
       if(!!resultFilter?.endDate && resultFilter?.endDate !== "Invalid date") setEndDate(resultFilter?.endDate);
    }catch(err) {}
  },[]);
  useEffect(() => {
    if(!show){
      setExtra(0)
      setAdjustmentDescription("")
      setSelectedCreditMemo([])
      setCreditMemoNumber([])
      setTotalSelectedCreditMemo(0)
      setInvoiceNumber("")
      setShowAdjust(true)
    }
  }, [show])

  const hanldeIcon = (type) => {
    if (type.toLocaleLowerCase() === "card") {
      return <SVG src={toAbsoluteUrl("/media/svg/icons/code/visa.svg")} />;
    } else if (type.toLocaleLowerCase() === "cash") {
      return <SVG src={toAbsoluteUrl("/media/svg/icons/code/cash2.svg")} />;
    } else if (type.toLocaleLowerCase() === "cheque") {
      return <SVG src={toAbsoluteUrl("/media/svg/icons/code/cheque.svg")} />;
    } else {
      return (
        <SVG
          src={toAbsoluteUrl("/media/svg/icons/code/Verified-success.svg")}
        />
      );
    }
  };
  const columns = [
    {
      dataField: "code",
      text: "Order#",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      // redirect to order details page on click
      formatter: (cellContent, row) => (
        <p
          className="text-info  font-weight-bolder mb-0 font-size-lg"
          style={{ cursor: "pointer" }}
          onClick={() => {
            history.push(`/order/${row.id}/details`);
          }}
        >
          #{row.code}
        </p>
      ),
    },
    {
      dataField: "firstName",
      text: "Customer",
      sort: true,
      sortCaret: sortCaret,
      formatter: (cellContent, row) => (
        <>
          <>
            <p
              className="text-info  font-weight-bolder mb-0 font-size-lg"
              style={{ cursor: "pointer" }}
              onClick={() => {
                history.push(`/customer/${row.patientId}/details`);
              }}
            >
              {row.firstName} {row.lastName}
            </p>
          </>
        </>
      ),
      headerSortingClasses,
    },
    {
      dataField: "deliveryCity",
      text: "Delivery",
      sort: false,
      sortCaret: sortCaret,
      formatter: (cellContent, row) => (
        <OverlayTrigger
          key={"right"}
          placement={"right"}
          overlay={
            <Tooltip id={`tooltip-${"placement"}`}>
              {row.deliveryAddress1}, {row.deliveryAddress2}, {row.deliveryCity}
              , {row.deliveryState}, {row.deliveryZipCode}
            </Tooltip>
          }
        >
          <span
            style={{ cursor: "pointer" }}
            className="svg-icon menu-icon svg-icon-info"
          >
            <SVG src={toAbsoluteUrl("/media/svg/icons/Map/Marker2.svg")} />
          </span>
        </OverlayTrigger>
      ),
      headerSortingClasses,
    },
    {
      dataField: "pickupAppointmentDate",
      text: "Pickup Date",
      headerFormatter: (cellContent, row) => (
        <>
          Pickup Date
          <OverlayTrigger
            key={"right"}
            placement={"right"}
            overlay={
              <Tooltip id={`tooltip-${"placement"}`}>{getFromatdate()}</Tooltip>
            }
          >
            <span
              style={{ cursor: "pointer" }}
              className="svg-icon menu-icon svg-icon-info"
            >
              <SVG
                src={toAbsoluteUrl("/media/svg/icons/Code/Info-circle.svg")}
              />
            </span>
          </OverlayTrigger>
        </>
      ),
      sort: true,
      sortCaret: sortCaret,
      formatter: (cellContent, row) => (
        // format date to dd/mm/yyyy
        <p>
          {!!row.pickupAppointmentDate
            ? ConvertToDateCurrentTimeZoneOnlyDate(row.pickupAppointmentDate)
            : "-"}
        </p>
      ),
      headerSortingClasses,
    },
    {
      dataField: "deliveryAppointmentDate",
      text: "Delivery Date",
      headerFormatter: (cellContent, row) => (
        <>
          Delivery Date
          <OverlayTrigger
            key={"right"}
            placement={"right"}
            overlay={
              <Tooltip id={`tooltip-${"placement"}`}>{getFromatdate()}</Tooltip>
            }
          >
            <span
              style={{ cursor: "pointer" }}
              className="svg-icon menu-icon svg-icon-info"
            >
              <SVG
                src={toAbsoluteUrl("/media/svg/icons/Code/Info-circle.svg")}
              />
            </span>
          </OverlayTrigger>
        </>
      ),
      sort: true,
      sortCaret: sortCaret,
      formatter: (cellContent, row) => (
        // format date to dd/mm/yyyy
        <p>
          {row.deliveryAppointmentDate
            ? ConvertToDateCurrentTimeZoneOnlyDate(row.deliveryAppointmentDate)
            : ""}
        </p>
      ),
      headerSortingClasses,
    },
    {
      dataField: "distance",
      text: "Distance",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      formatter: (cellContent, row) => (
        <>{row.distance && row.unit ? Number(row.distance) + " " + row.unit : ""}</>
      ),
    },
    {
      dataField: "totalCharge",
      text: "Charge ($)",
      sort: true,
      sortCaret: sortCaret,
      formatter: (cellContent, row) => <>{row.totalCharge ? Currency(row.totalCharge) : "$0.00"}</>,
      headerSortingClasses,
    },
    {
      dataField: "cod",
      text:"COD ($)",
      sort: true,
      sortCaret: sortCaret,
      formatter: (cellContent, row) => (
        <>
          <div className="d-flex ">
            <div className="mt-1">
              <p>${row.cod ? row.cod?.toFixed(2) : 0.0}</p>
            </div>
            {row?.paymentStatus === 3 ? (
              <div
                className="ml-2 cursor-pointer d-flex justify-content-center cursor-pointer"
              >
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip id="metronic-features">
                      {row?.paymentError}
                    </Tooltip>
                  }
                >
                  <img
                    src="/media/icons/credit-card (1).png"
                    alt=""
                    className=" h-25px cursor-pointer"
                  />
                </OverlayTrigger>
              </div>
            ) : row?.paymentStatus === 1 ? (
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip id="metronic-features">
                    Payment made successfully {row?.paymentMethod && "by "}
                    {row?.paymentMethod?.toLocaleLowerCase()}
                  </Tooltip>
                }
              >
                <span className="svg-icon svg-icon-lg svg-icon-white ml-3 cursor-pointer">
                  {hanldeIcon(row?.paymentMethod || "")}
                </span>
              </OverlayTrigger>
            ) : row?.paymentStatus === 2 ? (
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip id="metronic-features">Payment on delivery</Tooltip>
                }
              >
                <span className="svg-icon svg-icon-lg svg-icon-success ml-3">
                  <img
                    src="/media/icons/pending (2).png"
                    alt=""
                    className=" h-25px cursor-pointer"
                  />
                </span>
              </OverlayTrigger>
            ) : (
              ""
            )}
          </div>
        </>
      ),
      headerSortingClasses,
    },
    {
      dataField: "isHot",
      text: "Hot",
      sort: true,
      sortCaret: sortCaret,
      formatter: (cellContent, row) =>
        row.isHot === true ? (
          <span className="svg-icon svg-icon-lg svg-icon-warning ml-1">
            <SVG src={toAbsoluteUrl("/media/svg/icons/General/Fire.svg")} />
          </span>
        ) : (
          ""
        ),
      headerSortingClasses,
    },
    {
      dataField: "status",
      text: "Status",
      sort: true,
      sortCaret: sortCaret,
      formatter: columnFormatters.StatusColumnFormatter,
      headerSortingClasses,
    },
  ];

  useEffect(() => {
    setinitFilter({
      page: 1,
      sizePerPage: localStorage.getItem("orderPharmacyPageSize") ? parseInt(localStorage.getItem("orderPharmacyPageSize")) : 10,
      sortField: "Id",
      sortOrder: "desc",
      custom: true,
      totalSize: 100,
      sizePerPageList: [
        { text: "10", value: 10 },
        { text: "20", value: 20 },
        { text: "50", value: 50 },
        { text: "100", value: 100 },
      ],
    });
  }, []);

  const GetHandlerTableChange = (type, newState) => {
    let values = { ...initFilter };
    if (type === "sort") {
      values.sortField = newState.sortField;
      values.sortOrder = newState.sortOrder;
    }
    if (type === "pagination") {
      values.sizePerPage = newState.sizePerPage;
      values.page = newState.page === undefined ? 1 : newState.page;
    }
    setinitFilter(values);
  };

  function getTimeZoneOffset() {
    const offsetInMinutes = new Date().getTimezoneOffset();

    const hours = Math.floor(Math.abs(offsetInMinutes) / 60);
    const sign = offsetInMinutes < 0 ? "+" : "-";

    return `${sign}${hours.toString().padStart(2, "")}`;
  }

  const firstLetterUppercase = (word) => {
    return word.charAt(0).toUpperCase() + word.slice(1);
  };

  const handleGetOrders = (filter) => {
    filter.pageSize = localStorage.getItem("orderPharmacyPageSize") ? parseInt(localStorage.getItem("orderPharmacyPageSize")) : 10
    fetch(
      `${BASE_URL}/Pharmacies/GetOrdersInvoice?pharmacyId=${id}&pageNumber=${
        filter?.page
      }&pageSize=${filter?.sizePerPage}&search=${search ||
        ""}&startDate=${startDate || ""}&endDate=${endDate ||
        ""}&timezone=${getTimeZoneOffset()}&sortOrder=${filter?.sortOrder}&sortField=${firstLetterUppercase(
          filter?.sortField
        )}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        Allow: "GET",
      }
    )
      .then(async (response) => {
        const statusCode = await response.status;
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
          return response.json().then((data) => [statusCode, data]);
        } else {
          return response.text().then((data) => [statusCode, data]);
        }
      })
      .then((data) => {
        if (data[0] === 200) {
          setOrders(data[1]?.orders);
          setPagination(data[1]?.pagination);
        }
        else if (data[0] === 401) {
          history.push("/logout");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const handleGetTotalInvoiceByDate = () => {
    setIsLoading(true);
    setDisablePay(false);
    fetch(
      `${BASE_URL}/Pharmacies/GetTotalInvoiceByDate?pharmacyId=${id}&Extra=${extra || 0}&search=${search ||
        ""}&startDate=${startDate || ""}&endDate=${endDate ||
        ""}&timezone=${getTimeZoneOffset()}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        Allow: "GET",
      }
    )
      .then(async (response) => {
        const statusCode = await response.status;
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
          return response.json().then((data) => [statusCode, data]);
        } else {
          return response.text().then((data) => [statusCode, data]);
        }
      })
      .then((data) => {
        if (data[0] === 200) {
          setShow(true);
          setInvoices(data[1]);
        }
        else if (data[0] === 401) {
          history.push("/logout");
        }
        else {
          ModelStateMessage(data);
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  const GetTotalInvoice = () => {
    setIsLoading(true);
    setDisablePay(false);
    fetch(
      `${BASE_URL}/Pharmacies/GetTotalInvoice?pharmacyId=${id}&Extra=${extra || 0}&ids=${ids.join(
        "&ids="
      )}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        Allow: "GET",
      }
    )
      .then(async (response) => {
        const statusCode = await response.status;
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
          return response.json().then((data) => [statusCode, data]);
        } else {
          return response.text().then((data) => [statusCode, data]);
        }
      })
      .then((data) => {
        if (data[0] === 200) {
          setShow(true);
          setInvoices(data[1]);
        }
        else if (data[0] === 401) {
          history.push("/logout");
        }
        else {
          ModelStateMessage(data);
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleClearCodByIds = () => {
    fetch(
      `${BASE_URL}/Pharmacies/ClearCodById?pharmacyId=${id}&Extra=${extra || 0}&ids=${ids.join(
        "&ids="
      )}`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        Allow: "PUT",
      }
    )
      .then(async (response) => {
        const statusCode = await response.status;
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
          return response.json().then((data) => [statusCode, data]);
        } else {
          return response.text().then((data) => [statusCode, data]);
        }
      })
      .then((data) => {
        if (data[0] === 200) {
          GetTotalInvoice();
        }
        else if (data[0] === 401) {
          history.push("/logout");
        }
        else {
          ModelStateMessage(data);
        }
      })
      .catch((error) => {
        console.log(error);
      })
  };
  const handleClearCod = () => {
    fetch(`${BASE_URL}/Pharmacies/ClearCod?pharmacyId=${id}&search=${search ||
      ""}&startDate=${startDate || ""}&endDate=${endDate ||
      ""}&timezone=${getTimeZoneOffset()}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      },
      Allow: "PUT",
    })
      .then(async (response) => {
        const statusCode = await response.status;
        const contentType = response.headers.get("content-type");
        if (
          contentType && contentType.indexOf("application/json") !== -1
        ) {
          return response.json().then((data) => [statusCode, data]);
        } else {
          return response.text().then((data) => [statusCode, data]);
        }
      })
      .then(data => {
        if (data[0] === 200) {
         handleGetTotalInvoiceByDate();
        }
        else if (data[0] === 400 && data[1].message) {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: data[1].message,
          })
        }
        else if (data[0] === 400) {
          let tt = JSON.parse(data[1]);
          for (const [key, value] of Object.entries(tt.errors)) {
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: value,
            })
          }
        }
        else if (data[0] === 401) {
          history.push("/logout");
        }
        else if(data[0] === 402){
          Swal.fire({
            title: "Info!",
            text: "You have to subscribe first",
            icon: "info",
            confirmButtonText: "Ok",
          }).then((result) => {
            if (result.isConfirmed) {
              history.push("/user-profile");
            }
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  useEffect(() => {
    const debounceSearch = setTimeout(() => {
      handleGetOrders(initFilter);
    }, 500);
    return () => {
      clearTimeout(debounceSearch);
    };
  }, [initFilter, search, startDate, endDate]);

  const paginationOptions = {
    custom: true,
    totalSize: pagination?.totalItemCount || 0,
    sizePerPageList: initFilter?.sizePerPageList || [],
    sizePerPage: pagination?.pageSize || 10,
    page: pagination?.pageNumber || 1,
  };

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  function Export(Type) {
    apiCalll({
      method: "GET",
      link: `/Pharmacies/GetOrdersInvoice?pharmacyId=${id}&pageNumber=1&pageSize=100000000&timezone=${getTimeZoneOffset()}`,
    })
      .then(async (result) => {
        if (result[0] === 200) {
          var listOrders=[];
          var data = result[1];
          (data?.orders).map((k)=>{
            var a = JSON.stringify(k);
            a = a.replaceAll('"patientId":', '"customerID":');
            a = JSON.parse(a);
            listOrders.push(a)
          })
          ExportAs(listOrders || [], Type, "Invoices");
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  }

    
  const downloadBillAsPDF = async () => {
    const element = componentRef.current;

    const timestamp = new Date().getTime();

    const filename = props?.invoices?.billNumber
        ? `invoice-${props.invoices.billNumber}.pdf`
        : `invoice-${timestamp}.pdf`;

    const options = {
        margin: 0,
        filename,
        image: { type: 'jpeg', quality: 0.98 },
        html2canvas: { scale: 1, logging: false },
        jsPDF: {
            unit: 'in',
            format: 'a3',
            orientation: 'portrait',
        },
    };

    try {
        await html2pdf().set(options).from(element).save();
    } finally {
        setDownloadIsLoading(false);
    }
};

function handleSubmitCreditMemo() {
  setIsPaying(true);
  const data = {};
  if (paySelected === true) {
    data.ids = ids;
  }
  data.extra = extra || 0
  data.adjustmentDescription = adjustmentDescription
  data.creditMemoIds = selectedCreditMemo;
  
  return apiCalll({
    method: "Post",
    link:
      paySelected === true
        ? `/Pharmacies/CreditMemoSelected/${id}`
        : `/Pharmacies/CreditMemoByDate?pharmacyId=${id}&startDate=${startDate ||
            ""}&endDate=${endDate || ""}&timezone=${getTimeZoneOffset()}`,
    data: data,
  })
    .then((res) => {
      if (res[0] === 200) {
        document.getElementById("refresh_invoices").click();
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "The payment has been paid successfully!",
        });
        if(!data.adjustmentDescription)
        {
          setShowAdjust(false)
        }
        setShowPay(false);
        setHidden(false);
        setShowBtnPay(true);
        setShowExtra(false)
        setInvoiceNumber(res[1]?.invoiceNumber)
      } else if (res[0] === 402) {
        Swal.fire({
          title: "Info!",
          text: "You have to subscribe first",
          icon: "info",
          confirmButtonText: "Ok",
        }).then((result) => {
          if (result.isConfirmed) {
            history.push("/user-profile");
          }
        });
      } else {
        ModelStateMessage(res);
      }
    })
    .catch((error) => {
      console.error(error);
    })
    .finally(() => {
      setIsPaying(false);
    });
}

const handleRowSelect = (row, isSelect) => {
  if (isSelect) {
    setIds((prevIds) => [...prevIds, row.id]);
  } else {
    setIds((prevIds) => prevIds.filter((id) => id !== row.id));
  }
};

const handleSelectAll = (isSelect, rows) => {
  if (isSelect) {
    setIds(rows.map((row) => row.id));
  } else {
    setIds([]);
  }
};

const selectRow = {
  mode: 'checkbox',
  clickToSelect: true,
  selected: ids,
  onSelect: handleRowSelect,
  onSelectAll: handleSelectAll,
  selectionRenderer: (props) => <CustomCheckbox {...props} />,
  selectionHeaderRenderer: (props) => <CustomCheckbox {...props} />,
};

  return (
    <>
      <div className="row mb-7">
        <div className="col-xl-4 col-md-4 mt-3">
          <input
            type="text"
            className="form-control"
            name="search"
            placeholder="Search"
            onChange={(e) => {
              setSearch(e.target.value);
            }}
          />
          <small className="form-text text-muted">
            <b>Search</b> in all fields
          </small>
        </div>
        <div className="col-xl-6 col-md-6 mt-3">
          <RangeDatePicker
            startDate={
              startDate !== null && moment(startDate).format("YYYY-MM-DD")
            }
            endDate={endDate !== null && moment(endDate).format("YYYY-MM-DD")}
            onChange={(startDate, endDate) => {
              startDate === null
                ? setStartDate(null)
                : setStartDate(moment(startDate).format("YYYY-MM-DD"));

              endDate === null
                ? setEndDate(null)
                : setEndDate(moment(endDate).format("YYYY-MM-DD"));
            }}
            dateFormat="MM/DD/YYYY"
            minDate={new Date(1900, 0, 1)}
            maxDate={new Date(2100, 0, 1)}
            startDatePlaceholder="Start Date"
            endDatePlaceholder="End Date"
            disabled={false}
            startWeekDay="monday"
          />
          <small className="form-text text-muted">
            <b>Filter</b> by Delivery Date
          </small>
        </div>
        <div className="col-md-2">
        <Dropdown>
                <Dropdown.Toggle
                  className=" mb-xl0 mb-lg-0 mb-md-0 mb-3 mt-3"
                  variant="success"
                  id="dropdown-basic"
                >
                  <span className="svg-icon svg-icon-md svg-icon-white">
                    <SVG
                      src={toAbsoluteUrl("/media/svg/icons/Files/Export.svg")}
                    />
                  </span>
                  Export as 
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item
                    onClick={() => {
                      Export("excel");
                    }}
                    href={"#"}
                  >
                    <i className="fas fa-file-excel mr-3"></i> Excel
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => {
                      Export("csv");
                    }}
                    href="#"
                  >
                    <i className="fas fa-file-csv mr-3"></i> Csv
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => {
                      Export("json");
                    }}
                    href="#"
                  >
                    <i className="fas fa-file mr-3"></i> Json
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
          </div>
        <div className="col-xl-4 col-md-4 mt-3" >
          {ids.length === 0 && (
            <button
              type="button"
              className="btn btn-success"
              disabled={isLoading}
              onClick={() => {
                handleGetTotalInvoiceByDate();
                setShowBtnPay(false);
                setShowExtra(true);
                setPaySelected(false);
              }}
            >
              {
                isLoading ? "Generating Invoice..." : "Generate Invoice"
              }
            </button>
          )}
          {ids.length > 0 && (
            <button
              type="button"
              className="btn btn-success"
              onClick={() => {
                GetTotalInvoice();
                setShowBtnPay(false);
                setShowExtra(true);
                setPaySelected(true);
              }}
            >
              {
                isLoading ? "Generating Invoice..." : "Generate Invoice for selected orders"
              }
            </button>
          )}
          <button
            hidden={true}
            id="refresh_invoices"
            type="button"
            className="btn btn-success"
            onClick={() => {
              handleGetOrders(initFilter);
            }}
          >
            Reresh Invoices
          </button>
        </div>
      </div>
      <PaginationProvider pagination={paginationFactory(paginationOptions)}>
        {({ paginationProps, paginationTableProps }) => {
          return (
            <Pagination paginationProps={paginationProps} table="orderPharmacyPageSize">
              <BootstrapTable
                wrapperClasses="table-responsive"
                classes="table table-head-custom table-vertical-center overflow-hidden"
                bootstrap4
                bordered={false}
                remote
                keyField="id"
                data={orders || []}
                columns={columns}
                defaultSorted={[{ dataField: "Created", order: "desc" }]}
                onTableChange={GetHandlerTableChange}
                selectRow={selectRow}
                {...paginationTableProps}
              >
                <PleaseWaitMessage entities={orders || []} />
                <NoRecordsFoundMessage entities={orders || []} />
              </BootstrapTable>
            </Pagination>
          );
        }}
      </PaginationProvider>

      <Modal
        size="xl"
        show={show}
        onHide={() => {
          setShow(false);
        }}
        style={{ display: hidden ? "none" : "block" }}
      >
        <Modal.Header closeButton className="border-0"></Modal.Header>
        <Modal.Body>
          <Invoice
            componentRef={componentRef}
            invoices={invoices}
            totalSelectedCreditMemo={totalSelectedCreditMemo}
            isLoading={isLoading}
            name={props.name}
            startDate={startDate}
            endDate={endDate}
            extra={extra}
            setExtra={setExtra}
            showExtra={showExtra}
            adjustmentDescription={adjustmentDescription}
            setAdjustmentDescription={setAdjustmentDescription}
            GetTotalInvoice={GetTotalInvoice}
            handleGetTotalInvoiceByDate={handleGetTotalInvoiceByDate}
            ids={ids}
            setDisablePay={setDisablePay}
            disablePay={disablePay}
            invoiceNumber={invoiceNumber}
            handleClearCodByIds={handleClearCodByIds}
            handleClearCod={handleClearCod}
            showBtnPay={showBtnPay}
            creditMemoCodeNumbers={creditMemoCodeNumbers}
            showAdjust={showAdjust}
          />
        </Modal.Body>
        <Modal.Footer className="border-0">
          <Button
            variant="secondary"
            disabled={isPaying}
            onClick={() => {
              setShow(false);
            }}
          >
            Close
          </Button>
          <Button
            variant="light-warning"
            className="me-5"
            hidden={showBtnPay}
            disabled={isPaying}
            onClick={() => {
              setShowCreditMemo(true);
              setHidden(true);
            }}
          >
            Credit Memos
          </Button>
          <div className="my-1 me-5">
            <button
              hidden={showBtnPay}
              disabled={disablePay || isPaying}
              type="button"
              className="btn btn-light-info my-1 me-12"
              onClick={() => {
                if(extra !== 0 && extra !== null  && adjustmentDescription === "")
                {
                  Swal.fire({
                    icon: "info",
                    title: "Oops...",
                    text: "Please enter the adjustment description",
                  });
                  return;
                }
                if(totalSelectedCreditMemo !== 0 && (invoices?.totalWithTax + totalSelectedCreditMemo) < 0)
                {
                  Swal.fire({
                    title: "Are you sure?",
                    text: "You want to mark this invoice as credit memo?",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonText: "Yes, Mark it!",
                    cancelButtonText: "No, cancel!",
                    reverseButtons: true,
                  }).then((result) => {
                    if (result.isConfirmed) {
                      handleSubmitCreditMemo();
                      setIds([]);
                    }
                  });
                  return;
                }
                if (invoices?.totalWithTax > 0) {
                  setHidden(true);
                  setShowPay(true);
                }else{
                  Swal.fire({
                    title: "Are you sure?",
                    text: "You want to mark this invoice as credit memo?",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonText: "Yes, Mark it!",
                    cancelButtonText: "No, cancel!",
                    reverseButtons: true,
                  }).then((result) => {
                    if (result.isConfirmed) {
                      handleSubmitCreditMemo();
                      setIds([]);
                    }
                  });

                }
              }}
            >
             {isPaying ? "Please wait..." : "Pay Invoice"}
            </button>
          </div>
          <div className="my-1 me-5">
            <button
              type="button"
              className="btn btn-light-primary my-1 me-12"
              onClick={handlePrint}
              disabled={isPaying}
            >
              Print Invoice
            </button>
          </div>
          <div className="my-1 me-5">
            <button
              type="button"
              disabled={downloadIsLoading || isPaying}
              className="btn btn-light-success my-1 me-12"
              onClick={()=> {
                setDownloadIsLoading(true);
                downloadBillAsPDF()
              }}
            >
              {downloadIsLoading ? 'Downloading...' : 'Download PDF'}
            </button>
          </div>
        </Modal.Footer>
      </Modal>

      <Modal
        size="lg"
        show={showPay}
        onHide={() => {
          setHidden(false);
          setShowPay(false);
        }}
        style={{ display: hiddenCard ? "none" : "block" }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Pay Invoice</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <PayInvoice
            setShowAddCard={setShowAddCard}
            showCard={showCard}
            setHiddenCard={setHiddenCard}
            setShowPay={setShowPay}
            setShow={setShow}
            setHidden={setHidden}
            startDate={startDate}
            endDate={endDate}
            id={id}
            setIsPaying={setIsPaying}
            isPaying={isPaying}
            setShowBtnPay={setShowBtnPay}
            amount={invoices?.totalWithTax + (totalSelectedCreditMemo || 0)}
            paySelected={paySelected}
            ids={ids}
            setIds={setIds}
            extra={extra}
            adjustmentDescription={adjustmentDescription}
            setShowExtra={setShowExtra}
            setInvoiceNumber={setInvoiceNumber}
            selectedCreditMemo={selectedCreditMemo}
            downloadBillAsPDF={downloadBillAsPDF}
            setShowAdjust={setShowAdjust}
          />
        </Modal.Body>
        <Modal.Footer className="border-0">
          <Button
            disabled={isPaying}
            variant="secondary"
            onClick={() => {
              setHidden(false);
              setShowPay(false);
            }}
          >
            Cancel
          </Button>
          <div className="my-1 me-5">
            <button
              disabled={isPaying}
              type="button"
              className="btn btn-primary my-1 me-12"
              onClick={() => {
                document.getElementById("proceed_to_payment").click();
              }}
            >
              {isPaying ? (
                <div className="d-flex align-items-center">
                  Procceding to payment...
                  <span className="spinner-border spinner-border-sm ml-3"></span>
                </div>
              ) : (
                "Proceed to payment"
              )}
            </button>
          </div>
          <div className="my-1 me-5"></div>
        </Modal.Footer>
      </Modal>
      <CreditMemos
        showCreditMemo={showCreditMemo}
        setShowCreditMemo={setShowCreditMemo}
        selectedCreditMemo={selectedCreditMemo}
        setSelectedCreditMemo={setSelectedCreditMemo}
        setCreditMemoNumber={setCreditMemoNumber}
        creditMemoCodeNumbers={creditMemoCodeNumbers}
        totalSelectedCreditMemo={totalSelectedCreditMemo}
        setTotalSelectedCreditMemo={setTotalSelectedCreditMemo}
        setHidden={setHidden}
        startDate={startDate}
        endDate={endDate}
      />
    </>
  );
};

export default PharmacyOrdersInvoices;
