
import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  Badge,
  Button,
  Col,
  Modal,
  OverlayTrigger,
  Row,
  Spinner,
  Tooltip
} from "react-bootstrap";
import "react-csv-importer/dist/index.css";
import { useDropzone } from "react-dropzone";
import SVG from "react-inlinesvg";
import ReactSelect, { default as Select } from "react-select";
import Swal from "sweetalert2";
import * as XLSX from "xlsx";
import {
  RemoveSpace,
  truncateString
} from "../../../_metronic/_helpers/GlobalHelpers";
import {
  ModelStateMessage,
  ModelStateMessage400,
  apiCalll
} from "../../../_metronic/_helpers/apiHelpers";
// import { GetApiChange } from "./ContactCustomField";
import Papa from "papaparse";
import BootstrapTable from "react-bootstrap-table-next";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import {
  NoRecordsFoundMessage,
  PleaseWaitMessage,
  headerSortingClasses,
  sortCaret,
  toAbsoluteUrl
} from "../../../_metronic/_helpers";
import moment from "moment";
import { t } from "i18next";
import ReactPaginate from 'react-paginate'

function escapeRegExp(str) {
  return str.replace(/[.*+\-?^${}()|[\]\\]/g, "\\$&");
}

function checkString(str, phrase) {
  const escapedPhrase = escapeRegExp(phrase);
  if (str.match(new RegExp(escapedPhrase.split(/\W/).join("[\\W]*"), "i"))) {
    return true;
  }
  return false;
}
var jsnObj = [];
var errorListGl = [];
var CustomFieldsGL = [];
var CustomFieldsListGL = [];
var ColumnJsonGL = [];
var FieldsHeaderGL = [];
const ImportGlobal = (props) => {
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const token = JSON.parse(localStorage.getItem("user")).token;
  var [inputFile, setInputFile] = useState(null);
  var [type, setType] = useState("xlsx");
  const [ColumnJson, setColumnJson] = useState([]);
  const [InfoJsonResult, setInfoJsonResult] = useState([]);
  const [fieldsMapped, setFieldsMapped] = useState([]);
  const [LoadingPostImport, setLoadingPostImport] = useState(false);
  const [CustomFieldsList, setCustomFieldsList] = useState([]);
  const [CustomFields, setCustomFields] = useState([]);
  const [Name, setName] = useState("");
  const [errorList, seterrorList] = useState([]);
  const [MaxLines, setMaxLines] = useState(0);
  const [MinLines, setMinLines] = useState(0);
  const [fields, setFields] = useState([]);
  const [pharmacyAdmins, setPharmacyAdmins] = useState([]);
  const [pharmacyAdminId, setPharmacyAdminId] = useState("");

  //list arrays
  function transformData(arr, index) {
    // Check if the array has at least two elements
    if (arr.length >= 2) {
      // Destructure the first two elements of the array
      const [id] = arr;
      // Return an object with id and name
      return { id: index + 1, name: id };
    }
    // Return null for arrays with less than two elements
    return null;
  }
  const [dataFields, setDataFields] = useState([]);
  const [resultMapping, setResultMapping] = useState([]);
  const [loaderMappedFields, setLoaderMappFields] = useState(false);
  const history = useHistory();
  function isBoolean(item) {
    if (typeof item === 'boolean') {
        return true;
    }

    if (typeof item === 'string') {
        var lowerCaseItem = item.toLowerCase();
        return lowerCaseItem === 'true' || lowerCaseItem === 'false';
    }

    return false;
  }
  
  function booleanConvert(item) {
    if (typeof item === "string") {
      var lowerCaseItem = item.toLowerCase();
      return lowerCaseItem === "true" ? true : false;
    }
    if (typeof item === "boolean") {
      return item;
    }
    return item;
  }

  async function getFieldsMappedResult(batchSize = 100) {
    return new Promise((resolve, reject) => {
      try {
        // const cfFieldsCompany = new Set(
        //   (dataFields?.customFieldsCompany ?? []).map((y) => getItemString(y.label))
        // );
        // const cfFieldsContacts = new Set(
        //   (dataFields?.customFieldsContacts ?? []).map((y) => getItemString(y.label))
        // );
        // const cfFieldsMapped = fieldsMapped.filter((x) => {
        //   const itemName = getItemString(x.name) || getItemString(x.label);
        //   return cfFieldsCompany.has(itemName) || cfFieldsContacts.has(itemName);
        // });
  
        const fieldMapping = {};
        for (const field of [
          ...fields,
        ]) {
          const label = getItemString(field.label ?? field.name);
          fieldMapping[label] = field;
        }
  
        const resultMapping = [];
        var dataMapped = InfoJsonResult;
  
        const processBatch = (batch) => {
          return batch.map((item) => {
            const currentObject = {};
            for (const key in item) {
              const currentField = fieldsMapped.find((x) => {
                const keyField = getItemString(x.keyField);
                return getItemString(keyField) === getItemString(key);
              });
  
              if (!!currentField && !!key) {
                currentObject[currentField.name] =
                  item[currentField.keyField] === null ||
                  item[currentField.keyField] === "" ||
                  item[currentField.keyField] === undefined
                    ? null
                    : isBoolean(item[currentField.keyField])
                    ? booleanConvert(item[currentField.keyField])
                    : item[currentField.keyField]?.toString();
              }
            }
  
            return currentObject;
          });
        };
  
        const totalBatches = Math.ceil(dataMapped.length / batchSize);
  
        const processNextBatch = async (batchIndex) => {
          const startIndex = batchIndex * batchSize;
          const endIndex = Math.min(startIndex + batchSize, dataMapped.length);
          const batch = dataMapped.slice(startIndex, endIndex);
  
          resultMapping.push(...processBatch(batch));
  
          if (batchIndex < totalBatches - 1) {
            // Delay between batches to allow other tasks to run
            await new Promise((resolve) => setTimeout(resolve, 100));
  
            processNextBatch(batchIndex + 1);
          } else {
            resolve(resultMapping);
          }
        };
  
        processNextBatch(0);
      } catch (error) {
        reject(error);
      }
    });
  }
  
  useEffect(() => {
    console.log("fieldsMapped", fieldsMapped);
  },[fieldsMapped]);

  const setupColumnsMapped = useCallback(() => {
    var arrayColumns = Object.entries(
      InfoJsonResult.length > 0 ? InfoJsonResult[0] : []
    ).map((a, index) => {
      return {
        id: index + 1,
        field: String(a[0]),
        visible: true,
        label: String(a[0]),
        name: String(a[0]),
        cellRenderer: CustomFiled,
      };
    });
    if (arrayColumns.length > 0) {
      var result = [];
      arrayColumns.forEach((item) => {
        var currentItem = fieldsMapped?.find((y) => y?.id === item?.id);
        var field1 = item.label
          .toString()
          .replaceAll(" ", "")
          .replaceAll("*", "")
          ?.toLowerCase();
        var field2 = fields.find(
          (s) =>
            (!!s?.name ? s?.name : s?.label)
              ?.toString()
              ?.replaceAll(" ", "")
              ?.replaceAll("*", "")?.toLowerCase() === field1
        );
        var currentId = !!field2?.viewModelId
          ? field2?.viewModelId
          : field2?.id?.toString();
        if (
          !!field2 &&
          field1.includes(
            (!!field2?.name ? field2?.name : field2?.label)
              .toString()
              .replaceAll(" ", "")
              .replaceAll("*", "")?.toLowerCase()
          )
        ) {
          field2.viewModelId = currentId;
          field2.field = item.label + (field2?.required ? "*" : "");
          field2.keyField = item.label.trim();
          field2.id = item?.id;
          console.log(
            "🚀 ~ file: ImportContacts.js:207 ~ arrayColumns.forEach ~ field2:",
            field2
          );
          result.push(field2);
        }
      });
      setFieldsMapped(result);
    }
  }, [InfoJsonResult, fieldsMapped, fields]);
  useEffect(() => {
    var arrayColumns = Object.entries(
      InfoJsonResult.length > 0 ? InfoJsonResult[0] : []
    ).map((a, index) => {
      return {
        id: index + 1,
        field: String(a[0]),
        visible: true,
        label: String(a[0]),
        // cellRenderer: CustomFiled,
      };
    });
    if (arrayColumns.length > 0) {
      var result = [];
      arrayColumns.forEach((item) => {
        var field1 = item.label
          .toString()
          .replaceAll(" ", "")
          .replaceAll("*", "")
          .toLowerCase();
        var field2 = fields.find(
          (s) =>
            (!!s?.name ? s?.name : s?.label)
              ?.toString()
              ?.replaceAll(" ", "")
              ?.replaceAll("*", "")
              ?.toLowerCase() === field1
        );
        var currentId = !!field2?.viewModelId
          ? field2?.viewModelId
          : field2?.id?.toString();

        if (
          !!field2 &&
          field1.includes(
            (!!field2?.name ? field2?.name : field2?.label)
              .toString()
              .replaceAll(" ", "")
              .replaceAll("*", "")
              .toLowerCase()
          )
        ) {
          field2.viewModelId = currentId;
          field2.field = item.label + (field2?.required ? "*" : "");
          field2.keyField = item.label.trim();
          field2.id = item?.id;
          result.push(field2);
        }
      });

      setFieldsMapped(result);
    }
  }, [InfoJsonResult, fields]);
  useEffect(() => {
    if (!props?.show) {
      setFiles([]);
      setStep(1);
    }
  }, [props]);
  useEffect(() => {
    jsnObj = [...InfoJsonResult];
  }, [InfoJsonResult]);
  useEffect(() => {
    errorListGl = [...errorList];
  }, [errorList]);

  useEffect(() => {
    CustomFieldsListGL = CustomFieldsList;
  }, [CustomFieldsList]);

  useEffect(() => {
    CustomFieldsGL = CustomFields;
  }, [CustomFields]);

  /* useEffect(() => {
    FieldsHeaderGL = FieldsHeader;
  }, [FieldsHeader]); */

  const baseStyle = {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "100px",
    borderWidth: 2,
    borderRadius: 2,
    borderColor: "#0058B1",
    borderStyle: "dashed",
    backgroundColor: "#F5F8FA",
    color: "#464E5F",
    outline: "none",
    transition: "border .24s ease-in-out",
  };

  const activeStyle = {
    borderColor: "#2196f3",
  };
  const acceptStyle = {
    borderColor: "#00e676",
  };
  const rejectStyle = {
    borderColor: "#ff1744",
  };

  var jsonExample = {
    id: "1",
    "First name": "string",
    "Last name": "string",
    "Job title": "string",
    "Company name": "string",
    Source: "string",
    "Email domain": "string",
    Industry: "string",
    "Job level": "string",
    "Sic code": "string",
    "Naics code": "string",
    "Seo description": "string",
    "Annual revenue": "string",
    Visibility: "string",
    "Account Based": "string",
    "Email*": "string",
    "Phone number": "string",
  };

  var CsvHeaderExample = {
    "First name": "string",
    "Last name": "string",
    "Email*": "string",
    "Phone number": "string",
    "Job title": "string",
    "Job level": "string",
    "Account Based": "string",
    "Company name": "string",
    Industry: "string",
    Source: "string",
    "Email domain": "string",
    Visibility: "string",
  };
  //on drop functions
  const processCSVData = (rowObject) => {
    try {
      const lo = rowObject.map((entry) => {
        const modified = {};
        if (Array.isArray(entry)) {
          entry.forEach((value, index) => {
            let key = value || "";
            key = key.replace("(iso)", "");
            modified[index] = String(key);
          });
          return modified;
        } else {
          return entry;
        }
        
      });

      const jsonObjectWithId = lo.map((element) => {
        const keys = Object.keys(element);
      
        // Use keys as needed
        keys.forEach((key,value) => {
          const dateString = String(element[key]);
          const dateObject = new Date(dateString);
          const jsDateValue = new Date((dateString - 2) * 24 * 60 * 60 * 1000 + Date.UTC(1900, 0, 1));
          if (!!dateObject && !isNaN(dateObject.getTime()) && 
                !isNaN(jsDateValue.getTime()) && 
                (key?.toString()?.toLowerCase()?.includes("date") || 
                key?.toString()?.toLowerCase() ==="recurringto" || 
                key?.toString()?.toLowerCase() === "recurringfrom" )) {
            element[key] = moment(jsDateValue)?.format("MM/DD/YYYY")?.toString();
          }
        });
      
        return element;
      });

      const result = jsonObjectWithId.map((s) => {
        const mappedObject = {};
        Object.entries(s).forEach(([key, value]) => {
          const fieldName = jsonObjectWithId[0][key];
          mappedObject[fieldName] = value?.toString() ?? "";
        });

        return mappedObject;
      });
      console.log("result",result)
      setInfoJsonResult(
        rowObject.length > 0 && Array.isArray(rowObject[0])
          ? result
          : jsonObjectWithId
      );
      // setColumnJson(resultColumns ?? []);

      setCustomFields(
        !!jsonObjectWithId && jsonObjectWithId.length > 0
          ? Object.keys(jsonObjectWithId[0])
              .filter((a) => CheckIsCustomField(a))
              .map((a) => {
                const index = Object.keys(jsonObjectWithId[0]).findIndex(
                  (x) => x === a
                );
                return {
                  Id: null,
                  Label: a,
                  Required: false,
                  value: null,
                  index: index,
                };
              })
          : []
      );

      CustomFieldsListGL.forEach((x) => {
        const a = CustomFieldsGL.find(
          (y) =>
            RemoveSpace(y.Label).includes(RemoveSpace(x.Label)) ||
            RemoveSpace(x.Label).includes(RemoveSpace(y.Label))
        );
        if (!!a) SetCustomFieldFunction(a.Label, x);
      });
      setLoadingTable(false);
    } catch (err) {
      console.log("err", err);
    }
  };

  const parseCSVData = (data) => {
    const CHUNK_SIZE = 1024 * 1024; // 1MB chunk size
    let currentPosition = 0;
    let csvData = "";

    const processChunk = () => {
      const chunk = data.slice(currentPosition, currentPosition + CHUNK_SIZE);
      csvData += chunk;
      currentPosition += CHUNK_SIZE;

      if (currentPosition < data.length) {
        setTimeout(processChunk, 0); // Process the next chunk
      } else {
        Papa.parse(csvData, {
          complete: function(parsedData) {
            const rowObject = parsedData.data;
            processCSVData(rowObject.slice(0, rowObject.length));
            setLoadingTable(false);
          },
        });
      }
    };

    processChunk();
  };
  const [loadingTable, setLoadingTable] = useState(false);
  const onDrop = useCallback((acceptedFiles) => {
    try {
      var selectedFile = acceptedFiles[0];
      setInputFile(acceptedFiles[0]);
      if (selectedFile) {
        setCustomFields([...[]]);
        seterrorList([...[]]);
        setInfoJsonResult([...[]]);
        setColumnJson([...[]]);
        setFieldsMapped([...[]]);
        setResultMapping([...[]]);
        var fileReader = new FileReader();
        fileReader.onload = async function(event) {
          var data = event.target.result;
          setLoadingTable(true);
          setCustomFields([...[]]);
          seterrorList([...[]]);
          setInfoJsonResult([...[]]);
          setColumnJson([...[]]);
          setFieldsMapped([...[]]);
          setResultMapping([...[]]);

          var type = selectedFile.path.split(".").pop();

          if (type === "xlsx") {
            const workbook = XLSX.read(data, {
              type: "binary",
            });
            const sheet = workbook.Sheets[workbook.SheetNames[0]];
            const rowObject = XLSX.utils.sheet_to_json(sheet, { defval: null });
            processCSVData(rowObject);
            setType("xlsx");
          } else if (type === "csv") {
            setType("csv");
            parseCSVData(data);
          } else {
            Swal.fire({
              icon: "error",
              title: "Error",
              text: `You can only import CSV files.`,
            });
            return false;
          }
        };
        setLoadingTable(false);

        fileReader.readAsBinaryString(selectedFile);
      }
      setLoadingTable(false);
    } catch (err) {
      console.log("err", err);
    }
  }, []);
  function getArrayWithIndex(array) {
    return array.map((a, index) => {
      return { ...a, index: index };
    });
  }

  const GetViewModel = useCallback(() => {
    setFields(props?.fields);
  },[props]);

  useEffect(() => {
    setColumnJson([]);
    setInfoJsonResult([]);
    setCustomFields([]);
    seterrorList([]);
    setName("");
    setInputFile(null);
  }, [props.handleClose]);

  useEffect(() => {
    if (!!props.show) GetViewModel();
  }, [GetViewModel, props]);

  const CustomFiled = (label, value) => {
    return (
      <>
        {/* {CheckisRequredApi(
          props?.label ?? "-",
          row,
          cellContent,
          errorList,
          setInfoJsonResult
        ) && (
          <>
            <i className="fas fa-exclamation-circle text-danger"></i>{" "}
            <span className="text-danger mx-2">Required</span>
          </>
        )} */}
        {truncateString(value ?? "-", 90)?.toString()}
      </>
    );
  };

  function CheckIsCustomField(field) {
    var a = Object.keys(jsonExample).find((x) => x === field);
    return !a;
  }

  const { isDragActive, isDragAccept, isDragReject } = useDropzone({
    accept: "image/*",
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept]
  );
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    onDrop,
  });
  useEffect(() => {
    setFiles(
        acceptedFiles?.map((file) => (
          <li className="list-group-item" key={file.path}>
            <span className="svg-icon svg-icon-sm svg-icon-primary ml-1">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Files/File-cloud.svg")} />
            </span>
            <strong>{file.path}</strong> - {file.size} bytes
          </li>
        ))
      );
  },[acceptedFiles]);
  const [files, setFiles] = useState(
    acceptedFiles?.map((file) => (
      <li className="list-group-item" key={file.path}>
        <span className="svg-icon svg-icon-sm svg-icon-primary ml-1">
          <SVG src={toAbsoluteUrl("/media/svg/icons/Files/File-cloud.svg")} />
        </span>
        <strong>{file.path}</strong> - {file.size} bytes
      </li>
    ))
  );

  function SetCustomFieldFunction(label, item) {
    CustomFieldsGL.forEach((element) => {
      if (element.Id === item.Id) {
        element.Id = null;
        element.Required = false;
      }
    });
    var find = CustomFieldsGL.find((y) => y.Label === label);
    find.Id = item.Id;
    find.Required = item.Required;
    setCustomFields([...CustomFieldsGL]);
    ColumnJsonGL = Object.entries(
      InfoJsonResult.length > 0 ? InfoJsonResult[0] : []
    ).map((a, index) => {
      return {
        id: index + 1,
        field: String(a[0]),
        visible: true,
        label: String(a[0]),
        // cellRenderer: CustomFiled,
      };
    });
    // change Name in Table -------------------------------
    CustomFieldsGL.filter((x) => !x.Id).forEach((element) => {
      var a = ColumnJsonGL.find(
        (x) => element.Label.replace(/([A-Z])/g, " $1").trim() === x.labelOrgn
      );
      if (!!a) a.label = a.labelOrgn;
    });
    if (item.Required) {
      var findHeader = ColumnJsonGL.find((x) => x.field.includes(label));
      findHeader.labelOrgn = findHeader.label;
      findHeader.label = findHeader.label + "*";
    }
    setColumnJson([...[]]);
    setColumnJson([...ColumnJsonGL]);
    // change Name in Table -------------------------------
  }

  function ShowErrorsRow(index, Name) {
    var a = errorListGl.filter(
      (x) =>
        x.key.includes(`${Name}[${index - 1}]`) ||
        (+x.key > -1 && x.value.includes(`${Name}[${index - 1}]`))
    );
    return a.map((x) => x.value);
  }
  const [step, setStep] = useState(1);

  const getViewModel = () => {
    fetch(`${BASE_URL}/SuperAdmin/GetStoresViewModel`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    })
      .then(async (response) => {
        const statusCode = await response.status;
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
          return response.json().then((data) => [statusCode, data]);
        } else {
          return response.text().then((data) => [statusCode, data]);
        }
      })
      .then((data) => {
        if (data[0] === 200) {
          const pharmacyAdmins = data[1]?.pharmacyAdmins.map((el) => {
            return {
              value: el.id,
              label: el.name,
              useSso: el.useSso,
            };
          });
          setPharmacyAdmins(pharmacyAdmins);
        } else if (data[0] === 401) {
          history.push("/logout");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  async function confirmImport() {
    try {
      setLoadingPostImport(true);
      ColumnJsonGL = Object.entries(
        InfoJsonResult.length > 0 ? InfoJsonResult[0] : []
      ).map((a, index) => {
        return {
          id: index + 1,
          field: String(a[0]),
          visible: true,
          label: String(a[0]),
        //   cellRenderer: CustomFiled,
        };
      });
      let row = {};
      let optionsApi = {
        method: "POST",
        link: props?.link,
        data: {},
      };
      var resultMapped = await getFieldsMappedResult(100)
      .then((result) => result)
      .catch((error) => {
        // Handle the rejected error
        console.error(error);
      });
      row[props?.columnNameApi] = type === "csv" ? resultMapped.slice(1, resultMapped.length - 1) : resultMapped;
      optionsApi.data =  row;
      if (pharmacyAdminId) {
        optionsApi.data.pharmacyAdminId = pharmacyAdminId;
      }

      await apiCalll(optionsApi)
        .then(async (result) => {
          console.log("🚀 ~ .then ~ result:", result)

          if (result.length > 0 && result[0] === 200) {
            props.handleClose();

            Swal.fire({
              icon: "success",
              title: "Success",
              html:
                "<b>Import operation has been completed successfully</b><br/>" +
                "<hr class='mb-2 mt-2'/>" +
                (Array.isArray(result[1]) && result[1]?.length > 0
                  ? "<b>Errors: </b>" + result[1]?.length + "<br/> "
                  : "") +
                (Array.isArray(result[1]) && result[1]?.length > 0
                  ? "<hr class='mb-1 mt-1'/>"
                  : "") +
                (Array.isArray(result[1]) && result[1]?.length > 0
                  ? "<ul class='scroll-list'>" +
                    result[1]
                      ?.map(
                        (s, i) =>
                          `<li class='text-danger'>${
                            s
                          }</li>`
                      )
                      .join("<br/>") +
                    "</ul>"
                  : ""),
            });

            props.refreshData();
          } else if (result[0] === 402) {
            Swal.fire({
              title: "Info!",
              text: t("You have to subscribe first!"),
              icon: "info",
              confirmButtonText: "Ok",
            }).then((result) => {
              if (result.isConfirmed) {
                history.push("/user-profile");
              }
            });
          }  else if (result[0] === 400) {
            ModelStateMessage400(result);
          }

          setLoadingPostImport(false);
        })
        .catch((error) => {
          console.log("error", error);
          setLoadingPostImport(false);
        });
    } catch (err) {
      setLoadingPostImport(false);
      console.log(
        "🚀 ~ file: ImportContacts.js:800 ~ confirmImport ~ err:",
        err
      );
    }
  }

  //options grouped
  const getArrayMapped = useCallback(
    (array) => {
        console.log("🚀 ~ file: ImportGlobal.js:863 ~ ImportGlobal ~ fieldsMapped:", fieldsMapped)
    
      return (array ?? [])
        .filter((x) => {
          return (
            !!fieldsMapped.find(
              (y) => getItemString(y?.name) === getItemString(x?.name)
            ) === false
          );
        })
        .map((x, i) => {
          return {
            value: i + 1,
            label: x?.name,
            index: i,
          };
        });
    },
    [fieldsMapped]
  );
  const options = useMemo(
    () => [
      {
        label: "Fields",
        options: getArrayMapped(
          props?.fields ?? []
        ),
      },
    ],
    [getArrayMapped, props.fields]
  );
  const OptionGroup = ({ children, ...props }) => (
    <div {...props} autoComplete={'nope'}>
      <strong>{props.label}</strong>
      {children}
    </div>
  );

  function getItemString(value) {
    return value
      ?.toString()
      ?.replaceAll(" ", "")
      ?.replaceAll("*", "")
      ?.trim()
      ?.toLowerCase();
  }

  const customStyles = {
    // add custom styles for the label
    option: (provided, state) => ({
      ...provided,
      color: state.data.label.includes("*") ? "red" : "black",
    }),
  };
  const [maximizeModal, setMaximizeModal] = useState("");

  //content columns mapped
  const columnsMappedContent = useMemo(() => {
    console.log("dddd", Object.entries(
        InfoJsonResult.length > 0 ? InfoJsonResult[0] : []
      ));
    return Object.entries(InfoJsonResult.length > 0 ? InfoJsonResult[0] : [])
      .map((a, index) => {
        return {
          id: index + 1,
          field: String(a[0]),
          visible: true,
          label: String(a[0]),
        //   cellRenderer: CustomFiled,
        };
      })
      .map((item, index) => {
        var currentItem = fieldsMapped?.find((y) => y?.id === item?.id);
        return (
          <div className="row mb-3" key={index}>
            <Col lg="4">
              <label className="mb-1 font-size-sm">{item.label}</label>
            </Col>
            <Col lg="4">
              <Select
                className="w-100"
                options={options}
                styles={customStyles}
                id={index + 1}
                name={`fieldsMapped${index + 1}`}
                classNamePrefix="react-select"
                isClearable
                components={{ OptionGroup }}
                placeholder={"Select Field..."}
                size="sm"
                value={
                  !!currentItem && (!!currentItem?.name || !!currentItem?.label)
                    ? {
                        value: !!currentItem?.name
                          ? currentItem?.name
                          : currentItem?.label,
                        label: `${
                          !!currentItem?.name
                            ? currentItem?.name
                            : currentItem?.label
                        }`,
                      }
                    : null
                }
                onChange={async (e) => {
                  try {
                    var field2 = fields.find(
                      (s) =>
                        (!!s.name ? s?.name : s?.label)
                          ?.toString()
                          ?.replaceAll(" ", "")
                          ?.replaceAll("*", "")
                          ?.toLowerCase() ===
                          e?.label
                            ?.toString()
                            .replaceAll(" ", "")
                            ?.replaceAll("*", "")
                            .toLowerCase() && currentItem?.id === item?.id
                    );
                    if (!!currentItem) {
                      setFieldsMapped((x) => {
                        x = x.filter((y) => y?.id !== currentItem?.id);
                        if (!!field2) {
                          x.push({
                            viewModelId: e?.value?.toString(),
                            id: item.id,
                            name: e?.label,
                            field: `${e?.label}`,
                            required: field2?.repuired ?? field2?.required,
                            index: index,
                            keyField: item.label,
                          });
                        }
                        return [...x];
                      });
                    } else {
                      var fieldItem = fields.find(
                        (s) =>
                          (!!s.name ? s?.name : s?.label)
                            ?.toString()
                            ?.replaceAll(" ", "")
                            ?.replaceAll("*", "")
                            ?.toLowerCase() ===
                          e?.label
                            ?.toString()
                            .replaceAll(" ", "")
                            ?.replaceAll("*", "")
                            .toLowerCase()
                      );
                      setFieldsMapped([
                        ...fieldsMapped,
                        {
                          viewModelId: e?.value?.toString(),
                          id: item.id,
                          name: e?.label,
                          field: e?.label,
                          required: fieldItem?.repuired ?? fieldItem?.required,
                          index: index,
                          keyField: item.label,
                        },
                      ]);
                    }
                  } catch (error) {
                    console.log(error);
                  }
                }}
              />
            </Col>
            <Col
              lg="4"
              className="align-self-center"
              style={{ overflowWrap: "anywhere" }}
            >
              <label>
                {InfoJsonResult.length > 0
                  ? `${
                      InfoJsonResult[0][item?.field] !== null && InfoJsonResult[0][item?.field] !== undefined
                        ? type === "csv"
                          ? truncateString(InfoJsonResult.slice(1, InfoJsonResult.length)[0][item?.field], 90)
                          : truncateString(InfoJsonResult[0][item?.field], 90)
                        : "-"
                    }`
                  : `-`}
              </label>
            </Col>
          </div>
        );
      });
  }, [InfoJsonResult, customStyles, fields, fieldsMapped, options, type]);
 const [DataList, setDataList] = useState(InfoJsonResult);
 
   useEffect(()=>{
     if(InfoJsonResult){
       setDataList(InfoJsonResult)
     }
   },[InfoJsonResult])
 
  const [pageSize, setPageSize] = useState(10);
  const [currentPageData, setCurrentPageData] = useState([]);
  const [pageNumber, setPageNumber] = useState(0);
  useEffect(() => {
    console.log(pageNumber, pageSize)
    const startIndex = pageNumber * pageSize;
    const endIndex = startIndex + pageSize;
    setCurrentPageData(DataList?.slice(startIndex, endIndex));
  }, [DataList, pageNumber, pageSize]);
  useEffect(() => {
    if(props?.titleModal === "Pharmacies"){
     getViewModel();
     }
  }, []);

  const handlePageClick = ({ selected }) => {
    setPageNumber(selected);
  };
  const isStoresURL = window.location.pathname.includes('/stores');

  const handlePageSizeChange = (e) => {
    const newSize = parseInt(e.target.value, 10);
    const currentItemIndex = pageNumber * pageSize;
    const newPageNumber = Math.floor(currentItemIndex / newSize);
    console.log(newPageNumber)
    const limitedPageSize = Math.min(newSize, DataList.length);
    console.log(limitedPageSize)
    setPageSize(limitedPageSize);
    setPageNumber(0); 
  };
  return (
    <Modal
      size="lg"
      show={props.show}
      onHide={props.handleClose}
      className={maximizeModal}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {step === 1
            ? `Import ${props?.titleModal} ${
                InfoJsonResult.length > 0 ? "/ File rows " : ""
              }`
            : `Column mapping ${
                InfoJsonResult.length > 0 ? "/ File rows " : ""
              }`}
          {InfoJsonResult?.length > 0 && (
            <span className="ml-2 mr-2">
              <Badge variant="primary" style={{ fontSize: "1rem" }}>
                {InfoJsonResult?.length}
              </Badge>
            </span>
          )}
          <button
            className="btn btn-sm btn-outline-primary mr-2 px-2 ml-2"
            onClick={(e) => {
              e.preventDefault();
              setMaximizeModal(maximizeModal === "" ? "modalFullScreenV3" : "");
            }}
          >
            <i
              style={{ fontSize: "1rem" }}
              className={
                maximizeModal !== ""
                  ? "flaticon2-size px-1"
                  : "flaticon2-arrow-1 px-1"
              }
            ></i>{" "}
          </button>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {step === 1 ? (
          <Fragment>
            <div className="col-xl-12 col-lg-12 col-md-12">
              {props?.titleModal === "Pharmacies" && <div className="mb-5">
                    <h4>
                     Corporate Admin
                    </h4>
                    <ReactSelect
                      className="basic-single"
                      classNamePrefix="select"
                      isSearchable={true}
                      isClearable={true}
                      name="pharmacyAdmins"
                      options={pharmacyAdmins}
                      value={pharmacyAdmins.find(
                        (obj) =>
                          String(obj.value)
                            .toLocaleLowerCase()
                            .trim() ===
                          String(pharmacyAdminId)
                            .toLocaleLowerCase()
                            .trim()
                      )}
                      onChange={(e) => {
                       if(e){
                        setPharmacyAdminId(e.value);
                       }else{
                        setPharmacyAdminId("");
                       }
                      }}
                    />
                </div>}
              <h4>Upload {props?.titleModal}</h4>
              <p>
                Use the 'Browse...' button to select a {props?.titleModal}{" "}
                import file.
                <a
                  href={props?.TemplateLink ?? ""}
                  download
                  rel="noopener noreferrer"
                  target="_blank"
                  className="pl-2"
                >
                  Download Template
                </a>
              </p>
              <div className="">
                <div {...getRootProps({ style })}>
                  <input {...getInputProps()} />
                  <SVG
                    className="h-75 mb-3"
                    src={toAbsoluteUrl("/media/svg/custom/Upload.svg")}
                  ></SVG>
                  <div className="uppy-DragDrop-label text-dark font-weight-bolder">
                    Drop files here or{" "}
                    <span className="uppy-DragDrop-browse text-primary cursor-pointer">
                      browse
                    </span>
                  </div>
                </div>
                {!!files && files.length > 0 && (
                  <aside>
                    <h4 className="pt-3">Files</h4>
                    <ul className="list-group list-group-flush">{files}</ul>
                  </aside>
                )}
              </div>
            </div>
            <div className="form-group">
              <br />
              {loadingTable ? (
                <Fragment>
                  <div className="d-flex justify-content-center">
                    <span className="text-muted font-size-lg">
                      Importing...
                    </span>
                  </div>
                </Fragment>
              ) : InfoJsonResult.length > 0 && files.length !== 0 && DataList.length > 0 ? (
                <>
                 <BootstrapTable
                  wrapperClasses="table-responsive"
                  bordered={false}
                  classes="table table-head-custom table-vertical-center overflow-hidden"
                  bootstrap4
                  remote
                  keyField="id"
                  data={
                    (type === "csv"
                      ? currentPageData.slice(1, currentPageData.length - 1)
                      : currentPageData) ?? []
                  }
                  columns={[
                    {
                      dataField: "my-errors",
                      text: "...",
                      sort: true,
                      sortCaret: sortCaret,
                      headerSortingClasses,
                      formatter: (cellContent, row) => {
                        var list = ShowErrorsRow(cellContent, "Drivers");
                        return (
                          <>
                            {list.length > 0 && (
                              <OverlayTrigger
                                placement="top"
                                overlay={
                                  <Tooltip id="tooltip-top">
                                    <span className="font-weight-bold">
                                      {list.map((item, index) => (
                                        <>
                                          {Array.isArray(item)
                                            ? item.map((x) => (
                                                <>
                                                  {x}
                                                  <br />
                                                </>
                                              ))
                                            : item}
                                        </>
                                      ))}
                                    </span>
                                  </Tooltip>
                                }
                              >
                                <span className="svg-icon svg-icon-md svg-icon-primary m-auto">
                                  <i className="fas fa-exclamation-triangle text-danger mx-5 px-5"></i>
                                </span>
                              </OverlayTrigger>
                            )}
                          </>
                        );
                      },
                    },
                    ...Object.entries(
                      InfoJsonResult.length > 0 ? InfoJsonResult[0] : []
                    ).map((a, index) => {
                      return {
                        dataField: String(a[0]),
                        text: String(a[0]),
                        sort: true,
                        sortCaret: sortCaret,
                        headerSortingClasses,
                        id: index + 1,
                        formatter: (cellContent, row) => {
                          console.log(
                            "🚀 ~ file: ImportGlobal.js:1205 ~ ).map ~ row:",
                            row
                          );
                          return CustomFiled(String(a[0]), row[String(a[0])]);
                        },
                      };
                    }),
                  ]}
                  defaultSorted={"Id"}
                  
                >
                  <PleaseWaitMessage
                    entities={
                      InfoJsonResult.slice(1, InfoJsonResult.length) || []
                    }
                  />
                  <NoRecordsFoundMessage
                    entities={
                      InfoJsonResult.slice(1, InfoJsonResult.length) || []
                    }
                  />
                </BootstrapTable>



                <div className='d-flex flex-stack flex-wrap pt-10'>
        <div className='col-sm-10 col-md-6 h-30px d-flex align-iteml-center justify-content-center justify-content-md-start my-3'>
          <span className='mr-5 pr-3 fw-bold'>Total rows: {DataList?.length}</span>
          <div className='mr-5 d-flex align-iteml-center'>
            {/* Add the dropdown/select for selecting the number of rows */}
            <label className='mr-3 fw-bold' htmlFor='rowsPerPageSelect'>
              Rows per page:
            </label>
            <select onChange={handlePageSizeChange} value={pageSize} className="mb-2 h-20px">
              <option value={10}>10</option>
              <option value={25}>25</option>
              <option value={50}>50</option>
              <option value={100}>100</option>
            </select>
          </div>
        </div>
        <div className="d-flex justify-content-end mt-2">
        <ReactPaginate
          previousLabel={'Previous'}
          nextLabel={'Next'}
          pageCount={Math.ceil(DataList?.length / pageSize)}
          onPageChange={handlePageClick}
          containerClassName={'pagination'}
          previousLinkClassName={'pagination__link'}
          nextLinkClassName={'pagination__link'}
          disabledClassName={'pagination__link--disabled'}
          activeClassName={'pagination__link--active'}
        />
          </div>
       
      </div>



                </>
               
              ) : (
                files.length !== 0 && (
                  <div className="d-flex justify-content-center">
                    <h4 className="text-muted">
                      Your file has an empty data, please upload new file with
                      more data !
                    </h4>
                  </div>
                )
              )}
            </div>
          </Fragment>
        ) : (
          <div className="form-group">
            <Row>
              <Col lg="4">
                <span className="mb-1 font-size-lg font-weight-boldest">
                  File Columns
                </span>
              </Col>
              <Col lg="4">
                <span className="mb-1 font-size-lg font-weight-boldest mr-3">
                  Fields
                </span>
                <OverlayTrigger
                  placement="right"
                  overlay={
                    <Tooltip className="text-danger">
                      {(props?.fields ?? [])
                        .filter((e) => e?.required)
                        .map((x) => x?.name ?? x?.label)
                        .join(", ")}
                    </Tooltip>
                  }
                >
                  <i className="fas fa-exclamation-circle text-danger font-size-lg"></i>
                </OverlayTrigger>
              </Col>
              <Col lg="4">
                <span className="mb-1 font-size-lg font-weight-boldest">
                  Example
                </span>
              </Col>
            </Row>
            <hr className="mb-5" />
            {columnsMappedContent}
          </div>
        )}
        {(isStoresURL === true && step !== 1) && (
          <div className="form-group mb-8">
  <div className="alert alert-custom alert-default" role="alert">
    <div className="alert-icon">
      <span className="svg-icon svg-icon-primary svg-icon-xl">
        {/*begin::Svg Icon | path:/metronic/theme/html/demo1/dist/assets/media/svg/icons/Tools/Compass.svg*/}
        <svg
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          width="24px"
          height="24px"
          viewBox="0 0 24 24"
          version="1.1"
        >
          <title>Stockholm-icons / Tools / Compass</title>
          <desc>Created with Sketch.</desc>
          <defs />
          <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
            <rect x={0} y={0} width={24} height={24} />
            <path
              d="M7.07744993,12.3040451 C7.72444571,13.0716094 8.54044565,13.6920474 9.46808594,14.1079953 L5,23 L4.5,18 L7.07744993,12.3040451 Z M14.5865511,14.2597864 C15.5319561,13.9019016 16.375416,13.3366121 17.0614026,12.6194459 L19.5,18 L19,23 L14.5865511,14.2597864 Z M12,3.55271368e-14 C12.8284271,3.53749572e-14 13.5,0.671572875 13.5,1.5 L13.5,4 L10.5,4 L10.5,1.5 C10.5,0.671572875 11.1715729,3.56793164e-14 12,3.55271368e-14 Z"
              fill="#000000"
              opacity="0.3"
            />
            <path
              d="M12,10 C13.1045695,10 14,9.1045695 14,8 C14,6.8954305 13.1045695,6 12,6 C10.8954305,6 10,6.8954305 10,8 C10,9.1045695 10.8954305,10 12,10 Z M12,13 C9.23857625,13 7,10.7614237 7,8 C7,5.23857625 9.23857625,3 12,3 C14.7614237,3 17,5.23857625 17,8 C17,10.7614237 14.7614237,13 12,13 Z"
              fill="#000000"
              fillRule="nonzero"
            />
          </g>
        </svg>
        {/*end::Svg Icon*/}
      </span>
    </div>
    <div className="alert-text">
      Passwords must have at least one non-alphanumeric character, one digit (\'0\'-\'9\'), and one
      uppercase (\'A\'-\'Z\'). In case the password does not meet the required criteria, a new one will be generated.
    </div>
  </div>
</div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={props.handleClose}>
          Close
        </Button>
        {step === 2 && (
          <button
            className="btn btn-outline-primary"
            onClick={() => {
              setStep(1);
            }}
          >
            <i className="fas fa-chevron-left mr-2"></i>
            Back
          </button>
        )}
        <button
          className="btn btn-primary"
          disabled={
            LoadingPostImport ||
            InfoJsonResult?.length === 0 ||
            loaderMappedFields
          }
          onClick={() => {
            if (step === 1) {
              setupColumnsMapped();
              setStep(2);
            } else {
              var resultErrors = [];
              fields.forEach((column) => {
                var checkResult = fieldsMapped.find(
                  (q) =>
                    getItemString(!!q?.name ? q?.name : q?.label) ===
                      getItemString(
                        !!column?.name ? column?.name : column?.label
                      ) && q.required
                );
                if (!!checkResult === false) {
                  if (
                    column.required &&
                    (!!column?.field || !!column?.label || !!column?.name)
                  ) {
                    resultErrors.push(
                      `Please select field for ${(!!column?.field
                        ? column?.field
                        : column?.label) ?? column?.name}`
                    );
                  }
                  return false;
                }
              });
              if (resultErrors.length > 0) {
                Swal.fire({
                  icon: "error",
                  title: "Error",
                  html: resultErrors.map((x) => x).join("<br/>"),
                });
              } else {
                confirmImport();
              }
            }
          }}
        >
          {!LoadingPostImport && step === 2 && (
            <i className="fas fa-file-import mr-2"></i>
          )}
          {loaderMappedFields && (
            <div>
              <Spinner
                as="span"
                className="mr-2"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
              Please wait...
            </div>
          )}
          {step === 1 ? "Next" : "Import"}
          {step === 1 && <i className="fas fa-chevron-right ml-2"></i>}
          {step === 2 && LoadingPostImport && (
            <Spinner
              as="span"
              className="ml-2"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            />
          )}
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default ImportGlobal;
