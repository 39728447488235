import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import ReactSelect from 'react-select';
import Swal from 'sweetalert2';
import * as Yup from "yup";
import { toAbsoluteUrl } from '../../../_metronic/_helpers';
import { apiCalll, creditCardTypeFromNumber, ModelStateMessage400 } from '../../../_metronic/_helpers/apiHelpers';
import { currencyFormat } from '../../../_metronic/_helpers/GlobalHelpers';

const backgroundSchema = Yup.object().shape({
    cardName: Yup.string().required("Card name is required"),
    cardNumber: Yup.string()
        .matches(/^\d{13,19}$/, "Card number must be 13 or 19 digits")
        .required("Card number is required"),
    expiryMonth: Yup.string().required("Expiry month is required"),
    expiryYear: Yup.string().required("Expiry year is required"),
    cvv: Yup.string().required("CVV is required")
        .min(3, "CVV must be at least 3 digits")
        .max(4, "CVV must be at most 4 digits"),
});

const initialValues = {
    price: 0,
    email: "",
    workLocationState: "",
    workLocationCountry: "",
    workLocationStateName: "",
    workLocationCountryName: "",
    cvv: "",
    cardName: "",
    cardNumber: "",
    expiryMonth: "",
    expiryYear: "",
    phone: "",
    firstName: "",
    lastName: ""
};
export function BackgroundPayment(props) {
    const [token, setToken] = useState("");
    const [loading, setLoading] = useState(false);
    const [showMessage, setShowMessage] = useState(false);

    const formik = useFormik({
        initialValues,
        validationSchema: backgroundSchema,
        onSubmit: async (values, { setStatus, setSubmitting }) => {
            setLoading(true);
            await apiCalll({
                method: "POST",
                link: `/Checkr/driver/invite`,
                token: token,
                data: values,
            }).then(async (result) => {
                try {
                    if (result.length > 0 && result[0] === 200) {
                        Swal.fire({
                            icon: "success",
                            title: "Success",
                            text: "You payment for background check has been submitted successfully",
                        });
                        setShowMessage(true);
                        setLoading(false);
                    } else {
                        ModelStateMessage400(result);
                        setLoading(false);
                    }
                } catch (err) {
                    setLoading(false);
                }
            });
        },
    });

    const t = (val) => val;
    const listMonth = [];
    const listMonthSelect = [];
    const listYear = [];
    const listYearSelect = [];
    var currentTime = new Date().getFullYear();
    for (let index = 1; index <= 12; index++) {
        if (index < 10) {
            listMonth.push(<option>{`0${index}`}</option>);
            listMonthSelect.push(`0${index}`);
        }
        else {
            listMonth.push(<option>{index}</option>);
            listMonthSelect.push(index);
        }
    }
    for (let index = 0; index <= 20; index++) {
        listYear.push(<option>{currentTime + index}</option>);
        listYearSelect.push(currentTime + index);
    }
    useEffect(() => {
        const tokenValue = window.location.search.replace("?token_id=", "");
        if (!!tokenValue) {
            setToken(tokenValue);
            apiCalll({
                method: "GET",
                link: `/Checkr/driver/backgroundCheckDetails`,
                token: tokenValue,
            }).then(async (result) => {
                try {
                    if (result.length > 0 && result[0] === 200) {
                        var a = result[1];
                        formik.setFieldValue("price", a?.details?.price)
                        formik.setFieldValue("firstName", a?.details?.firstName)
                        formik.setFieldValue("lastName", a?.details?.lastName)
                        formik.setFieldValue("email", a?.details?.email)
                        formik.setFieldValue("phone", a?.details?.phoneNumber ?? "-")
                        formik.setFieldValue("workLocationCountry", a?.details?.location?.countryCode);
                        formik.setFieldValue("workLocationState", a?.details?.location?.stateCode);

                        // formik.setFieldValue("workLocationCountry", a?.details?.location?.countryCode ?? "-")
                        // formik.setFieldValue("workLocationState", a?.details?.location?.stateCode ?? "-")
                        formik.setFieldValue("workLocationStateName", a?.details?.location?.stateName ?? "-")
                        formik.setFieldValue("workLocationCountryName", a?.details?.location?.countryName ?? "-")
                    }
                } catch (err) { }
            });
        }
    }, []);
    function ImageCardNumber({ value }) {
        return (
            <img
                src={toAbsoluteUrl(
                    creditCardTypeFromNumber(value) === "Visa"
                        ? "/media/svg/card-logos/visa.svg"
                        : creditCardTypeFromNumber(value) === "MasterCard"
                            ? "/media/svg/card-logos/mastercard.svg"
                            : creditCardTypeFromNumber(value) === "Amex"
                                ? "/media/svg/card-logos/american-express.svg"
                                : creditCardTypeFromNumber(value) === "Discover"
                                    ? "/media/svg/card-logos/discover.svg"
                                    : creditCardTypeFromNumber(value) === "UnionPay"
                                        ? "/media/svg/card-logos/unionpay.svg"
                                        : creditCardTypeFromNumber(value) === "JCB"
                                            ? "/media/svg/card-logos/jcb.svg"
                                            : creditCardTypeFromNumber(value) === "Diners"
                                                ? "/media/svg/card-logos/diners-club.svg"
                                                : creditCardTypeFromNumber(value) === "Union Pay"
                                                    ? "/media/svg/card-logos/unionpay.svg"
                                                    : ""
                )}
                alt=""
                className="h-25px"
            />
        );
    }
    return (
        <div className="login-form login-signin" id="kt_login_signin_form">
            <div className="text-left mb-10 mb-lg-10">
                <h3 className="d-flex">
                    <i className='flaticon2-correct mr-3 text-primary' style={{ placeContent: "center", fontSize: "1.5rem" }} />
                    Background check payment
                </h3>
            </div>
            {showMessage ? (
                <span className='text-success text-center font-size-xl d-flex'>
                    🎉 Thank you! Your payment was successful. We're processing your background check, and you’ll receive updates soon!
                </span>
            ) : <form
                onSubmit={formik.handleSubmit}
                className="form fv-plugins-bootstrap fv-plugins-framework"
            >
                <div className="form-group fv-plugins-icon-container justify-content-between d-flex">
                    <label className='font-weight-bold'>
                        <i className='flaticon-price-tag mr-3 text-primary' style={{ placeContent: "center" }} />
                        Price</label>
                    <span className='text-primary'>${currencyFormat(formik.values.price)}</span>
                </div>
                <div className="form-group fv-plugins-icon-container justify-content-between d-flex">
                    <label className='font-weight-bold'>                            <i className='flaticon2-user mr-3 text-primary' style={{ placeContent: "center" }} />
                        Full name</label>
                    <span className='text-primary'>{formik.values.firstName} {formik.values.lastName}</span>
                </div>
                <div className="form-group fv-plugins-icon-container justify-content-between d-flex">
                    <label className='font-weight-bold'>
                        <i className='flaticon2-send mr-3 text-primary' style={{ placeContent: "center" }} />
                        Email</label>
                    <span className='text-primary'>{formik.values.email}</span>
                </div>
                <div className="form-group fv-plugins-icon-container justify-content-between d-flex">
                    <label className='font-weight-bold'>                            <i className='flaticon-placeholder-3 mr-3 text-primary' style={{ placeContent: "center" }} />
                        Country</label>
                    <span className='text-primary'>{formik.values.workLocationCountryName}</span>
                </div>
                <div className="form-group fv-plugins-icon-container justify-content-between d-flex">
                    <label className='font-weight-bold'>                            <i className='flaticon-placeholder-3 mr-3 text-primary' style={{ placeContent: "center" }} />
                        State</label>
                    <span className='text-primary'>{formik.values?.workLocationStateName}</span>
                </div>
                <div className="form-group fv-plugins-icon-container">
                    <label>
                        {t("Name on card")} <span className="text-danger">*</span>
                        <OverlayTrigger
                            overlay={
                                <Tooltip
                                    style={{ pointerEvents: "none" }}
                                    id="products-delete-tooltip"
                                >
                                    {t("Specify a card holder name")}
                                </Tooltip>
                            }
                        >
                            <i className="fa fa-exclamation-circle ml-2 fs-7"></i>
                        </OverlayTrigger>
                    </label>
                    <input
                        type="text"
                        className="form-control  form-control-md"
                        placeholder="Card Name"
                        name="cardName"
                        onChange={(e) => {
                            formik.setFieldValue(
                                "cardName",
                                e.target.value
                            );
                        }}
                    />
                        {formik.touched.cardName && formik.errors.cardName ? (
                                    <div className="fv-plugins-message-container mt-2">
                                    <div className="text-danger">* {formik.errors.cardName}</div>
                            </div>
                        ) : null}
                </div>
                <div className="form-group">
                    <label>
                        {t("Card number")}
                        <span className="text-danger">*</span>
                    </label>
                    <div className="position-relative">
                        <input
                            type="number"
                            className="form-control  form-control-md"
                            placeholder={t("Card number")}
                            name="cardNumber"
                            onChange={(e) => {
                                formik.setFieldValue(
                                    "cardNumber",
                                    e.target.value
                                );
                            }}
                        />
                        {formik.values.cardNumber !== "" && (
                            <div className="position-absolute translate-middle-c top-50 end-0 mr-5">
                                <ImageCardNumber value={formik.values.cardNumber} />
                            </div>
                        )}

                            {formik.touched.cardNumber && formik.errors.cardNumber ? (
                                    <div className="fv-plugins-message-container mt-2">
                                    <div className="text-danger">* {formik.errors.cardNumber}</div>
                                </div>
                            ) : null}
                    </div>
                </div>
                {/*end::Input*/}
                <div className="row">
                    <div className="col-xl-4">
                        {/*begin::Input*/}
                        <div className="form-group fv-plugins-icon-container">
                            <label>
                                {t("Expiration date")}
                                <span className="text-danger">*</span>
                            </label>
                            <ReactSelect
                                name="expiryMonth"
                                isSearchable={true}
                                placeholder={t("Month")}
                                options={listMonthSelect.map((a) => {
                                    return {
                                        label: a,
                                        value: a,
                                    };
                                })}
                                onChange={(e) => {
                                    formik.setFieldValue(
                                        "expiryMonth",
                                        e.value.toString()
                                    );
                                }}
                            />
                                {formik.touched.expiryMonth && formik.errors.expiryMonth ? (
                                    <div className="fv-plugins-message-container mt-2">
                                    <div className="text-danger">* {formik.errors.expiryMonth}</div>
                                    </div>
                                ) : null}
                        </div>
                        {/*end::Input*/}
                    </div>

                    <div className="col-xl-4">
                        {/*begin::Input*/}
                        <div className="form-group fv-plugins-icon-container mt-8">
                            <ReactSelect
                                name="expiryYear"
                                isSearchable={true}
                                placeholder={t("Year")}
                                options={
                                    listYearSelect.map((a) => {
                                        return {
                                            label: a.toString().slice(-2),
                                            value: a.toString().slice(-2),
                                        };
                                    }
                                    )
                                }
                                onChange={(e) => {
                                    formik.setFieldValue(
                                        "expiryYear",
                                        e.value.toString()
                                    );
                                }}
                            />
                                {formik.touched.expiryYear && formik.errors.expiryYear ? (
                                    <div className="fv-plugins-message-container mt-2">
                                    <div className="text-danger">* {formik.errors.expiryYear}</div>
                                    </div>
                                ) : null}
                        </div>
                        {/*end::Input*/}
                    </div>

                    <div className="col-xl-4">
                        {/*begin::Input*/}
                        <div className="form-group fv-plugins-icon-container">
                            <label>
                                {t("CVV")} <span className="text-danger">*</span>
                                <OverlayTrigger
                                    overlay={
                                        <Tooltip
                                            style={{ pointerEvents: "none" }}
                                            id="products-delete-tooltip"
                                        >
                                            {t("Enter a cvv code")}
                                        </Tooltip>
                                    }
                                >
                                    <i className="fa fa-exclamation-circle ml-2 fs-7"></i>
                                </OverlayTrigger>
                            </label>
                            <div className="position-relative">
                                <input
                                    type="number"
                                    className="form-control form-control-md"
                                    placeholder={t("CVV")}
                                    name="cvv"
                                    onChange={(e) => {
                                        formik.setFieldValue(
                                            "cvv",
                                            e.target.value
                                        );
                                    }}
                                />
                                <div className="position-absolute translate-middle-c top-50 end-0 mr-3">
                                    <span className="svg-icon svg-icon-2hx">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width={24}
                                            height={24}
                                            viewBox="0 0 24 24"
                                            fill="none"
                                        >
                                            <path
                                                d="M22 7H2V11H22V7Z"
                                                fill="currentColor"
                                            />
                                            <path
                                                opacity="0.3"
                                                d="M21 19H3C2.4 19 2 18.6 2 18V6C2 5.4 2.4 5 3 5H21C21.6 5 22 5.4 22 6V18C22 18.6 21.6 19 21 19ZM14 14C14 13.4 13.6 13 13 13H5C4.4 13 4 13.4 4 14C4 14.6 4.4 15 5 15H13C13.6 15 14 14.6 14 14ZM16 15.5C16 16.3 16.7 17 17.5 17H18.5C19.3 17 20 16.3 20 15.5C20 14.7 19.3 14 18.5 14H17.5C16.7 14 16 14.7 16 15.5Z"
                                                fill="currentColor"
                                            />
                                        </svg>
                                    </span>
                                </div>
                                {formik.touched.cvv && formik.errors.cvv ? (
                                    <div className="fv-plugins-message-container mt-2">
                                        <div className="text-danger">* {formik.errors.cvv}</div>
                                    </div>
                                ) : null}
                            </div>
                            <div className="fv-plugins-message-container" />
                        </div>
                        {/*end::Input*/}
                    </div>
                </div>
                <button
                    // id="kt_login_signin_submit"
                    type="button"
                    onClick={(e) => {
                        e.preventDefault();
                        formik.handleSubmit();
                    }}
                    disabled={formik.isSubmitting || loading}
                    className={`btn btn-info font-weight-bold px-9 py-4 my-3 w-100`}
                >
                    <span>{t("Pay now")}</span>
                    {loading && <span className="ml-3 spinner spinner-white"></span>}
                </button>
            </form>}
            {/*end::Form*/}
        </div>
    )
}
