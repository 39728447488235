import moment from "moment";
import React, { useCallback, useEffect, useRef } from "react";
import { useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import { useReactToPrint } from "react-to-print";
import { useHistory } from "react-router-dom";
import SVG from "react-inlinesvg";
import "react-google-flight-datepicker/dist/main.css";
import { RangeDatePicker } from "react-google-flight-datepicker";
import {
  Card,
  CardBody,
  Pagination,
} from "../../../../../_metronic/_partials/controls";
import {
  sortCaret,
  headerSortingClasses,
  PleaseWaitMessage,
  NoRecordsFoundMessage,
  toAbsoluteUrl,
  sortCaretTwo,
} from "../../../../../_metronic/_helpers";
import Swal from "sweetalert2";
import Bill from "../managedrivers/driver-details/Bill";
import { Button, Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import ReactSelect from "react-select";
import html2pdf from 'html2pdf.js';
import { formatNumberWithCommas } from "../../../../../_metronic/_helpers/GlobalHelpers";

const Payout = (props) => {
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const token = JSON.parse(localStorage.getItem("user")).token;
  const [search, setSearch] = useState("");
  const [pagination, setPagination] = useState([]);
  const [drivers, setDrivers] = useState([]);
  const [id, setId] = useState("");
  const [ids] = useState([]);
  const [invoices, setInvoices] = useState([]);
  const [billInfos, setBillInfos] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isPaying, setIsPaying] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState("");
  const [show, setShow] = useState(false);
  const [withStripe, setWithStripe] = useState(true);
  const [extra, setExtra] = useState(null);
  const [adjustmentDescription, setAdjustmentDescription] = useState("");
  const [showBtnPay, setShowBtnPay] = useState(false);
  const [downloadIsLoading, setDownloadIsLoading] = useState(false);
  const [isPaymentLoading, setIsPaymentLoading] = useState(false);
  const downloadBillAsPDF = async () => {
    const element = componentRef.current;

    const timestamp = new Date().getTime();
    const invoice = invoices;
    const filename = invoice?.invoiceNumber
        ? `invoice-${invoice.invoiceNumber}.pdf`
        : `invoice-${timestamp}.pdf`;

    const options = {
        margin: 0,
        filename,
        image: { type: 'jpeg', quality: 0.98 },
        html2canvas: { scale: 1, logging: false },
        jsPDF: {
            unit: 'in',
            format: 'a3',
            orientation: 'portrait',
        },
    };

    try {
        await html2pdf().set(options).from(element).save();
    } finally {
        setDownloadIsLoading(false);
    }
};
  const history = useHistory();
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const componentRef = useRef();

  const [initFilter, setinitFilter] = useState({
    page: 1,
    sizePerPage: 10,
    sortField: "Id",
    sortOrder: "desc",
    totalSize: 10,
    sizePerPageList: [
      { text: "10", value: 10 },
      { text: "20", value: 20 },
      { text: "50", value: 50 },
      { text: "100", value: 100 },
    ],
  });

  useEffect(() => {
    setinitFilter({
      page: 1,
      sizePerPage: 10,
      sortField: "Id",
      sortOrder: "desc",
      custom: true,
      totalSize: 100,
      sizePerPageList: [
        { text: "10", value: 10 },
        { text: "20", value: 20 },
        { text: "50", value: 50 },
        { text: "100", value: 100 },
      ],
    });
  }, []);

  const GetHandlerTableChange = (type, newState) => {
    let values = { ...initFilter };
    if (type === "sort") {
      values.sortField = newState.sortField;
      values.sortOrder = newState.sortOrder;
    }
    if (type === "pagination") {
      values.sizePerPage = newState.sizePerPage;
      values.page = newState.page === undefined ? 1 : newState.page;
    }
    setinitFilter(values);
  };

  function getTimeZoneOffset() {
    const offsetInMinutes = new Date().getTimezoneOffset();

    const hours = Math.floor(Math.abs(offsetInMinutes) / 60);
    const sign = offsetInMinutes < 0 ? "+" : "-";

    return `${sign}${hours.toString().padStart(2, "")}`;
  }

  const firstLetterUppercase = (word) => {
    return word.charAt(0).toUpperCase() + word.slice(1);
  };

  const handleGetDrivers = useCallback((filter) => {
    const sortFiler = JSON.parse(localStorage.getItem("filterPayoutBillingSort"));
    filter.sortField = sortFiler?.sortField || "Id";
    filter.sortOrder = sortFiler?.sortOrder || "desc";
    fetch(
      `${BASE_URL}/invoicing/drivers?pageNumber=${filter?.page}&pageSize=${
        filter?.sizePerPage
      }&search=${search || ""}&startDate=${startDate || ""}&endDate=${endDate ||
        ""}&timezone=${getTimeZoneOffset()}&sortOrder=${
        filter?.sortOrder
      }&sortField=${firstLetterUppercase(filter?.sortField)}&paymentMethod=${paymentMethod}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        Allow: "GET",
      }
    )
      .then(async (response) => {
        const statusCode = await response.status;
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
          return response.json().then((data) => [statusCode, data]);
        } else {
          return response.text().then((data) => [statusCode, data]);
        }
      })
      .then((data) => {
        if (data[0] === 200) {
          setDrivers(data[1]?.drivers);
          setPagination(data[1]?.pagination);
        }
        else if (data[0] === 401) {
          history.push("/logout");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  },[BASE_URL, endDate, paymentMethod, search, startDate, token]);

  useEffect(() => {
    const debounceSearch = setTimeout(() => {
      handleGetDrivers(initFilter);
    }, 500);
    return () => {
      clearTimeout(debounceSearch);
    };
  }, [initFilter, search, startDate, endDate, paymentMethod, handleGetDrivers]);

  var methods = [
    {
      value: "",
      label: "All",
    },
    {
    value: true,
    label: "Stripe",
  },
  {
    value: false,
    label: "EMT",
  },];

  useEffect(() => {
    try{
      var resultFilter = JSON.parse(localStorage.getItem("filterPayoutBilling"));
      setSearch(resultFilter?.search);
      setPaymentMethod((resultFilter?.paymentMethod === "All" ? "" : resultFilter?.paymentMethod) ?? "");
      if(!!resultFilter?.startDate && resultFilter?.startDate !== "Invalid date") setStartDate(resultFilter?.startDate);
      if(!!resultFilter?.endDate && resultFilter?.endDate !== "Invalid date") setEndDate(resultFilter?.endDate);
   }catch(err) {}
  },[]);

  const handleGetPayoutEverything = (withStripe) => {
    setIsLoading(true);
    fetch(
      `${BASE_URL}/Payouts/GetTotalPayoutEverything/${id}?startDate=${startDate ||
        ""}&endDate=${endDate ||
        ""}&NotPayedOut=${true}&withStripe=${withStripe}&timezone=${getTimeZoneOffset()}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        Allow: "GET",
      }
    )
      .then(async (response) => {
        const statusCode = await response.status;
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
          return response.json().then((data) => [statusCode, data]);
        } else {
          return response.text().then((data) => [statusCode, data]);
        }
      })
      .then((data) => {
        if (data[0] === 200) {
          setInvoices(data[1]);
          setShow(true);
        } else if (data[0] === 400 && data[1].message) {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: data[1].message,
          });
        } else if (data[0] === 400) {
          let tt = JSON.parse(data[1]);
          for (const [key, value] of Object.entries(tt.errors)) {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: value,
            });
          }
        } else if (data[0] === 401) {
          history.push("/logout");
        } else if (data[0] === 402) {
          Swal.fire({
            title: "Info!",
            text: "You have to subscribe first!",
            icon: "info",
            confirmButtonText: "Ok",
          }).then((result) => {
            if (result.isConfirmed) {
              history.push("/user-profile");
            }
          });
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (id) {
      handleGetPayoutEverything(withStripe);
    }
  }, [id, withStripe]);
  useEffect(() => {
    if (!show) {
      setId("");
      setExtra(null);
      setWithStripe(true);
      setAdjustmentDescription("");
      setBillInfos([])
    }
  }, [show]);

  function camelCaseToReadable(camelCaseStr) {
    let result = camelCaseStr.replace(/([A-Z])/g, ' $1');
    result = result.charAt(0).toUpperCase() + result.slice(1);
    return result;
  }

  const columns = [
    {
      dataField: "firstName",
      text: "Driver name",
      sort: true,
      onSort: (field, order) => {
        localStorage.setItem("filterPayoutBillingSort",JSON.stringify({
            sortField: field,
            sortOrder: order,
          })
        );
      },
      sortCaret: sortCaret,
      headerSortingClasses,
      formatter: (cellContent, row) => (
        <p
          className="text-info font-weight-bolder text-hover-muted mb-1 font-size-lg"
          style={{ cursor: "pointer" }}
          onClick={() => {
            history.push(`/driver/${row.id}/details/payout`);
          }}
        >
          {row?.firstName} {row?.lastName}
        </p>
      ),
    },

    {
      dataField: "email",
      text: "Email",
      sort: true,
      onSort: (field, order) => {
        localStorage.setItem("filterPayoutBillingSort",JSON.stringify({
            sortField: field,
            sortOrder: order,
          })
        );
      },
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "phone",
      text: "Phone number",
      sort: true,
      onSort: (field, order) => {
        localStorage.setItem("filterPayoutBillingSort",JSON.stringify({
            sortField: field,
            sortOrder: order,
          })
        );
      },
      sortCaret: sortCaret,
      headerSortingClasses,
      formatter: (cellContent, row) => (
        <p 
        onClick={() => {
          window.open(`tel:${row.phone}`);
        }}
        className="text-info font-weight-bolder text-hover-muted mb-1 font-size-lg cursor-pointer">{row.phone}</p>
      ),
    },
    {
      dataField: "address1",
      text: "Address",
      sort: true,
      onSort: (field, order) => {
        localStorage.setItem("filterPayoutBillingSort",JSON.stringify({
            sortField: field,
            sortOrder: order,
          })
        );
      },
      sortCaret: sortCaret,
      headerSortingClasses,
      formatter: (cellContent, row) =>
        row.address1 && (
          <OverlayTrigger
            key={"right"}
            placement={"right"}
            overlay={
              <Tooltip id={`tooltip-${"placement"}`}>
                {row.address1 +
                  (row.address2 !== null &&
                  row.address2 !== undefined &&
                  row.address2 !== ""
                    ? ", " + row.address2
                    : "") +
                  (row.city !== null &&
                  row.city !== undefined &&
                  row.city !== ""
                    ? ", "
                    : "") +
                  row.city +
                  (row.state !== null &&
                  row.state !== undefined &&
                  row.state !== "" &&
                  row.zipCode !== null &&
                  row.zipCode !== undefined &&
                  row.zipCode !== ""
                    ? ", "
                    : "") +
                  row.state +
                  " " +
                  row.zipCode}
              </Tooltip>
            }
          >
            <span
              style={{ cursor: "pointer" }}
              className="svg-icon menu-icon svg-icon-info"
            >
              <SVG src={toAbsoluteUrl("/media/svg/icons/Map/Marker2.svg")} />
            </span>
          </OverlayTrigger>
        ),
    },
    {
      dataField: "ordersCountNotPaid",
      sortCaret: sortCaret,
      text: "Deliveries Not PAID",
      sort: true,
      onSort: (field, order) => {
        localStorage.setItem("filterPayoutBillingSort",JSON.stringify({
            sortField: field,
            sortOrder: order,
          })
        );
      },
      headerSortingClasses,
      formatter: (cellContent, row) => (
        <p
          className="text-info font-weight-bolder text-hover-muted mb-1 font-size-lg"
          style={{ cursor: "pointer" }}
          onClick={() => {
            history.push(`/driver/${row.id}/details/payout`);
          }}
        >
          {formatNumberWithCommas(row.ordersCountNotPaid)}
        </p>
      ),
    },
    {
      dataField: "amount",
      text: "Subtotal",
      sort: true,
      onSort: (field, order) => {
        localStorage.setItem("filterPayoutBillingSort",JSON.stringify({
            sortField: field,
            sortOrder: order,
          })
        );
      },
      sortCaret: sortCaret,
      formatter: (cellContent, row) => (
        <>
          {new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
              }).format(row.amount || 0)
          }
        </>
      ),
      headerSortingClasses,
    },
    {
      dataField: "payout",
      text: "Actions",
      sortCaret: sortCaret,
      headerSortingClasses,
      formatter: (cellContent, row) => (
        <div className="d-flex">
        <button
          onClick={() => {
            setId(row.id);
            setWithStripe(row?.paymentMethod === "Stripe");
            setShowBtnPay(true);
          }}
          className={`btn btn-sm btn-secondary font-weight-bolder font-size-sm mr-3`}
        >
          Show & Payout
        </button>
        <button
          onClick={() => {
            const isStripe = paymentMethod === "Stripe" ? true : false;
            Swal.fire({
              title: "Are you sure?",
              text: "You want to pay this bill!",
              icon: "warning",
              showCancelButton: true,
              confirmButtonText: "Yes, Pay it!",
              cancelButtonText: "No, cancel!",
              reverseButtons: true,
            }).then((result) => {
              if (result.isConfirmed) {
                handlePayoutAllButton(row.id, isStripe);
              }
            });
          }}
          className={`btn btn-sm btn-success font-weight-bolder font-size-sm`}
        >
          Payout
        </button>
        </div>
      ),
    },
  ];

  useEffect(() => {
    if (isPaymentLoading) {
      Swal.fire({
        title: "Processing...",
        html: "Please wait while we are processing your request",
        padding: "2em",
        allowOutsideClick: false,
        showConfirmButton: false,
        willOpen: () => {
          Swal.showLoading();
        },
      });
    }
  }, [isPaymentLoading]);

  const handlePayoutAllButton = (driverId, isStripe) => {
    setIsPaymentLoading(true);
    fetch(
      `${BASE_URL}/Payouts/PayoutEverything?driverId=${driverId}&startDate=${startDate ||
        ""}&endDate=${endDate ||
        ""}&NotPayedOut=${true}&payByStripe=${isStripe}&extras=${extra ||
        0}&adjustment=${adjustmentDescription}&timezone=${getTimeZoneOffset()}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        Allow: "POST",
      }
    )
      .then(async (response) => {
        const statusCode = await response.status;
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
          return response.json().then((data) => [statusCode, data]);
        } else {
          return response.text().then((data) => [statusCode, data]);
        }
      })
      .then((data) => {
        if (data[0] === 200) {
          Swal.fire({
            icon: "success",
            title: "Success",
            html: `Payout Informations : </br> Paid : ${data[1].countSuccess} orders </br> Failed : ${data[1].countError} orders`,
          });
          setShowBtnPay(false);
          handleGetDrivers(initFilter);
        } else if (data[0] === 400 && data[1].message) {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: data[1].message,
          });
        } else if (data[0] === 400) {
          let tt = JSON.parse(data[1]);
          for (const [key, value] of Object.entries(tt.errors)) {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: value,
            });
          }
        } else if (data[0] === 401) {
          history.push("/logout");
        } else if (data[0] === 402) {
          Swal.fire({
            title: "Info!",
            text: "You have to subscribe first!",
            icon: "info",
            confirmButtonText: "Ok",
          }).then((result) => {
            if (result.isConfirmed) {
              history.push("/user-profile");
            }
          });
        }
      })
      .catch((error) => {
        console.log(error);
      }).finally(() => {
        setIsPaymentLoading(false);
      });
  };
  const handlePayoutEverything = (isManual) => {
    setIsPaying(true);
    fetch(
      `${BASE_URL}/Payouts/PayoutEverything?driverId=${id}&startDate=${startDate ||
        ""}&endDate=${endDate ||
        ""}&NotPayedOut=${true}&manual=${isManual}&payByStripe=${withStripe}&extras=${extra ||
        0}&adjustment=${adjustmentDescription}&timezone=${getTimeZoneOffset()}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        Allow: "POST",
      }
    )
      .then(async (response) => {
        const statusCode = await response.status;
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
          return response.json().then((data) => [statusCode, data]);
        } else {
          return response.text().then((data) => [statusCode, data]);
        }
      })
      .then((data) => {
        if (data[0] === 200) {
          Swal.fire({
            icon: "success",
            title: "Success",
            html: `Payout Informations : </br> Paid : ${data[1].countSuccess} orders </br> Failed : ${data[1].countError} orders`,
          });
          setBillInfos(data[1])
          setShowBtnPay(false);
          handleGetDrivers(initFilter);
          downloadBillAsPDF()
        } else if (data[0] === 400 && data[1].message) {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: data[1].message,
          });
        } else if (data[0] === 400) {
          let tt = JSON.parse(data[1]);
          for (const [key, value] of Object.entries(tt.errors)) {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: value,
            });
          }
        } else if (data[0] === 401) {
          history.push("/logout");
        } else if (data[0] === 402) {
          Swal.fire({
            title: "Info!",
            text: "You have to subscribe first!",
            icon: "info",
            confirmButtonText: "Ok",
          }).then((result) => {
            if (result.isConfirmed) {
              history.push("/user-profile");
            }
          });
        }
      })
      .catch((error) => {
        console.log(error);
      }).finally(() => {
        setIsPaying(false);
      });
  };
  const paginationOptions = {
    custom: true,
    totalSize: pagination?.totalItemCount || 0,
    sizePerPageList: initFilter?.sizePerPageList || [],
    sizePerPage: pagination?.pageSize || 10,
    page: pagination?.pageNumber || 1,
  };

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <>
      <Card>
        <CardBody>
          <div className="row mb-7">
            <div className="col-md-4">
              <input
                type="search"
                className="form-control"
                name="search"
                placeholder="Search"
                value={search}
                onChange={(e) => {
                  setSearch(e.target.value);

                  var objectFilter = {
                    search: e.target.value,
                    startDate: startDate,
                    endDate: endDate,
                  };
                  localStorage.setItem("filterPayoutBilling", JSON.stringify(objectFilter));

                }}
              />
              <small className="form-text text-muted">
                <b>Search</b> in all fields
              </small>
            </div>
            <div className="col-md-4 ">
              <RangeDatePicker
                startDate={
                  startDate !== null && moment(startDate).format("YYYY-MM-DD")
                }
                endDate={
                  endDate !== null && moment(endDate).format("YYYY-MM-DD")
                }
                onChange={(startDate, endDate) => {
                  startDate === null
                    ? setStartDate(null)
                    : setStartDate(moment(startDate).format("YYYY-MM-DD"));

                  endDate === null
                    ? setEndDate(null)
                    : setEndDate(moment(endDate).format("YYYY-MM-DD"));

                    var objectFilter = {
                      search: search,
                      startDate: moment(startDate).format("YYYY-MM-DD"),
                      endDate: moment(endDate).format("YYYY-MM-DD"),
                    };
                    localStorage.setItem("filterPayoutBilling", JSON.stringify(objectFilter));
  
                }}
                dateFormat="MM/DD/YYYY"
                minDate={new Date(1900, 0, 1)}
                maxDate={new Date(2100, 0, 1)}
                startDatePlaceholder="Start Date"
                endDatePlaceholder="End Date"
                disabled={false}
                startWeekDay="monday"
              />
              <small className="form-text text-muted">
                <b>Filter</b> by Date
              </small>
            </div>
            <div className="col-md-4">
              <ReactSelect
                className="basic-single"
                classNamePrefix="select"
                isSearchable={true}
                options={methods}
                value={methods.find((method) => method.label === (paymentMethod === "" ? "All" : paymentMethod))}
                onChange={(e) => {
                  setPaymentMethod(e.label === "All" ? "" : e.label);
                  var objectFilter = {
                    search: search,
                    startDate: startDate,
                    endDate: endDate,
                    paymentMethod: e.label
                  };
                  localStorage.setItem("filterPayoutBilling", JSON.stringify(objectFilter));
                }}
              />
              <small className="form-text text-muted">
                <b>Filter</b> by Payment Method
              </small>
            </div>
            <div className="d-flex justify-content-end col-12">
             {JSON.parse(localStorage.getItem("filterPayoutBillingSort"))?.sortField && <button
                    type="button"
                    onClick={() => {
                      localStorage.setItem("filterPayoutBillingSort", JSON.stringify({
                        sortField: "",
                        sortOrder: "desc",
                      }));
                      handleGetDrivers(initFilter);
                    }}
                    className="btn btn-primary ml-xl-3 ml-lg-3 ml-md-3 ml-0"
                  >
                    {sortCaretTwo(JSON.parse(localStorage.getItem("filterPayoutBillingSort"))?.sortOrder, JSON.parse(localStorage.getItem("filterPayoutBillingSort"))?.sortField)}
                    {
                      camelCaseToReadable(JSON.parse(localStorage.getItem("filterPayoutBillingSort"))?.sortField)
                    }
                    <i className="flaticon-circle font-size-sm ml-3"></i>
                  </button>}
                  <button
                    type="button"
                    onClick={() => {
                      localStorage.removeItem("filterPayoutBilling");
                      localStorage.removeItem("filterPayoutBillingSort");
                      setSearch("");
                      setStartDate(null);
                      setEndDate(null);
                      setPaymentMethod("");
                    }}
                    className="btn btn-outline-danger ml-xl-3 ml-lg-3 ml-md-3 ml-3"
                  >
                    <i className="flaticon-circle font-size-sm"></i>
                    Clear filter
                  </button>
            </div>
          </div>
          <PaginationProvider pagination={paginationFactory(paginationOptions)}>
            {({ paginationProps, paginationTableProps }) => {
              return (
                <Pagination
                  paginationProps={paginationProps}
                  table="invoicesPageSize"
                >
                  <BootstrapTable
                    wrapperClasses="table-responsive"
                    classes="table table-head-custom table-vertical-center overflow-hidden"
                    bootstrap4
                    bordered={false}
                    remote
                    keyField="id"
                    data={drivers || []}
                    columns={columns}
                    defaultSorted={[{ dataField: "Created", order: "desc" }]}
                    onTableChange={GetHandlerTableChange}
                    {...paginationTableProps}
                  >
                    <PleaseWaitMessage entities={drivers || []} />
                    <NoRecordsFoundMessage entities={drivers || []} />
                  </BootstrapTable>
                </Pagination>
              );
            }}
          </PaginationProvider>
        </CardBody>
      </Card>

      <Modal
        size="xl"
        show={show}
        onHide={() => {
          setShow(false);
        }}
      >
        <Modal.Header closeButton className="border-0"></Modal.Header>
        <Modal.Body>
          <Bill
            componentRef={componentRef}
            invoices={invoices}
            isLoading={isLoading}
            name={props.name}
            withStripe={withStripe}
            extra={extra}
            setExtra={setExtra}
            setWithStripe={setWithStripe}
            ids={ids}
            handleGetPayoutEverything={handleGetPayoutEverything}
            showBtnPay={showBtnPay}
            startDate={startDate}
            endDate={endDate}
            adjustmentDescription={adjustmentDescription}
            setAdjustmentDescription={setAdjustmentDescription}
            billInfos={billInfos}
            isDetails={false}
          />
        </Modal.Body>
        <Modal.Footer className="border-0">
          <Button
            variant="secondary"
            onClick={() => {
              setShow(false);
            }}
          >
            Close
          </Button>
          {showBtnPay && !withStripe && (
            <div className="my-1 me-5">
              <button
                disabled={isPaying}
                type="button"
                className="btn btn-warning my-1 me-12"
                onClick={() => {
                  if(extra !== 0 && extra !== null  && adjustmentDescription === "")
                  {
                    Swal.fire({
                      icon: "info",
                      title: "Oops...",
                      text: "Please enter the adjustment description",
                    });
                    return;
                  }
                  Swal.fire({
                    title: "Are you sure?",
                    text: "You want to pay this bill!",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonText: "Yes, Pay it!",
                    cancelButtonText: "No, cancel!",
                    reverseButtons: true,
                  }).then((result) => {
                    if (result.isConfirmed) {
                      handlePayoutEverything(true);
                    }
                  });
                }}
              >
                {isPaying ? "Paying..." : "Pay Manually"}
              </button>
            </div>
          )}
          {showBtnPay && (
            <div className="my-1 me-5">
              <button
                disabled={isPaying}
                type="button"
                className="btn btn-info my-1 me-12"
                onClick={() => {
                  if(extra !== 0 && extra !== null  && adjustmentDescription === "")
                  {
                    Swal.fire({
                      icon: "info",
                      title: "Oops...",
                      text: "Please enter the adjustment description",
                    });
                    return;
                  }
                  Swal.fire({
                    title: "Are you sure?",
                    text: "You want to pay this bill!",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonText: "Yes, Pay it!",
                    cancelButtonText: "No, cancel!",
                    reverseButtons: true,
                  }).then((result) => {
                    if (result.isConfirmed) {
                      handlePayoutEverything(false);
                    }
                  });
                }}
              >
                {isPaying ? "Paying..." : "Pay Bill"}
              </button>
            </div>
          )}
          <div className="my-1 me-5">
            {!showBtnPay && (
              <>
                <button
                  type="button"
                  className="btn btn-success my-1 me-12 me-5 mr-5"
                  onClick={handlePrint}
                >
                  Print Bill
                </button>
                <button
                  type="button"
                  disabled={downloadIsLoading}
                  className="btn btn-light-primary my-1 me-12"
                  onClick={() => {
                    setDownloadIsLoading(true);
                    downloadBillAsPDF()
                  }}
                >
                  {downloadIsLoading ? 'Downloading...' : 'Download PDF'}
                </button>
              </>
            )}
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Payout;
