import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";

const AutoLogout = ({ children }) => {
  const history = useHistory();
  const LOGOUT_TIMEOUT = 10 * 60 * 1000;
  const LAST_ACTIVITY_KEY = "lastActivity";
  
  const handleLogout = () => {
    const lastActivity = parseInt(localStorage.getItem(LAST_ACTIVITY_KEY), 10);
    const now = Date.now();
    if (now - lastActivity >= LOGOUT_TIMEOUT) {
      history.push("/logout");
    }
  };

  const resetTimeout = () => {
    const now = Date.now();
    localStorage.setItem(LAST_ACTIVITY_KEY, now.toString());
  };

  useEffect(() => {
    localStorage.setItem(LAST_ACTIVITY_KEY, Date.now().toString());

    const events = ["mousemove", "keydown", "click", "scroll"];
    events.forEach((event) => window.addEventListener(event, resetTimeout));

    const interval = setInterval(handleLogout, 1000);

    return () => {
      events.forEach((event) =>
        window.removeEventListener(event, resetTimeout)
      );
      clearInterval(interval);
    };
  }, []);

  return <>{children}</>;
};

export default AutoLogout;
