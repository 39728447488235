import React, { useState } from "react";
import { useFormik } from "formik";
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import * as Yup from "yup";
import { injectIntl } from "react-intl";
import * as auth from "../_redux/authRedux";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";
import { translateAuto } from "../../../../_metronic/_helpers/GlobalHelpers";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import SVG from "react-inlinesvg";
import { useHistory } from "react-router-dom";

const BASE_URL = process.env.REACT_APP_BASE_URL;

function ResetPassword(props) {
  const { t } = useTranslation();
  const { intl } = props;
  const [isRequested, setIsRequested] = useState(false);
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  var url2 = window.location.search;
  let param = url2?.replace("?code=", "");
  let token = param?.split(",,,")[0];
  var email = param?.split(",,,")[1];

  const ForgotPasswordSchema = Yup.object().shape({
    password: Yup.string()
      .required(translateAuto("Password is required"))
      .min(6, translateAuto("Password must be at least 6 characters long"))
      .matches(/[A-Z]/, translateAuto("Password must contain at least one uppercase letter"))
      .matches(/\d/, translateAuto("Password must contain at least one digit"))
      .matches(/\W/, translateAuto("Password must contain at least one non-alphanumeric character")),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], translateAuto("Passwords must match"))
      .required(translateAuto("Confirm Password is required")),
  });

  const initialValues = {
    email: email,
    password: "",
    token: token,
    confirmPassword: "",
  };

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  // handle forgot password
  const handleResetPassword = (values) => {
    setLoading(true);
    fetch(`${BASE_URL}/Account/ResetPassword`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      Allow: "POST",
      body: JSON.stringify(values),
    })
      .then(async (response) => {
        const statusCode = await response.status;
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
          return response.json().then((data) => [statusCode, data]);
        } else {
          return response.text().then((data) => [statusCode, data]);
        }
      })
      .then((data) => {
        if (data[0] === 200) {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: t("Password reset successfully"),
          });
          setLoading(false);
          setIsRequested(true);
        } else if (data[0] === 400 && data[1].errors) {
          setLoading(false);
          for (var i = 0; i < data[1].errors.length; i++) {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: data[1].errors[i].description,
            });
          }
          setIsRequested(false);
        } 
        else if (data[0] === 401) {
          history.push("/logout");
        }
        else if (data[0] === 500) {
          setLoading(false);
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: t("Something went wrong!. Please try again later"),
          });
          setIsRequested(false);
        }
      })
      .catch((error) => {
        setLoading(false);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: error,
        });
      });
  };
  const [showPassword, setShowPassword] = useState(false);
  const [showconfirmPassword, setshowconfirmPassword] = useState(false);
  const formik = useFormik({
    initialValues,
    validationSchema: ForgotPasswordSchema,
    onSubmit: (values) => {
      handleResetPassword(values);
    },
  });

  return (
    <>
      {isRequested && <Redirect to="/auth" />}
      {!isRequested && (
        <div className="login-form login-forgot" style={{ display: "block" }}>
          <div className="text-center mb-10 mb-lg-20">
            <h3 className="font-size-h1">{t("Reset Password !")}</h3>
          </div>
          <form
            onSubmit={formik.handleSubmit}
            className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
          >
         <div className="form-group fv-plugins-icon-container">
          <div className="position-relative">
            <input
              placeholder={t("Password")}
              type={showPassword ? "text" : "password"}
              className={`form-control form-control-solid h-auto py-5 px-6`}
              name="password"
              {...formik.getFieldProps("password")}
            />
            <span
              className="svg-icon svg-icon-md svg-icon-info position-absolute right-0 top-0 pt-5 mr-5 pr-3"
              style={{ cursor: "pointer" }}
              onClick={() => {
                setShowPassword(!showPassword);
              }}
            >
              <SVG
                src={toAbsoluteUrl(
                  showPassword
                    ? "/media/svg/icons/General/Visible.svg"
                    : "/media/svg/icons/General/Hidden.svg"
                )}
              ></SVG>
            </span>
          </div>
          {formik.touched.password && formik.errors.password ? (
            <div className="fv-plugins-message-container">
              <div className="text-danger">* {formik.errors.password}</div>
            </div>
          ) : null}
        </div>
        <div className="form-group fv-plugins-icon-container">
          <div className="position-relative">
            <input
              placeholder={t("confirmPassword")}
              type={showconfirmPassword ? "text" : "password"}
              className={`form-control form-control-solid h-auto py-5 px-6`}
              name="confirmPassword"
              {...formik.getFieldProps("confirmPassword")}
            />
            <span
              className="svg-icon svg-icon-md svg-icon-info position-absolute right-0 top-0 pt-5 mr-5 pr-3"
              style={{ cursor: "pointer" }}
              onClick={() => {
                setshowconfirmPassword(!showconfirmPassword);
              }}
            >
              <SVG
                src={toAbsoluteUrl(
                  showconfirmPassword
                    ? "/media/svg/icons/General/Visible.svg"
                    : "/media/svg/icons/General/Hidden.svg"
                )}
              ></SVG>
            </span>
          </div>
          {formik.touched.confirmPassword && formik.errors.confirmPassword ? (
            <div className="fv-plugins-message-container">
              <div className="text-danger">* {formik.errors.confirmPassword}</div>
            </div>
          ) : null}
        </div>
            <div className="form-group d-flex flex-wrap flex-center">
              <button
                id="kt_login_forgot_submit"
                type="submit"
                className="btn btn-info font-weight-bold px-9 py-4 my-3 mx-4"
                disabled={loading}
              >
                {t("Submit")}
              </button>
              <Link to="/auth">
                <button
                  type="button"
                  id="kt_login_forgot_cancel"
                  className="btn btn-light-info font-weight-bold px-9 py-4 my-3 mx-4"
                >
                  {t("Cancel")}
                </button>
              </Link>
            </div>
          </form>
        </div>
      )}
    </>
  );
}

export default injectIntl(connect(null, auth.actions)(ResetPassword));
