import React, { useEffect, useState } from "react";
import { useSubheader } from "../../../../_metronic/layout";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import ReactPlayer from 'react-player';
import { Button, Col, Modal, Row } from "react-bootstrap";
import { useHistory } from "react-router";

const BASE_URL = process.env.REACT_APP_BASE_URL;
const HelpCenter = () => {
  const suhbeader = useSubheader();
  suhbeader.setTitle("Help Center");
  const token = JSON.parse(localStorage.getItem("user"))?.token;
  const history = useHistory();
  const [search, setSearch] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [unitVideos, setUnitVideos] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [videosPerPage] = useState(6);
  const [ShowModal, setShowModal] = useState(false);
  const [currentItem, setCurrentItem] = useState(null);

  const startIndex = (currentPage - 1) * videosPerPage;
  const endIndex = startIndex + videosPerPage;

  const currentVideos = unitVideos.slice(startIndex, endIndex);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };


  const GetTermsInfo = () => {
    setIsLoading(true);
    fetch(`${BASE_URL}/Generic/HelpCenter?search=${search}`, {
      method: "GET",
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      },
    })
      .then(async (response) => {
        const statusCode = await response.status;
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
          return response.json().then((data) => [statusCode, data]);
        } else {
          return response.text().then((data) => [statusCode, data]);
        }
      })
      .then((data) => {
        if (data[0] === 200) {
          setUnitVideos(data[1].unitVideos);
        }else if (data[0] === 401) {
          history.push("/logout");
        }
      })
      .catch((error) => {
        console.log(error);
      }).finally(() => {
        setIsLoading(false);
      });
  };


  useEffect(() => {
    const timeOutId = setTimeout(() => {
      GetTermsInfo();
    }, 500);
    return () => clearTimeout(timeOutId);
  }, [search]);


  return (
    <div>
      <div className="app-main flex-column flex-row-fluid " id="kt_app_main">
        <div className="d-flex flex-column flex-column-fluid">
          <div id="kt_app_content" className="app-content  flex-column-fluid ">
            <div
              id="kt_app_content_container"
              className="app-container  container-fluid "
            >
              <div className="card mb-12">
                <div
                  className="card-body flex-column p-5 d-block bgi-no-repeat bgi-size-cover bgi-position-center card-rounded position-relative min-h-150px"
                  style={{
                    backgroundImage: `url(${toAbsoluteUrl(
                      "/media/misc/pattern-4.jpg"
                    )})`,
                  }}
                >
                  <div className="d-flex justify-content-center align-items-center h-lg-300px p-5 p-lg-15">
                    <div className="d-flex flex-column align-items-start justift-content-center flex-equal me-5">
                      <h1 className="fw-bold fs-4 fs-lg-1 text-gray-800 mb-5 mb-lg-10">
                        How Can We Help You?
                      </h1>
                      <div className="position-relative w-100" id="VedioSearch">
                        <i className="ki-outline ki-magnifier fs-2 text-primary position-absolute top-50 translate-middle ms-8" />
                        <input
                          type="text"
                          className="form-control fs-4 py-4 ps-14 text-gray-700 placeholder-gray-500 mw-500px"
                          name="search"
                          placeholder="Search..."
                          value={search}
                          onChange={(e) => setSearch(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="flex-equal d-flex justify-content-center align-items-end ms-5">
                      <img
                        src={toAbsoluteUrl("media/illustrations/sketchy-1/20.png")}
                        alt=""
                        className="mw-100 mh-125px mh-lg-275px mb-lg-n12"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-body p-10 p-lg-15" id="vedios">
                  <div className="mb-7">
                    <div className="d-flex justify-content-between align-items-center mb-5">
                      <h3 className="text-gray-900">Video Tutorials</h3>
                    </div>
                    <div className="separator separator-dashed mb-9" />
                    <div className="row g-10">
                      {currentVideos.map((item, index) => {
                        return (
                          <div className="col-xl-4 col-lg-6 col-md-6 mb-5 pb-5 cursor-pointer" key={index}
                            onClick={() => {
                              setShowModal(true);
                              setCurrentItem(item);
                            }
                            }
                          >
                            <div className="card-xl-stretch me-md-6">
                              <div
                                className="d-block bgi-no-repeat bgi-size-cover bgi-position-center card-rounded position-relative min-h-200px mb-5 shadow-sm"
                                style={{
                                  backgroundImage: `url(${toAbsoluteUrl(item.image)})`,
                                  backgroundSize: 'cover',
                                  position: 'relative',
                                }}
                                // data-fslightbox="lightbox-video-tutorials"
                                // href={item.link}
                                // target="_blank"
                              >
                                <div className="overlay-text-inside position-absolute start-0 ml-5">
                                  <h2 className="font-weight-bolder max-w-150px">
                                    {item.title}
                                  </h2>
                                </div>
                                <img
                                  src={toAbsoluteUrl("/media/users/media-youtube-icon.png")}
                                  className="position-absolute top-50 start-50 translate-middle"
                                  alt=""
                                  width={50}
                                  height={50}
                                />
                              </div>
                            </div>
                          </div>
                        );
                      })}
                      {!isLoading && currentVideos.length === 0 && (
                        <div className="col-md-12 mb-5 pb-5">
                          <div className="card-xl-stretch me-md-6">
                            <div className="m-0">
                              <div className="fs-6 fw-bold d-flex justify-content-center">
                                <h1 className="text-muted mx-3 mt-17">
                                  No Results Found
                                </h1>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      {isLoading && (
                        <div className="col-md-12 mb-5 pb-5">
                          <div className="card-xl-stretch me-md-6">
                            <div className="m-0">
                              <div className="fs-6 fw-bold d-flex justify-content-center">
                                <h1 className="text-muted mx-3 mt-17">
                                  Loading...
                                </h1>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div>
                    <ul className="pagination d-felx justify-content-end">
                      {Array.from(
                        { length: Math.ceil(unitVideos.length / videosPerPage) },
                        (_, index) => (
                          <li
                            key={index}
                            className={`page-item ${currentPage === index + 1 ? "active" : ""
                              }`}
                          >
                            <button
                              className="page-link"
                              onClick={() => handlePageChange(index + 1)}
                            >
                              {index + 1}
                            </button>
                          </li>
                        )
                      )}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        size="xl"
        show={ShowModal}
        onHide={() => {
          setShowModal(false);
        }}
        centered
      >
        <Modal.Body>
          <Row className="mb-5">
            <Col>
              <h2 className="font-weight-bolder text-primary">
                {currentItem?.title}
              </h2>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="d-flex justify-content-center align-items-center p-3">
                <ReactPlayer
                  url={currentItem?.link}
                  controls
                  height={500}
                  width="100%"
                  playing={true}
                  volume={100}
                  muted={false}
                />
              </div>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer className="border-0">
          <Button
            variant="secondary"
            onClick={() => {
              setShowModal(false);
            }}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default HelpCenter;
