import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FormattedMessage, injectIntl } from "react-intl";
import * as auth from "../_redux/authRedux";
import { login } from "../_redux/authCrud";
import { connect } from "react-redux";
import { useFormik } from "formik";
import ReactCodeInput from 'react-verification-code-input'
import * as Yup from "yup";
import AuthSTockData from "./Two-factor/AuthSTockData";
import Swal from "sweetalert2";

const initialValues = {
    code: "",
};
function VerificationCode(props) {
    const { intl } = props;
    const BASE_URL = process.env.REACT_APP_BASE_URL;
    const [loading, setLoading] = useState(false);
    const { t } = useTranslation();
    const [BackUp, setBackUp] = useState(false);
    const [Type, setType] = useState("number");
    const [accessToken, setaccessToken] = useState(null);
    const [Token, setToken] = useState(null);

    const [Steps, setSteps] = useState(0);
    const [portalIdArray, setPortalIdArray] = useState([]);

    useEffect(() => {
        // Fetch the existing array from localStorage
        const storedPortalIdArray = JSON.parse(localStorage.getItem('portalId')) || [];

        // Set the array in state
        setPortalIdArray(storedPortalIdArray);
    }, []);
    const handleSavePortalId = (email , portalId) => {
        // Ensure both email and portalId are available
        if (email && portalId) {
          // Create a new object with email and portalId
          const newPortalIdObject = { email, portalId };
    
          // Update the array in state by adding the new object
          setPortalIdArray([...portalIdArray, newPortalIdObject]);
    
          // Update localStorage with the new array
          localStorage.setItem('portalId', JSON.stringify([...portalIdArray, newPortalIdObject]));
    
          // Clear the email and portalId in state after saving
        }
      };
    const LoginSchema = Yup.object().shape({
        code: Yup.string()
            .required("Code field is required"),
    });
    const enableLoading = () => {
        setLoading(true);
    };

    const disableLoading = () => {
        setLoading(false);
    };
    const [code, setCode] = useState(0); // Assuming you have a state for the code

    const handleCodeChange = (code) => {
        formik.setFieldValue("code", code)
       
    }
    const handleButtonClick = () => {
        // Incrementing the key will force remounting of ReactCodeInput
        formik.setFieldValue("code", '')
        setCode((prevKey) => prevKey + 1);
      };
    const state = AuthSTockData.getState();
    useEffect(() => {
        AuthSTockData.getState().setLoginData({
            isBackup: false,
        });
    }, [])
    useEffect(() => {
        if (!!state.loginData.password === false) {
            props.history.push("/auth/login");
        }
    }, [state])
    const [deviceInfo, setDeviceInfo] = useState({
        name: '',
        publicIpAddress: '',
        hostName: '',
        platform: '',
        osVersion: '',
        deviceId: '',
        macAddress: '',
    });
    const PutVerification = () => {
        fetch(`${BASE_URL}/Account/TwoFactorAuthentication/ValidationDevice`, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + Token,
            },
            Allow: "PUT",
            body: JSON.stringify(deviceInfo),
        })
            .then(async (response) => {
                const statusCode = await response.status;
                const contentType = response.headers.get("content-type");
                if (contentType && contentType.indexOf("application/json") !== -1) {
                    return response.json().then((data) => [statusCode, data]);
                } else {
                    return response.text().then((data) => [statusCode, data]);
                }
            })
            .then((data) => {
                if (data[0] === 200) {
                    // localStorage.setItem('portalId', data[1]?.portalId);
                    handleSavePortalId( state.loginData.username,data[1]?.portalId)
                    props.login(accessToken)
                } else if (data[0] === 400 && data[1].message) {
                    Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: data[1].message,
                    });
                } else if (data[0] === 400) {
                    let tt = JSON.parse(data[1]);
                    for (const [key, value] of Object.entries(tt.errors)) {
                        Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: value,
                        });
                    }
                } else if (data[0] === 401) {
                    props.history.push("/logout");
                } else if (data[0] === 402) {
                    Swal.fire({
                        title: "Info!",
                        text: t("You have to subscribe first!"),
                        icon: "info",
                        confirmButtonText: "Ok",
                    }).then((result) => {
                        if (result.isConfirmed) {
                            props.history.push("/user-profile");
                        }
                    });
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };
    useEffect(() => {

        const fetchDeviceInfo = () => {
            // Device Name (browser information)
            const name = navigator.userAgent;

            // Public IP Address (not always accurate due to privacy concerns)
            // This is just a placeholder. In a real application, you might want to use a third-party API to get the public IP.
            const publicIpAddress = 'not_available';

            // Host Name (may not be available in some browsers)
            const hostName = window.location.hostname;

            // Platform (e.g., Win32, Linux, MacOS)
            const platform = navigator.platform;

            // OS Version
            const osVersion = navigator.oscpu || navigator.appVersion;

            // Device ID (not available in browsers for security reasons)
            const deviceId = 'not_available';

            // MAC Address (not available in browsers for security reasons)
            const macAddress = 'not_available';

            // Update state with device information
            setDeviceInfo({
                name,
                publicIpAddress,
                hostName,
                platform,
                osVersion,
                deviceId,
                macAddress,
            });
        };

        // Call the function to fetch device information
        fetchDeviceInfo();
    }, []);
    
    
    const formik = useFormik({
        initialValues,
        validationSchema: LoginSchema,
        onSubmit: (values, { setStatus, setSubmitting }) => {
            enableLoading();
            setTimeout(() => {
                login(state.loginData.username, state.loginData.password, values.code, state.loginData.isBackup)
                    .then((data) => {
                        setaccessToken(data?.data?.accessToken)
                        setToken(data?.data?.data?.token)
                        setSteps(Steps + 1)
                        // props.login(data?.data?.accessToken)
                    })
                    .catch(() => {
                        disableLoading();
                        setSubmitting(false);
                        setStatus(
                            intl.formatMessage({
                                id: "AUTH.VALIDATION.INVALID_LOGIN",
                            })
                        );
                    });
            }, 1000);
        },
    });

    return (
        <div className="login-form login-signin text-center " id="kt_login_signin_form">
            {/* begin::Head */}

            {/* end::Head */}

            {/*begin::Form*/}
            {Steps === 0 ?
                <>
                    <div className="text-left mb-10 mb-lg-10 text-center">
                        <h3 className="font-size-h1">{t("Enter the verification code from your security app")}</h3>
                        <span className="text-muted text-decoration-none font-weight-bolder line-height-lg mt-3">
                            Enter one of your unused backup codes.
                        </span>
                    </div>
                    <form
                        onSubmit={formik.handleSubmit}
                        className="form fv-plugins-bootstrap fv-plugins-framework"
                    >

                        <div className="form-group fv-plugins-icon-container ">
                            <div className="position-relative ">
                                <ReactCodeInput key={code} type={Type} fields={6} onChange={handleCodeChange}  className="w-100" />
                            </div>
                            {formik.touched.code && formik.errors.code ? (
                                <div className="fv-plugins-message-container  mt-4 text-left pl-10 ">
                                    <div className="text-danger">* {formik.errors.code}</div>
                                </div>
                            ) : null}

                        </div>

                        <button
                            // id="kt_login_signin_submit"
                            type="submit"
                            disabled={formik.isSubmitting}
                            className={`btn btn-info font-weight-bold px-9 py-4 my-3 w-100`}
                        >
                            <span>{t("Sign_In")}</span>
                            {loading && <span className="ml-3 spinner spinner-white"></span>}
                        </button>
                        <button
                            hidden={true}
                            id="kt_login_signin_submit"
                            type="submit"
                            disabled={formik.isSubmitting}
                            className={`btn btn-info font-weight-bold px-9 py-4 my-3 w-100`}
                        >
                            <span>{t("Sign_In")}</span>
                            {loading && <span className="ml-3 spinner spinner-white"></span>}
                        </button>
                        <div className="form-group d-flex flex-wrap justify-content-between align-items-center">
                            <span
                                className="text-info text-hover-primary mt-3 cursor-pointer"
                                id="kt_login_forgot"
                                onClick={() => {
                                    setBackUp(!BackUp)
                                    handleButtonClick()
                                    AuthSTockData.getState().setLoginData({
                                        isBackup: !BackUp,
                                    });
                                    if (BackUp === true) {
                                        setType("number")

                                    } else {
                                        setType("text")
                                    }

                                }}
                            >
                                {/* <FormattedMessage id="AUTH.GENERAL.FORGOT_BUTTON" /> */}
                                {BackUp === true ?
                                    t("Use your primary verification method")
                                    :
                                    t("Use a backup code")
                                }
                            </span>
                        </div>
                    </form>
                </>

                : ""
            }
            {Steps === 1 ?
                <div>
                    <div className="card card-custom shadow text-left">
                        <div className="card-body ">
                            <div className="login-signin">
                                <h1 className="font-weight-bolder mt-5">
                                    Would you like us to remember you on this device?
                                </h1>
                                <div className=" flex-wrap font-weight-semibold  text-dark-50 mt-3 mb-10">
                                    We'll remember this device, so you won't be asked for two-factor authentication every time, but this preference won't last indefinitely.
                                </div>
                                <div className="d-flex">
                                    <button
                                        type="button"
                                        onClick={() => {
                                            PutVerification()
                                        }}
                                        className="btn btn-primary font-weight-bold px-9  my-3 mr-3"
                                    >
                                        <span>Remember me</span>
                                    </button>
                                    <button
                                        type="button"
                                        onClick={() => {
                                            props.login(accessToken)
                                        }}
                                        className="btn btn-outline-primary font-weight-bold py-4 my-3 "
                                    >
                                        <span>Ask for 2FA every time</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                : ""}
            {/*end::Form*/}
        </div>
    );
}
export default injectIntl(connect(null, auth.actions)(VerificationCode));

